import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a href={{@href}} {{on \"click\" this.onClick}} ...attributes target={{this.target}}>\n  {{yield}}\n</a>\n", {"contents":"<a href={{@href}} {{on \"click\" this.onClick}} ...attributes target={{this.target}}>\n  {{yield}}\n</a>\n","moduleName":"direct/components/portal/my-documents-link.hbs","parseOptions":{"srcName":"direct/components/portal/my-documents-link.hbs"}});
import Component from '@glimmer/component'
import { service } from '@ember/service'
import { action } from '@ember/object'

export default class MyDocumentsLinkComponent extends Component {
  @service mobile

  get isCompatibleMobileApp() {
    return this.mobile.isMobileApp
  }

  get target() {
    if (this.isCompatibleMobileApp) {
      return '_self'
    }

    return this.args.target
  }

  @action
  async onClick(event) {
    if (this.isCompatibleMobileApp) {
      event.preventDefault()
      return this.mobile.callBridgeFunction('download_and_open_file', this.args.href)
    }
  }
}
