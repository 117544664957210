import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class OrderPhysicalCardModalComponent extends Component {
  id = 'order_physical_card'

  get emberLoader() {
    return document.querySelector('.ui.dimmer.ember-loader')
  }

  showEmberLoader() {
    this.emberLoader.style.opacity = 0.96
    this.emberLoader.style.display = 'inherit'
  }

  hideEmberLoader() {
    this.emberLoader.style.opacity = 0
    this.emberLoader.style.display = 'none'
  }

  @action
  closeModal() {
    this.showEmberLoader()
    closeModal(this.id)

    return this.args.shoppingPass.getMultiUsePhysicalCardOnetimeToken().then((data) => {
      this.args.shoppingPass.set('physicalCard', data.physical_card)
      this.hideEmberLoader()
    });
  }
}
