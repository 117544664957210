import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import PortalController from '../portal';
const Big = window.Big

@classic
class PortalOffersController extends PortalController {
  @controller('services/paymentCalculator')
  calculator;

  @controller
  subvention;

  @controller('services/paymentSchedule')
  paymentSchedule;

  @controller
  portal;

  @service
  intl;

  @equal('loan.subventionProgramId', 0)
  isStandardProgram;

  loading = false;
  saving = false;
  errorState = false;
  previousSubventionId = null;
  showPurchaseAmountValidationMessage = false;

  @computed('loading', 'calculator.selectedSubvention')
  get loadingText() {
    if (this.loading && (this.get('calculator.selectedSubvention').type !== 'SameAsCash')) {
      // eslint-disable-next-line ember/no-string-prototype-extensions
      return htmlSafe('<i class="icon-spinner icon-spin"></i>');
    } else {
      return '';
    }
  }

  @computed('loan.{requestedAmount,poiThreshold}')
  get isQuickfund() {
    if (this.get('loan.poiThreshold') === '0') { return false; }
    const mQFA = new Big(this.get('loan.poiThreshold'));
    const rA = new Big(this.get('loan.requestedAmount'));

    return rA.lte(mQFA);
  }

  @computed('calculator.selectedSubvention', 'loan.subventionProgramId')
  get isSameAsCashProgram() {
    return this.get('calculator.selectedSubvention').type === 'SameAsCash';
  }

  @computed('loan.{isPurchaseDetailsCompleted,approvalNoAmount}')
  get isPurchaseAmountSet() {
    return !this.get('loan.approvalNoAmount') || this.get('loan.isPurchaseDetailsCompleted');
  }

  @computed('isQuickfund', 'calculator.globalMaxRequestedAmount', 'loan.poiThreshold')
  get showQuickFundMaxAmount() {
    if (!this.isQuickfund) { return false; }
    const gMRA = this.get('calculator.globalMaxRequestedAmount');
    const mQFA = new Big(this.get('loan.poiThreshold'));
    if (mQFA.lte(0)) { return false; }
    return gMRA.gt(mQFA);
  }

  @action
  slideUp() {
    // eslint-disable-next-line ember/no-jquery
    return $('html,body').animate({ scrollTop: 0 }, 'fast');
  }
}

export default PortalOffersController;
