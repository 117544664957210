import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

@classic
export default class SubmitProofOfIncomeRoute extends Route {
  @service store
  @service router

  resetController(controller) {
    controller.set('isCoborrowerPoiStep', null);
  }

  beforeModel(transition) {
    const loan = this.controllerFor('loan').get('model');
    const borrower = this.controllerFor('borrower').get('model');
    const step_name = transition.to.queryParams.isCoborrowerPoiStep ? 'submit_proof_of_income_coborrower' : 'submit_proof_of_income';
    const poiStep = this.store.peekAll('loan-step').findBy('name', step_name);

    if (transition.to.queryParams.forceTransition) { return false; }

    //redirect to upload photo step if user is not using flinks
    const userIsUploadingPhoto = poiStep.get('directState') !== 'new' && (loan.get('currentFlinksResponse') === null);
    const hitMaxAttempts = borrower.atMaxFlinksPoiAttempts;
    if (userIsUploadingPhoto || hitMaxAttempts) {
      return this.router.transitionTo('portal.hub.loan-steps', step_name);
    }
  }
}
