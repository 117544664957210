import Component from '@glimmer/component'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { action } from '@ember/object'

export default class PaymentDisclaimerComponent extends Component {
  @action
  showModal(id) {
    UiModalUtils.showModal(id)
  }
}
