import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'

export default class LoanDocumentUploaderComponent extends Component {
  @service intl
  @service UiFileQueue
  
  constructor() {
    super(...arguments)
    this.fileQueue = this.UiFileQueue.create(this.fileQueueName, this.uploadUrl)
    this.headers =  { 'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.content }
  }

  get fileQueueName() {
    return this.args.name
  }

  get documentType() {
    return this.args.documentType
  }

  get uploadUrl() {
    return `/${this.intl.language}/api/v3/direct/loans/${this.args.loan.id}/upload?document_type=${this.documentType}`
  }

  get disableSubmit() {
    return !this.fileQueue.files.length
  }

  @action
  async onSubmit() {
    return this.fileQueue.uploadAll(this.headers)
      .then(() => this.args.onSubmit?.())
      .catch(error => this.args.onError?.(error))
  }
}
