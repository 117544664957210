import Component from '@glimmer/component'

export default class BadgeCellComponent extends Component {
  get recordProperty() {
    return this.args.record[this.args.column.propertyName]
  }

  get kind() {
    return this.recordProperty.kind
  }

  get text() {
    return this.recordProperty.text
  }
}
