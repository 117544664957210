import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

@classic
class Error404Controller extends Controller {
  @service
  router;

  @action
  backHome() {
    let loginKey=window.location.pathname.match('direct/portal/(.+?)/')?.[1];
    return this.router.transitionTo('portal.hub', loginKey);
  }
}

export default Error404Controller;
