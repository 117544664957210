import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NotificationService extends Service {
  // The kind of notification to display (can be success | warning | error | note)
  @tracked kind =  null
  @tracked message = null

  setNotification(kind, message) {
    this.kind = kind
    this.message = message
  }

  get hasMessage() {
    return this.kind && this.message
  }

  reset() {
    this.kind = null
    this.message = null
  }
}
