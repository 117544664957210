import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import PortalHubBankingController from '../banking';
import secureFetch from '../../../../utils/secure-fetch'

@classic
class PortalHubBankingAlsoSetUpPaymentsController extends PortalHubBankingController {
  @service
  intl;

  @service store

  @controller('portal.hub')
  hubController;

  showSpinner = false;
  showProgressBar = true;
  shouldCompleteProgressBar = false;

  get language() {
    return this.intl.language
  }

  @computed('loan.{borrowerId,hasCoborrower}', 'session.loggedInUser.id')
  get coborrowerLoggedIn() {
    return this.get('loan.hasCoborrower') && (parseInt(this.get('session.loggedInUser.id')) !== this.get('loan.borrowerId'));
  }

  @computed('coborrowerLoggedIn', 'loan.{borrower.id,coborrower.id}', 'model')
  get currentUserId() {
    if (this.coborrowerLoggedIn) {
      return {
        id: this.get('loan.coborrower.id'),
        key: "coborrower"
      };
    } else {
      return {
        id: this.get('loan.borrower.id'),
        key: "borrower"
      };
    }
  }

  async postBankAccountData() {
    return await secureFetch(`/${this.language}/api/v3/direct/bank_accounts`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        source: 'poi',
        purpose: 'set_up_payments',
        account_id: this.get('borrower.chosenBankAccountId'),
        loan_id: this.get('loan.id'),
        borrower_id: this.get('session.loggedInUser.id')
      })
    }).then(res => res.json()).catch(() => {
      return {
        loan: null,
        step: null,
        error: true
      }
    });
  }

  @action
  async setupPayments() {
    this.set('showVerifyingAccountText', true);
    this.set('showSpinner', true);
    this.set('showProgressBar', true);
    const data = await this.postBankAccountData()
    this.store.pushPayload('loan', { loan: data.loan });
    this.store.pushPayload('loanStep', { loanStep: data.step });
    this.send('updateIdWasVerifiedStatus');

    if (isPresent(data.error)) {
      this.transitionToRoute('portal.hub.banking.error', { queryParams: { sourceFlow: 'set_up_payments' } });
    } else {
      this.transitionToRoute('portal.hub.banking.confirmation', { queryParams: { sourceFlow: 'poi' } });
    }
    this.set('shouldCompleteProgressBar', true);
    this.set('showSpinner', false);
    this.set('showVerifyingAccountText', false)
  }

  @action
  cancel() {
    return this.transitionToRoute('portal.hub.banking.confirmation', { queryParams: { sourceFlow: 'poi' } });
  }
}

export default PortalHubBankingAlsoSetUpPaymentsController;
