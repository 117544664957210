import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import PortalController from '../portal';
import * as UiModalUtils from 'tag/utils/ui-modal'
import { tracked } from '@glimmer/tracking'

@classic
class PortalFundedController extends PortalController {
  @controller('services/paymentCalculator')
  calculator;

  @controller
  subvention;

  @controller('services/paymentSchedule')
  paymentSchedule;

  @controller
  session;

  @service
  intl;

  @service
  snowplow;

  @service store;

  sessionData = null;
  errorMessage = null;
  loginKey = null;

  @tracked
  showSpinner = false;

  async getLoans() {
    this.set('sessionData', null);
    if (this.store.peekAll('loan').objectAt(0)){
      this.set('loginKey', this.store.peekAll('loan').objectAt(0).loginKey);
    }

    if(this.loginKey) {
      try {
        const data = await this.store.peekRecord('session', 0).loans({login_key: this.loginKey});
        this.set('sessionData', data);
      } catch(e) {
        this.set('errorMessage', e.errors[0]);
      }
    }
    this.set('showSpinner', false);
  }

  async showModal(id) {
    this.set('showSpinner', true);
    await this.getLoans();
    UiModalUtils.showModal(id)
  }

  @computed('session.activeLoansCount')
  get multipleActiveLoans() {
    return this.session.activeLoansCount > 1
  }

  @computed('loan.dbState')
  get isPartiallyFunded() {
    return this.get('loan.dbState') === 'partially_funded'
  }

  @computed('session.loggedInUser.id', 'store')
  get loggedInEmail() {
    const loggedInUserId = this.get('session.loggedInUser.id')
    const loggedInBorrower = this.store.peekAll('borrower').findBy('id', loggedInUserId)
    return loggedInBorrower.email
  }

  @computed('loan.nextNonHolidayScheduledPayment')
  get hasNextScheduledPayment() {
    return (this.get('loan.nextNonHolidayScheduledPayment') != null);
  }

  @computed('loan.amountNeededToPayOffLoan')
  get hasRemainingBalance() {
    return (this.get('loan.amountNeededToPayOffLoan') > 0);
  }

  @computed(
    'loan.{eligibleForPaymentScheduleChange,multiStageFinancingFundedButNotFinalized,paymentHolidayPresent,currentArrears.is_in_arrears}'
  )
  get showPaymentFrequencyButton() {
    return this.get('loan.eligibleForPaymentScheduleChange') &&
      !this.get('loan.paymentHolidayPresent') &&
      !this.get('loan.currentArrears.is_in_arrears') &&
      !this.get('loan.multiStageFinancingFundedButNotFinalized')
  }

  @computed('hasNextScheduledPayment', 'loan.nextNonHolidayScheduledPayment')
  get nextScheduledPayment() {
    if (this.hasNextScheduledPayment) {
      const payment = this.get('loan.nextNonHolidayScheduledPayment');
      return {
        date: payment.date,
        due: payment.due,
      };
    } else {
      return null;
    }
  }

  @computed
  get phoneLink() {
    const number = this.intl.t('financeit.phone');
    return `tel://${number.replace(/\./g, '')}`;
  }

  @computed('loan.latestIdsDocumentUrl')
  get latestIdsDocumentUrl() {
    return this.get('loan.latestIdsDocumentUrl');
  }

  @computed('borrower.eConsentModalShown', 'partner.isBigBox', 'session.loggedInUser.esignConsentReceived')
  get shouldShowEConsent() {
    return !this.get('session.loggedInUser.esignConsentReceived') && !this.get('borrower.eConsentModalShown') && !this.get('partner.isBigBox');
  }

  @computed('loan.subventionProgramId', 'subvention.promo')
  get isNoPaymentsProgram() {
    if (this.get('subvention.promo')) {
      return this.get('subvention.promo').type === 'NoPayments';
    } else {
      return false;
    }
  }

  @computed('loan.subventionProgramId', 'subvention.promo')
  get isSameAsCashProgram() {
    if (this.get('subvention.promo')) {
      return this.get('subvention.promo').type === 'SameAsCash';
    } else {
      return false;
    }
  }

  @computed('isNoPaymentsProgram', 'isSameAsCashProgram')
  get isBadgePromotion() {
    return this.isNoPaymentsProgram || this.isSameAsCashProgram;
  }

  @computed('loan.{principalAmount,amountNeededToPayOffLoan}')
  get percentageRemainingPayment() {
    const calculatedPercentage = ((this.get('loan.principalAmount') - this.get('loan.amountNeededToPayOffLoan')) / this.get('loan.principalAmount')) * 100;
    if (calculatedPercentage < 0) {
      return 0
    } else {
      return calculatedPercentage;
    }
  }

  @computed('session.loggedInUser.mailOptOut')
  get returnedMail() {
    return this.get('session.loggedInUser.mailOptOut')
  }

  @computed('session.loggedInUser.id', 'loan.isShoppingPass')
  get ctuOfferForCurrentUser(){
    if (this.get('loan.isShoppingPass')) {
      return null;
    }

    const loggedInUserId = this.get('session.loggedInUser.id')
    const loggedInBorrower = this.store.peekAll('borrower').findBy('id', loggedInUserId)

    return loggedInBorrower.ctuOffer
  }

  @action
  showEConsentOnInsert() {
    if (this.shouldShowEConsent) {
      UiModalUtils.showModal('e-consent-modal');
    }
  }

  @action
  acceptEConsent() {
    this.set('session.loggedInUser.esignConsentReceived', true);

    return this.borrower.updateEsignConsent({
      login_key: this.get('loan.loginKey'),
      new_esign_consent_status: this.get('session.loggedInUser.esignConsentReceived')
    }).then(data => {
      this.set('session.loggedInUser.esignConsentReceived', data.esign_consent);
      this.set('borrower.eConsentModalShown', true);
      return this.closePrintTable;
    });
  }

  @action
  closeEConsent() {
    this.set('borrower.eConsentModalShown', true);
    return this.closePrintTable;
  }

  @action
  trackPaymentHistoryClick() {
    this.snowplow.snowplow('trackStructEvent', {
      category: 'FinanceIt::Direct::Portal::FundedController',
      action: 'payment_history_button_click'
    });
    return this.transitionToRoute('portal.payment.payment-details');
  }

  @action
  openPayoutStatementScreen() {
    this.snowplow.snowplow('trackStructEvent', {
      category: 'FinanceIt::Direct::Portal::FundedController',
      action: 'payout_statement_button_click'
    });
    return this.transitionToRoute('portal.payment.payout-statement');
  }

  @action
  changePaymentFrequencyClick() {
    return this.transitionToRoute('portal.payment-options.change');
  }

  @action
  changeBankAccountClick() {
    return this.transitionToRoute('portal.banking.change-account');
  }

  @action
  showSpinnerFunded(value) {
    this.showSpinner = value
  }

  @action
  profileAndSettingsClick() {
    return this.transitionToRoute('portal.profile')
  }

  @action
  navigateToCreditTopUpOffer(){
    this.snowplow.snowplow('trackStructEvent', {
      category: 'FinanceIt::Direct::Portal::FundedController',
      action: 'navigate_to_credit_top_up_offer_click'
    });
    return window.location.href = this.ctuOfferForCurrentUser.link + "&source=direct"
  }
}

export default PortalFundedController;
