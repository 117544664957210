import Component from '@glimmer/component'
import { action } from '@ember/object'
import { resetModalToTargetSection } from 'financeit-components/utils/learn-more-show-modal-helper'
import * as UiModalUtils from 'tag/utils/ui-modal'

export default class BaseTransactionsTableComponent extends Component {
  @action
  openLearnMoreModal() {
    if (this.args.learnMoreSectionId) {
      resetModalToTargetSection(this.args.learnMoreSectionId)
    }
    UiModalUtils.showModal(this.args.learnMoreModalId)
  }
}
