import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject as service } from '@ember/service'
import Changeset from 'ember-changeset'
import { isPresent, isBlank } from '@ember/utils'
import PortalHubInsuranceController from '../insurance'

export default class extends PortalHubInsuranceController {
  @service store;
  @tracked borrowerChangeset = new Changeset(this.borrower)

  treatyModalId = 'treaty-number-modal'

  get taxExemptionEligible() {
    return this.loan.insuranceTaxedProvinces.includes(this.borrower.province)
  }

  get treatyNumberEntered() {
    return isPresent(this.borrower.treatyNumber)
  }

  get insuranceChoiceContinueDisabled() {
    return (this.borrowerChangeset.insuranceDecision === 'true' && !this.borrowerChangeset.fullTimeEmploymentAcknowledgement)
      || isBlank(this.borrowerChangeset.insuranceDecision)
      || this.isSubmitting
  }

  @action
  async removeTreatyNumber() {
    this.isSubmitting = true
    await this.borrower.deleteTreatyNumber()
    await this.store.findRecord('loan', this.loan.id, { reload: true })
    this.borrower.treatyNumber = undefined
    this.isSubmitting = false
  }

  @action
  submitInsuranceDecision() {
    if (this.borrowerChangeset.insuranceDecision === 'true') {
      this.borrowerChangeset.execute()
      return this.router.transitionTo('portal.hub.insurance.review')
    } else {
      this.submitInsuranceOfferDecisionStep(false)
    }
  }
}
