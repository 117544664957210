import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';

@classic
class PortalBankingProcessingRoute extends Route {
  @service
  intl;

  @service store
  @service router

  beforeModel() {
    const loan = this.controllerFor('loan');
    const id = loan.get('model.id');
    const loginKey = loan.get('model.loginKey');

    // eslint-disable-next-line ember/no-jquery
    return $.ajax({
      type: 'POST',
      url: `/${this.intl.language}/api/v3/direct/flinks/responses/process_bank_details_response`,
      data: { loan_id: id, login_key: loginKey, source: 'set_up_payments' }
    }).then((data) => {
      this.store.pushPayload('loan', { loan: data.loan });

      if (isPresent(data.error_description)) {
        this.controllerFor('portal.banking.error').set('bankingErrorTitle', data.error_title);
        this.controllerFor('portal.banking.error').set('bankingErrorDescription', data.error_description);
        return this.router.transitionTo('portal.banking.error');
      } else if (data.loan.flinks_summaries.length < 1) {
        return this.router.transitionTo('portal.banking.none-found');
      } else {
        return this.router.transitionTo('portal.banking.choose-account');
      }
    });
  }

  setupController() {
    const loan = this.controllerFor('loan').get('model');
    const borrower = this.controllerFor('borrower').get('model');
    if (isPresent(loan.get('flinksSummaries'))) {
      return borrower.set('chosenBankAccountId', loan.get('flinksSummaries')[0].id);
    }
  }
}


export default PortalBankingProcessingRoute;
