import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';
import Changeset from "ember-changeset";
import { inject as service } from '@ember/service';

@classic
class PortalPaymentRoute extends Route {
  @service store

  beforeModel(transition) {
    checkAuthenticated(transition, this);
    if (!['funded', 'msf_funded_but_not_finalized'].includes(this.controllerFor('loan').get('model.state'))) {
      return transitionToDefaultRoute(this);
    }
  }

  model() {
    const loan = this.controllerFor('loan').get('model');
    const payment = this.store.createRecord('payment');
    const changeset = new Changeset(loan);

    if (loan.currentArrears.is_in_arrears) {
      changeset.set('makePaymentAmount', loan.currentArrears.total_overdue_payment);
    }

    return { changeset, loan, payment };
  }
}

export default PortalPaymentRoute;
