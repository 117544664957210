import Component from '@glimmer/component'

export default class UiButtonBase extends Component {
  // Default properties
  get kind() {
    return this.args.kind ?? 'primary'
  }
  get size() {
    return this.args.size ?? 'base'
  }
  get underlineOnHover() {
    return this.args.underlineOnHover ?? true
  }
  get bold() {
    return this.args.bold ?? true
  }

  // kind and size
  get _size() {
    if (this.kind === 'basic') {
      return this.size
    }

    if (this.size === 'xs' || this.size === 'sm') {
      return this.size
    }

    return 'base'
  }

  get _classes() {
    if (this.kind === 'basic') {
      return this.basicButtonClasses()
    } else {
      return this.buttonClasses()
    }
  }

  buttonClasses() {
    const classes = [
      'tw-rounded',
      'tw-font-body',
      'tw-inline-block',
      'tw-font-bold',
      'tw-leading-normal',
      'tw-border-2',
      'tw-whitespace-no-wrap',
      `tag-theme-button-${this.kind}`,
      `tag-theme-button-${this.size}`,
    ]

    return [...classes, ...this.baseClasses(), ...this.dynamicClasses()].join(' ')
  }

  dynamicClasses() {
    const classes = [`tw-text-${this._size}`]

    if (this._size === 'xs') {
      classes.push('tw-px-2', 'tw-py-1')
    } else {
      classes.push('tw-px-6', 'tw-py-2')
    }

    if (this.isDisabled) {
      classes.push('tw-cursor-not-allowed')
    }

    if (this.args.fullWidthMobile) {
      classes.push('tw-w-full sm:tw-w-auto tw-text-center')
    }

    return classes
  }

  baseClasses() {
    switch (this.kind) {
      case 'secondary':
        if (this.isDisabled) {
          return ['tw-border-ui-gray-dark', 'tw-bg-transparent', 'tw-text-ui-gray-dark']
        } else {
          return [
            'tw-border-interactive-100',
            'tw-bg-transparent',
            'hover:tw-bg-interactive-40',
            'tw-text-interactive-100'
          ]
        }
      case 'tertiary':
        if (this.isDisabled) {
          return ['tw-border-transparent', 'tw-bg-transparent', 'tw-text-ui-gray-dark']
        } else {
          return [
            'tw-border-transparent',
            'tw-bg-transparent',
            'tw-text-interactive-100',
            'hover:tw-text-interactive-dark',
            'hover:tw-bg-ui-gray-40'
          ]
        }
      default:
        if (this.isDisabled) {
          return ['tw-border-ui-gray-dark', 'tw-bg-ui-gray-dark', 'tw-text-white']
        } else {
          return [
            'tw-border-interactive-100',
            'tw-bg-interactive-100',
            'hover:tw-bg-interactive-dark',
            'tw-text-white'
          ]
        }
    }
  }

  basicButtonClasses() {
    const classes = [
      'tw-font-body',
      'tw-leading-normal',
      'tw-text-interactive-100',
      `tw-text-${this._size}`,
      'tag-theme-button-basic'
    ]

    let isUiLinkToOrUiLinkToExternalComponent = new RegExp('^UiLinkTo.*$').test(this.constructor.name)

    if (this.args.icon) {
      classes.push('tw-inline-block')
    } else if (isUiLinkToOrUiLinkToExternalComponent) {
      classes.push('tw-inline')
    } else {
      classes.push('tw-inline-block')
    }

    if (this.underlineOnHover) {
      classes.push('hover:tw-underline')
    }

    if (this.bold) {
      classes.push('tw-font-bold')
    }

    return classes.join(' ')
  }

  get iconToShow() {
    if (this.args.isRunning) {
      return 'pending'
    }

    return this.args.icon
  }

  get isDisabled() {
    if (this.args.isRunning) {
      return true
    }

    return this.args.disabled
  }
}
