import classic from 'ember-classic-decorator';
/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';

@classic
class PortalOffersContactRoute extends Route {
  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  setupController(controller, model) {
    return controller.send('initializeBusinessHours');
  }
}

export default PortalOffersContactRoute;
