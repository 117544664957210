import { inject as service } from '@ember/service';
import RESTAdapter from '@ember-data/adapter/rest';
import { camelize } from '@ember/string';

// Override the default adapter with the `DS.ActiveModelAdapter` which
// is built to work nicely with the ActiveModel::Serializers gem.
class ApplicationAdapter extends RESTAdapter {
  @service
  intl;

  //include rails recommended csrf protection
  get headers() {
    const csrfTokenElement = document.querySelector('[name="csrf-token"]')
    if (csrfTokenElement) {
      return {
        'X-CSRF-Token': csrfTokenElement.content,
      };

    } else { return null }
  }

  get namespace() {
    return `${this.intl.language}/api/v3/direct`;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  fetchError(errors) {
    const response = {}
    Object.keys(errors).forEach(key => response[camelize(key)] = errors[key])
    return response
  }

  ajaxError(jqXHR) {
    const json = JSON.parse(jqXHR.responseText)
    const errors = {}
    Object.keys(json.errors).forEach(key => errors[camelize(key)] = json.errors[key])
    return errors
  }

  emberError(error) {
    const errors = {};
    error.errors.forEach(function(error) {
      for (let key in error) {
        errors[camelize(key)] = error[key];
      }
    });
    return errors;
  }

  normalizeErrorResponse(status, headers, payload) {
    if (payload && typeof payload === 'object' && payload.errors) {
      return payload.errors;
    } else {
      return [{
        status: "" + status,
        title: 'The backend responded with an error',
        detail: "" + payload
      }];
    }
  }

  handleResponse(status, headers, payload, requestData) {
    if (payload && typeof payload === 'object' && payload.errors) {
      payload.errors = Object.keys(payload.errors).map((key) => {
        return {
          detail: payload.errors[key][0],
          source: { pointer: `/data/attributes/${key}` },
          meta: { key: key, errors: payload.errors[key] }
        }
      })
    }

    return super.handleResponse(...arguments);
  }
}

export default ApplicationAdapter;
