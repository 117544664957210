import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
/* eslint-disable
    ember/no-jquery,
    ember/use-brace-expansion,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils';
import PortalHubController from '../hub';
import { formatAsCurrency } from 'tag/utils/number-formatters'

@classic
class PortalHubLoanStepsController extends PortalHubController {
  @controller('portal.hub')
  hubController;

  confirmWorkCompleteChecked = false;

  @service
  intl;

  submitLoanAgreementDocumentClicked = false;

  uncollapseResubmit = false;

  @computed('uncollapseResubmit')
  get resubmitChevronName() {
    if (this.uncollapseResubmit) {
      return ("chevron-down-wide");
    }
    return "chevron-up-wide";
  }

  @computed('model.confirmedWorkComplete')
  get disableWorkCompleteContinue() {
    return !this.model.get('confirmedWorkComplete');
  }

  @computed('model.authorizedReleaseOfFunds')
  get disableReleaseFunds() {
    return !this.model.get('authorizedReleaseOfFunds');
  }

  @computed(
    'confirmWorkCompleteChecked', 'loan.{finalTrancheRequested,multiStageFinancing}', 'model.{finalTrancheRequested,multiStageFinancing}'
  )
  get confirmWorkCompleteSubmitted() {
    if (this.get('loan.multiStageFinancing') && !this.get('loan.finalTrancheRequested') || this.confirmWorkCompleteChecked) {
      return true
    } else {
      return false
    }
  }

  @computed('confirmWorkCompleteSubmitted', 'loan.isRequestFundsNoWorkCompletionRule')
  get showAuthorizeReleaseOfFundsPage() {
    return !!this.confirmWorkCompleteSubmitted || !!this.get('loan.isRequestFundsNoWorkCompletionRule')
  }

  @alias('model.hasBorrowerCompleted')
  hasBorrowerCompleted;

  @alias('model.hasCoborrowerCompleted')
  hasCoborrowerCompleted;

  uploadSubmitted = false;

  @computed('loan.{borrower,coborrower}', 'model')
  get idvStepBorrower() {
    if (this.model.get('name') === 'verify_id_coborrower') {
      return this.get('loan.coborrower');
    } else {
      return this.get('loan.borrower');
    }
  }

  @computed('loan.borrower.id', 'loan.coborrower.id', 'model')
  get currentStepBorrowerId() {
    if (this.model.get('name') === 'verify_id') {
      return {
        id: this.get('loan.borrower.id'),
        key: "borrower"
      };
    } else {
      return {
        id: this.get('loan.coborrower.id'),
        key: "coborrower"
      };
    }
  }

  @computed('model.wasInvalidated', 'model.documentErrors')
  get showResign() {
    return this.get('model.wasInvalidated') || isPresent(this.get('model.documentErrors'));
  }

  @computed('model.wasInvalidated', 'model.name')
  get showGenericError() {
    return !this.get('model.wasInvalidated') && (this.get('model.name') !== 'submit_loan_agreement');
  }

  @computed('model', 'partner.businessName')
  get readyDescription() {
    return this.intl.t(`hub.${this.model.get('name')}.ready.description`, { business_name: this.get('partner.businessName') });
  }

  @computed('model')
  get resignContent() {
    return this.intl.t(`hub.${this.model.get('name')}.ready.resign_if_change`);
  }

  @computed('model', 'partner.businessName')
  get explanation() {
    return this.intl.t(`hub.${this.model.get('name')}.not_ready.explanation`, { business_name: this.get('partner.businessName') });
  }

  @computed('model')
  get subtitle() {
    return this.intl.t(`hub.${this.model.get('name')}.not_ready.subtitle`);
  }

  @computed('loan.fromQuebec', 'model.workCompletionTranslationKey')
  get showSecuritizationLanguage() {
    if (this.get('loan.fromQuebec')) { return false }

    return this.model.get('workCompletionTranslationKey') === 'work_complete'
  }

  @computed('model.isEditable')
  get stepNotReady() {
    return isPresent(this.model.get('isEditable')) && !(this.model.get('isEditable'))
  }

  @computed('loan.{FirstPaymentDate,firstPaymentDate}')
  get formattedFirstPaymentDate() {
    return moment(this.get('loan.FirstPaymentDate')).format(this.intl.t('time.formats.date_only_full_month'))
  }

  @computed('loan.{loanSteps,loanTranches}', 'model.loanTranches', 'partner.businessName')
  get loanTranches() {
    const resolvedSteps = [];

    const trancheData = this.get('loan.loanTranches');
    const trancheInfo = this.get('loan.loanSteps');

    let trancheDataCount = 0;

    trancheInfo.toArray().forEach((obj, ind) => {
      const step = {
        data: trancheData.objectAt(trancheDataCount),
        content: null,
        date: trancheData.objectAt(trancheDataCount)?.get('localizedDate'),
        price: formatAsCurrency(trancheData.objectAt(trancheDataCount)?.get('requestedAmount'), { precision: 2 })
      };

      if (obj.get('signingStep')) {
        step.info = obj;
        step.content = this.intl.t(`hub.funding_roll.plan.${obj.get('name')}`, { merchant: this.get('partner.businessName') });
        trancheDataCount++;
        return resolvedSteps.push(step);
      }
    });
    return resolvedSteps;
  }

  get currentTrancheRequestedAmount() {
    return formatAsCurrency(this.loan.loanTranches.lastObject.requestedAmount, { precision: 2 })
  }

  @action
  disableSubmitLoanAgreementLink() {
    this.set('submitLoanAgreementDocumentClicked', true);
  }

  @action
  handleWorkCompleteContinue(_event) {
    return this.set('confirmWorkCompleteChecked', true);
  }

  @action
  onBackToFrictionClicked() {
    this.set('confirmWorkCompleteChecked', false);
  }

  @action
  toggleResubmitDocuments() {
    this.toggleProperty('uncollapseResubmit')
  }
}

export default PortalHubLoanStepsController;
