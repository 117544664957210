import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { isArray } from '@ember/array'
import { isEmpty } from '@ember/utils'
import { inject as service } from '@ember/service'

export default class UiTableBaseComponent extends Component {
  @service('emt-themes/default') themeInstance

  @tracked currPage = this.args.currPage
  @tracked _pageSize
  @tracked currentGroupingPropertyName

  get itemsCount() {
    return this.args.data?.meta?.recordCount ?? 0
  }

  get pagesCount() {
    return this.args.data?.meta?.pagesCount ?? 0
  }

  get paginationType() {
    return this.args.paginationType ?? 'page-selector'
  }

  get pageSize() {
    if (this.paginationType === 'none') {
      return 999999 // an arbitrarily large number to show all records
    }
    return this._pageSize ?? (this.args.pageSize ?? 10) // default of 10 if pageSize not specified
  }

  get filters() {
    let filters = this.args.filters ?? []

    filters.forEach(filter => {
      if (isEmpty(filter.wormholeId)) {
        throw new Error(
          'You must supply a wormholeId so the filter can be placed'
        )
      }

      // add tw-hidden class on all filters
      // so they don't show up as a column in the table
      filter.className = 'tw-hidden'
    })

    return filters
  }

  get columns() {
    this.args.columns.forEach(column => {
      column.component ?? (column.component = 'ui-table/cells/basic-text')
      column.headerCellClassName ?? (column.headerCellClassName = column.cellClassName)
    })
    return this.args.columns
  }

  get expandedRowTogglesColumn() {
    return {
      component: 'ui-table/cells/expandable-row-toggle',
      disableSorting: true,
      className: this.args.expandedRowOptions?.className,
      cellClassName: 'tw-flex tw-justify-end'
    }
  }

  get selectedItemsColumn() {
    return {
      component: 'ui-table/cells/row-select-checkbox',
      mayBeHidden: false,
      disableSorting: true,
      componentForSortCell: 'ui-table/cells/row-select-all-checkbox'
    }
  }

  get selectableItems() {
    return isArray(this.args.selectedItems)
  }

  get selectRowOnClick() {
    return this.args.selectRowOnClick ?? true
  }

  get expandedRowComponent() {
    return this.args.expandedRowOptions?.component
  }

  get computedColumns() {
    let computedColumns = [...this.columns]

    // toggles column appears to the right of all of the columns
    if (this.expandedRowComponent) {
      computedColumns.push(this.expandedRowTogglesColumn)
    }

    // selected checkboxes appear to the left of all columns
    if (this.selectableItems) {
      computedColumns.unshift(this.selectedItemsColumn)
    }

    // filters are hidden so do not show up as columns
    if (this.filters) {
      computedColumns.push(...this.filters)
    }

    return computedColumns
  }

  @action // action is triggered from UiPageSelector component
  updateCurrPage(goToPageFn, page) {
    goToPageFn(page)
    this.args.updateCurrPage?.(page)

    this.currPage = page
  }

  _setSortProperty(columns) {
    let sort = null
    const sortedColumn = columns.find(column => column.sorting !== 'none')

    if (sortedColumn) {
      sort = (sortedColumn.sorting !== 'desc') ? sortedColumn.sortField : `-${sortedColumn.sortField}`
    }

    this.args.updateSort?.(sort)
  }

  @action
  onDisplayDataChanged(settings) {
    this._pageSize = settings.pageSize
    this.currPage = settings.currentPageNumber
    this.currentGroupingPropertyName = settings.currentGroupingPropertyName

    this._setSortProperty(settings.columns)
    this.args.onDisplayDataChanged?.(settings)
  }
}
