import Controller from '@ember/controller'
import { action, set } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { isPresent } from '@ember/utils'
import { later } from '@ember/runloop'
import { inject as service } from '@ember/service'
import { serializeForCreate } from 'financeit-components/utils/prepare-borrower-for-submit'
import { nextRoute } from '../../utils/application-form-flows'
import secureFetch from '../../utils/secure-fetch'
import { applyErrors } from 'financeit-components/utils/model-helpers'

export default class extends Controller {
  @service intl
  @service router
  @service trustev
  @service store
  @service homeDepotAnalytics
  @tracked showSpinner = false
  @tracked holdingForBureau = false
  submitTimeoutMs = 180 * 1000
  applicationSubmittedAt = null

  queryParams = ['redirectToAddCoborrower']
  @tracked redirectToAddCoborrower = null

  get showSuccessBanner() {
    return this.model.loan.isPrequalified && !this.model.loan.isCounterOffer
  }

  holdForDecision() {
    const qqSimulateTimeoutCheckbox = document.querySelector('#qq_simulate_timeout')
    if (qqSimulateTimeoutCheckbox?.checked) {
      return this.showGeneralError()
    }

    return later(this, async () => {
      const response = await secureFetch(`/${this.intl.language}/api/v3/direct/loans/${this.model.loan.id}/hold_for_decision`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })

      const responseData = await response.text()

      if (isPresent(responseData)) {
        const { loan, borrower, borrowers, status } = JSON.parse(responseData)

        const borrowerArray = Array.isArray(borrowers) ? borrowers : [borrower]
        borrowerArray.forEach(borrower => this.store.pushPayload('borrower', { borrower }))
        this.store.pushPayload('loan', { loan })

        if (this.model.partner.get('isBigBox')) {
          this.trackLoanEventsForHomeDepot(status)
        }

        if (this.store.peekRecord('borrower', borrowerArray[0].id).get('attributesToResolve')?.length > 0) {
          return this.router.transitionTo('portal.reprompt', this.model.loan.loginKey);
        } else if (this.redirectToAddCoborrower) {
          return this.router.transitionTo('portal.soft-decline.add-coborrower', this.model.loan.loginKey)
        }

        return this.router.transitionTo(nextRoute({ status, loan: this.model.loan }), this.model.loan.loginKey)
      } else {
        if (Date.now() > this.applicationSubmittedAt + this.submitTimeoutMs) {
          this.showGeneralError()
        } else {
          this.holdForDecision()
        }
      }
    }, 3000)
  }

  async submitStageTwoLoanApplication() {
    this.applicationSubmittedAt = Date.now()
    this.showSpinner = true

    const data = serializeForCreate({ model: this.model.borrower })

    data.loan_id = this.model.loan.id

    const element = document.querySelector('.quick_qa_container')
    if (element) {
      data.qq_options = QuickQA.get_qq_options_hash()
    }

    const response = await secureFetch(`/${this.intl.language}/api/v3/direct/loans/${this.model.loan.id}/stage_two_loan_application_form`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })

    const responseData = await response.json()

    if (!response.ok) {
      const errors = this.store.adapterFor('loan').fetchError(responseData.errors)
      this.handleErrorMessages(errors)
      return this.showSpinner = false
    }

    this.holdingForBureau = true
    this.holdForDecision()
  }

  handleErrorMessages(errors) {
    if (!this.model.borrower.isValid) { this.model.borrower.errors.clear() }

    applyErrors(this.model.borrower, errors)
  }

  showGeneralError() {
    this.showSpinner = false
    this.showModal('general-error-modal')
  }

  trackLoanEventsForHomeDepot(finalHoldForDecisionStatus) {
    const loanId = parseInt(this.model.loan.id, 10)
    this.homeDepotAnalytics.trackStageTwoLoanEvent(loanId, this.homeDepotAnalytics.applicationSubmittedEventName)

    if (finalHoldForDecisionStatus) {
      this.homeDepotAnalytics.trackStageTwoLoanEvent(loanId, this.homeDepotAnalytics.applicationDeclinedEventName)
    } else {
      this.homeDepotAnalytics.trackStageTwoLoanEvent(loanId, this.homeDepotAnalytics.applicationApprovedEventName)
    }
  }

  @action
  async onSubmit() {
    const annualIncome = this.model.borrower.employmentAnnualIncome
    const housingCosts = this.model.borrower.housingMonthlyCosts
    if (isPresent(annualIncome) && (annualIncome <= this.model.borrower.minAnnualIncome || annualIncome >= this.model.borrower.maxAnnualIncome)) {
      set(this.model.borrower, 'verifyAnnualIncome', null)
      return UiModalUtils.showModal('verify-income-modal')
    }

    if (isPresent(housingCosts) && (housingCosts >= this.model.borrower.maxHousingMonthlyCosts)) {
      this.model.borrower.set('verifyHousingCosts', null);
      return UiModalUtils.showModal('verify-housing-costs-modal');
    }

    await this.submitStageTwoLoanApplication()
  }

  @action
  async onVerifyIncomeSubmit() {
    const housingCosts = this.model.borrower.housingMonthlyCosts;
    if (isPresent(housingCosts) && (housingCosts >= this.model.borrower.maxHousingMonthlyCosts)) {
      this.model.borrower.set('verifyHousingCosts', null);
      return UiModalUtils.showModal('verify-housing-costs-modal');
    }

    await this.submitStageTwoLoanApplication()
  }

  @action
  async onVerifyHousingCostsSubmit() {
    await this.submitStageTwoLoanApplication()
  }

  @action
  showModal(id) {
    UiModalUtils.showModal(id)
  }

  @action
  setupTrustev() {
    this.trustev.setup()
  }
}
