import ApplicationAdapter from './application';
import { underscore } from '@ember/string';
import { pluralize } from 'ember-inflector';

export default class LoanStepAdapter extends ApplicationAdapter {
  pathForType(type) {
    const underscored = underscore(type);
    return pluralize(underscored);
  }
}
