import { isPresent } from '@ember/utils';
import { next } from '@ember/runloop';
import { buildRouteTransitionerForLoanState } from './default-route-transitioner'

export function transitionToDefaultRoute(currentRoute) {
  const loan = currentRoute.controllerFor('loan').get('model')
  const borrower = currentRoute.controllerFor('borrower').get('model')

  const loanState = loan.state
  const transitioner = buildRouteTransitionerForLoanState(loanState, currentRoute, loan, borrower)

  // See https://github.com/emberjs/ember-test-helpers/issues/332 for why we need to wrap this in next()
  next(this, () => transitioner.transition())
}

export function checkAuthenticated(transition, currentRoute) {
  const isLoggedIn = currentRoute.controllerFor('session').get('loggedIn')

  if (!isLoggedIn) {
    const queryParams = { for: currentRoute.paramsFor('portal').login_key }
    if (isPresent(transition.to.queryParams.email)) {
      queryParams.email = transition.to.queryParams.email
    }

    currentRoute.controllerFor('account.login').set('previousTransition', transition)
    currentRoute.transitionTo('account.login', { queryParams })
  }

  return isLoggedIn
}
