// Fetch request wrapper to include custom headers
export default async function secureFetch(url, options = {}) {

  const csrfToken = document.querySelector('[name="csrf-token"]')?.content

  if (options.headers?.method?.toUpperCase() !== 'GET') {
    options.headers = options.headers ?? {}
    options.headers['X-CSRF-Token'] = csrfToken
  }

  return fetch(url, options)
}
