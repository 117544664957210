import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PhoneAnimationComponent extends Component {
  @service intl

  get localizedAnimation() {
    const language = this.intl.primaryLocale === 'fr-ca' ? 'fr' : 'en'
    return `/assets/consumer_direct/apply/phone-animation-${language}.mp4`
  }
}
