export default ({
  component: {
    showPan: {
      cardPan: {
        domId: "mq-card-pan",
        format: true,
        styles: {
          "span": {
            "background": "transparent",
            "color": "black",
            "font-family": "Helvetica Neue, Arial, sans-serif",
            "letter-spacing": "3px",
            "font-size": "1em"
          }
        }
      },
      cardExp: {
        domId: "mq-card-exp",
        format: true,
        styles: {
          "span": {
            "background": "transparent",
            "color": "black",
            "font-family": "Helvetica Neue, Arial, sans-serif",
            "letter-spacing": "2px",
            "font-size": "0.9em"
          }
        }
      },
      cardCvv: {
        domId: "mq-card-cvv",
        styles: {
          "span": {
            "background": "transparent",
            "color": "black",
            "font-family": "Helvetica Neue, Arial, sans-serif",
            "letter-spacing": "2px",
            "font-size": "0.9em"
          }
        }
      }
    }
  }
});
