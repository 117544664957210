/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { htmlSafe } from '@ember/template';
import { helper as buildHelper } from '@ember/component/helper';
import Em from 'ember';

const MapToHelper = buildHelper(function(address) {
  address = Em.Handlebars.Utils.escapeExpression(address);
  const link = '<a href="' + address.mapify() + '" target="_blank">' + address + '</a>';
  return new htmlSafe(link);
});

export default MapToHelper;
