import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class AuthCodeModalComponent extends Component {
  @action
  processAuthCode() {
    this.args.processAuthCode()
  }

  @action
  triggerAuthCode(type) {
    this.args.triggerAuthCode(type)
  }
}
