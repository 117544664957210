import Model, { belongsTo, attr } from "@ember-data/model";

export default class LoanTranche extends Model {
  @belongsTo("loan") loanId;
  @attr("boolean") isDisbursed;
  @attr("number") requestedAmount;
  @attr("number") principalAmount;
  @attr("string") disbursedAt;
  @attr("number") percentage;
  @attr("string") state;
  @attr("number") trancheNumber;
  @attr("string") localizedDate;
  @attr("boolean") isMsfFinalTranche;
  @attr("number") fee;
  @attr("string") description;
}
