import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-incorrect-calls-with-inline-anonymous-functions,
    ember/no-jquery,
    no-undef,
    no-unused-vars
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';
import setTransitionOriginator from '../../utils/set-transition-originator'

@classic
class PortalOffersRoute extends Route {
  @service
  intl;

  init() {
    super.init(...arguments);
    this.on('routeWillChange', this.willTransition.bind(this))
  }

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel(transition) {
    checkAuthenticated(transition, this);
    if (!['approved', 'prequalified', 'msf_funded_but_not_finalized'].includes(this.controllerFor('loan').get('model.state'))) {
      return transitionToDefaultRoute(this);
    }
  }

  setupController(controller, model) {
    const loan = this.controllerFor('loan').get('model');
    $(document).attr('title', this.intl.t('title.offer'));
    controller.set('model', model);
  }

  @action
  willTransition(transition) {
    const model = this.controller.get('model')
    return setTransitionOriginator(transition, model, this.routeName);
  }
}

export default PortalOffersRoute;
