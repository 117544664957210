import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { checkAuthenticated } from "../../../utils/redirect-session";

@classic
export default class PreferencesRoute extends Route {
  @service
  intl;

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel(transition) {
    checkAuthenticated(transition, this);
    document.title = this.intl.t('profile.communication_preferences.title')
  }
}
