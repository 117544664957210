import Component from '@glimmer/component'
import { action } from '@ember/object'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'

@uiFormFieldValue
export default class UiSegmentedControl extends Component {
  get selectedOptionIndex() {
    return this.args.options.map((option) => option.value).indexOf(this.value)
  }

  get lastIndex() {
    return this.args.options.length - 1
  }

  get optionsClasses() {
    return this.args.options.map((_, index) => {
      let classes = ['tw-border-t tw-border-b']
      if (index === 0) {
        classes.push('tw-border-l tw-rounded-l-sm')
      } else if (index === this.lastIndex) {
        classes.push('tw-border-r tw-rounded-r-sm')
      }
      if (index === this.selectedOptionIndex) {
        classes.push(
          'tw-text-ui-black-dark tw-font-bold tw-border-interactive-100 tw-bg-interactive-40'
        )
      }

      return classes.join(' ')
    })
  }

  get rightBorderSelected() {
    return this.args.options.map(
      (_, index) =>
        index === this.selectedOptionIndex ||
        index === this.selectedOptionIndex - 1
    )
  }

  get bgColorComputed() {
    if (this.args.bgColor === 'white') {
      return 'white'
    }

    return 'ui-gray-40'
  }

  @action
  onChange(index, event) {
    this.value = this.args.options[index].value
    this.args.onChange?.(event)
  }
}
