import { action } from '@ember/object'
import Component from '@glimmer/component'
import { run, later } from '@ember/runloop'
import { typeOf, isEmpty } from '@ember/utils'
import { getOwner } from '@ember/application'
import { tracked } from '@glimmer/tracking'

export default class UiPlacesAutocompleteInput extends Component {
  @tracked autocomplete = null
  @tracked previousAttrs = null
  @tracked _value = null

  get country() {
    return this.args.country ?? 'ca'
  }

  get placeType() {
    return this.args.placeType ?? null
  }

  get returnFields() {
    return this.args.returnFields ?? ['address_components', 'geometry']
  }

  get placeholder() {
    return this.args.placeholder ?? ''
  }

  get value() {
    if (!isEmpty(this.args.value) || this.args.value === '') {
      return this.args.value
    }

    return this._value
  }

  set value(value) {
    this._value = value
    this.args.onChange?.(value)
  }

  @action
  onDidInsert() {
    this.setupComponent()
  }

  willDestroy() {
    if (this.autocomplete) {
      const google = window ? window.google : null
      if (google && google.maps && google.maps.event) {
        google.maps.event.clearInstanceListeners(this.autocomplete)
      }
    }

    super.willDestroy(...arguments)
  }

  @action
  onDidUpdate() {
    const anyChanges =
      typeOf(this.previousAttrs) !== 'object'
        ? false
        : Object.keys(this.previousAttrs).some((attr) => {
            return this.previousAttrs[attr] !== this[attr]
          })

    if (anyChanges) {
      this.setupComponent()
    }

    this.previousAttrs = { country: this.country }
  }

  getOptions() {
    const options = {}

    if (this.placeType) {
      options.types = [this.placeType]
    }

    if (this.country) {
      options.componentRestrictions = { country: this.country }
    }

    return options
  }

  setupComponent() {
    if (this.isDestroying || this.isDestroyed) {
      return
    }

    const envConfig = getOwner(this).resolveRegistration('config:environment')

    if (
      this.args.disableAddressAutocomplete ||
      (envConfig && envConfig.environment === 'test')
    ) {
      return
    }

    if (!window?.google?.maps) {
      return later(this, this.setupComponent.bind(this), 250)
    }

    this.setAutocomplete()

    if (this.returnFields) {
      this.autocomplete.setFields(this.returnFields)
    }

    this.autocomplete.addListener('place_changed', () => {
      run(() => this.placeChanged())
    })
  }

  setAutocomplete() {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(this.args.id),
      this.getOptions()
    )

    this.autocomplete = autocomplete
  }

  placeChanged() {
    const place = this.autocomplete.getPlace()

    this.args.onPlaceChanged?.(place)
  }

  @action
  onInputFocus() {
    // Google Places Autocomplete sets "autocomplete" to "off", which does not work.
    // Turning off browser autocomplete seems to work when "autocomplete" is set to a value
    // which the browser does not recognize.
    document
      .getElementById(this.args.id)
      .setAttribute('autocomplete', 'something-new')
  }

  @action
  onInput(event) {
    this.value = event.target.value
  }
}
