import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { applyErrors } from 'financeit-components/utils/model-helpers';

@classic
class AccountSetupController extends Controller {
  queryParams = ['for', 'isCoborrower', 'needsEsignConsent', 'email'];

  @controller
  session;

  @service router;
  @service store;
  @service intl;

  for = null;
  isCoborrower = false;
  needsEsignConsent = false;
  email = null;
  isPasswordCorrect = false;
  showError = false;
  skipTwoFaError = null;

  @computed('model.email', 'email')
  get emailIsValid() {
    if (isPresent(this.email)) return true;
    // the rails form has stricter validations -- this is just to enable sumbits
    return /[^\s@]+@[^\s@]+\.[^\s@]+/.test(this.get('model.email'));
  }

  @computed('emailIsValid', 'isPasswordCorrect')
  get disableSubmit() {
    return !this.isPasswordCorrect || !this.emailIsValid;
  }

  get backButtonHref() {
    return `/${this.intl.language}/direct/account/login`;
  }

  @action
  async onSubmit() {
    let data = null
    try {
      data = await this.store.peekRecord('session', 0).directAccountVerify({
        login_key: this['for'],
        password: this.get('model.password'),
        email: this.get('model.email'),
        is_coborrower: this.isCoborrower,
        esign_consent_received: this.get('model.esignConsentReceived')
      });
    } catch(errorResponse) {
      const errors = {}
      errorResponse.errors.forEach((error) => {
        errors[error.meta.key] = error.meta.errors
      })

      applyErrors(this.model, errors)
    }

    if(data) {
      if (data.token) {
        const params = { token: data.token };
        this.router.transitionTo('account.authenticate', { queryParams: params });
      } else if (data.email_confirmation_sent) {
        this.router.transitionTo('account.email-confirmation');
      }
    }
  }

  @action
  onPasswordValidityChange(isPasswordCorrect) {
    this.set('isPasswordCorrect', isPasswordCorrect)
  }
}

export default AccountSetupController;
