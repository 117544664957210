import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class CountryComponent extends Component {
  @service intl

  get value() {
    const translationKey = `address.country.${this.args.countryCode || ''}`
    return this.intl.t(translationKey, {
      default: 'address.country.international',
      countryCode: this.args.countryCode,
    })
  }
}
