import Component from '@glimmer/component'
import { formatAsCurrency } from 'tag/utils/number-formatters'

export default class PaymentAmountComponent extends Component {
  paymentAmount = formatAsCurrency(this.args.record['paymentAmount'], { precision: 2 })

  get formattedPaymentAmount() {
    if (this.args.column.componentProperties.forPaymentHistory) {
      return `-${this.paymentAmount}`
    }
    return this.paymentAmount
  }
}
