import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'

export default class EmailConfirmationController extends Controller {
  @service store

  @tracked resent = false

  @action
  async resendEmail() {
    const data = await this.store
      .peekRecord('session', 0)
      .resendConfirmationEmail({})

    if (data.email_confirmation_sent) {
      this.resent = true
    }
  }
}
