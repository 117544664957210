import Service, { inject as service } from '@ember/service'
import secureFetch from 'direct/utils/secure-fetch'

export default class BioauthService extends Service {
  /** @type {import('financeit-components/app/services/mobile').default} */
  @service mobile
  @service intl

  eligibilityStatuses = Object.freeze({
    enrolledFaceId: "enrolled_face_id",
    eligibleNoEnrolledFace: "eligible_no_enrolled_face",
    enrolledTouchId: "enrolled_touch_id",
    eligibleNoEnrolledFingerprint: "eligible_no_enrolled_fingerprint",
    notEligible: "not_eligible",
  })

  events = Object.freeze({
    tokenSetup: 'setupBioauthToken',
    bioauthRecognized: 'bioauthRecognized',
    bioauthSetupComplete: 'bioauthSetupComplete',
  })

  _setupTokenHandler = null

  get eligibilityStatus() {
    if (!this.mobile.isIOSApp) {
      return this.eligibilityStatuses.notEligible
    }

    return this.mobile.featureFlags.biometricsEligibility ?? this.eligibilityStatuses.notEligible
  }

  get isPromptDisabled() {
    return this.mobile.featureFlags.disableBioauthPrompt
  }

  get isBioauthSupported() {
    return this.eligibilityStatus !== this.eligibilityStatuses.notEligible
  }

  get isFaceIdEnrolled() {
    return this.eligibilityStatus === this.eligibilityStatuses.enrolledFaceId
  }

  get isTouchIdEnrolled() {
    return this.eligibilityStatus === this.eligibilityStatuses.enrolledTouchId
  }

  get isBioauthEnrolled() {
    return this.isFaceIdEnrolled || this.isTouchIdEnrolled
  }

  get isFaceIdEligible() {
    return this.eligibilityStatus === this.eligibilityStatuses.eligibleNoEnrolledFace
  }

  get isTouchIdEligible() {
    return this.eligibilityStatus === this.eligibilityStatuses.eligibleNoEnrolledFingerprint
  }

  get isBioauthEligible() {
    return this.isFaceIdEligible || this.isTouchIdEligible
  }

  constructor() {
    super(...arguments)

    document.addEventListener('resetBioauth', () => {
      window.webkit.messageHandlers.iosSwiftBridge.postMessage({ name: 'resetBioauth', data: { } })
    })

    document.addEventListener(this.events.tokenSetup, this._setupTokenHandler = () => {
      this.setupBioauthToken()
    })
  }

  willDestroy() {
    super.willDestroy(...arguments)

    document.removeEventListener(this.events.tokenSetup, this._setupTokenHandler)
  }

  async setupBioauthToken() {
    if (!this.isBioauthSupported) {
      return
    }

    const url = `/${this.intl.language}/api/v3/direct/sessions/setup_bioauth_token`
    const res = await secureFetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
    const json = await res.json()
    const bioauthToken = json.bioauth_token

    this.mobile.callBridgeFunction('setup_bioauth_token', bioauthToken)

    return bioauthToken
  }

  async processBioauthToken(token) {
    if (!this.isBioauthSupported) {
      return
    }

    const url = `/${this.intl.language}/api/v3/direct/sessions/process_bioauth_token`
    const res = await secureFetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ bioauth_token: token }),
    })

    const json = await res.json()

    return json
  }

  async promptBioauth() {
    if (this.isBioauthSupported) {
      return this.mobile.callBridgeFunction('prompt_bioauth', 'false')
    }
  }

  async disableBioauthPrompt(isDisabled = true) {
    return this.mobile.callBridgeFunction('disable_bioauth_prompt', String(isDisabled))
  }

  async toggleBioauth(isEnabled) {
    return this.mobile.callBridgeFunction('toggle_bioauth', String(isEnabled))
  }

  async setBioauthEligibility(isEligible) {
    return this.mobile.callBridgeFunction('set_bioauth_eligibility', String(isEligible))
  }
}
