import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
class PortalPaymentMakePaymentRoute extends Route {
  @service
  intl;

  beforeModel() {
    document.title = this.intl.t('make_payment.make_my_payment');
  }
}

export default PortalPaymentMakePaymentRoute;
