import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class UiSpinnerOverlayComponent extends Component {
  @service intl

  get position() {
    return this.args.position || 'fixed'
  }

  get accessibilityLabel() {
    return this.args.accessibilityLabel || this.intl.t('spinner.loading')
  }
}
