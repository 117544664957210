import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { applyErrors } from 'financeit-components/utils/model-helpers'

@classic
class AccountResetPasswordController extends Controller {
  queryParams = ['redirect', 'token'];
  redirect = null;
  token = null;
  isPasswordCorrect = false;

  @service store;
  @service router;

  @computed('isPasswordCorrect')
  get disableSubmit() {
    return !this.isPasswordCorrect;
  }

  @action
  async onSubmit() {
    try {
      const data = await this.store.peekRecord('session', 0).resetPassword({
        password: this.get('model.password'),
        token: this.token
      });

      if (data.token) {
        const params = { token: data.token, resetPassword: true };
        this.router.transitionTo('account.authenticate', { queryParams: params });
      }
    } catch(e) {
      const errors = this.store.adapterFor('session').emberError(e);
      if (errors && errors.expired) {
        return this.router.transitionTo('account.expired-link');
      }

      applyErrors(this.model, errors)
    }
  }

  @action
  onPasswordValidityChange(isPasswordCorrect) {
    this.set('isPasswordCorrect', isPasswordCorrect)
  }
}

export default AccountResetPasswordController;
