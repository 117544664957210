import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PortalPayoutStatementRoute extends Route {
  @service router

  setupController(controller, model) {
    super.setupController(controller, model)
    controller.setTitle()
  }

  afterModel(model) {
    if (!model.loan.isGoodStandingOrArrears || model.loan.isMarbleCreditTopUp || model.loan.isMarbleLinkedCreditTopUp) {
      return this.router.transitionTo('portal.hub', model.loan.get('loginKey'));
    }
  }
}
