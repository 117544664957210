import classic from 'ember-classic-decorator';
import PortalProfileIndexController from '../profile';

@classic
export default class EditEmploymentController extends PortalProfileIndexController {
  queryParams = ['fromHub'];
  fromHub = false;

  get backButtonRoute() {
    if (this.fromHub) {
      return "portal.hub";
    } else {
      return "portal.profile";
    }
  }
}
