import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class ProvinceComponent extends Component {
  @service intl

  get label() {
    const translationKey = `address.province.${this.args.countryCode || ''}`
    return this.intl.t(translationKey, {
      default: 'address.province.international',
    })
  }

  get isDisabled() {
    return Object.keys(this.options).length === 0
  }

  get options() {
    switch (this.args.countryCode) {
      case 'US':
        return this._americanStates()
      case 'CA':
        return this._canadianProvinces()
      default:
        return {}
    }
  }

  // eslint-disable-next-line max-lines-per-function
  _americanStates() {
    return {
      AL: this.intl.t('us_states.al'),
      AK: this.intl.t('us_states.ak'),
      AZ: this.intl.t('us_states.az'),
      AR: this.intl.t('us_states.ar'),
      CA: this.intl.t('us_states.ca'),
      CO: this.intl.t('us_states.co'),
      CT: this.intl.t('us_states.ct'),
      DE: this.intl.t('us_states.de'),
      DC: this.intl.t('us_states.dc'),
      FL: this.intl.t('us_states.fl'),
      GA: this.intl.t('us_states.ga'),
      HI: this.intl.t('us_states.hi'),
      ID: this.intl.t('us_states.id'),
      IL: this.intl.t('us_states.il'),
      IN: this.intl.t('us_states.in'),
      IA: this.intl.t('us_states.ia'),
      KS: this.intl.t('us_states.ks'),
      KY: this.intl.t('us_states.ky'),
      LA: this.intl.t('us_states.la'),
      ME: this.intl.t('us_states.me'),
      MD: this.intl.t('us_states.md'),
      MA: this.intl.t('us_states.ma'),
      MI: this.intl.t('us_states.mi'),
      MN: this.intl.t('us_states.mn'),
      MS: this.intl.t('us_states.ms'),
      MO: this.intl.t('us_states.mo'),
      MT: this.intl.t('us_states.mt'),
      NE: this.intl.t('us_states.ne'),
      NV: this.intl.t('us_states.nv'),
      NH: this.intl.t('us_states.nh'),
      NJ: this.intl.t('us_states.nj'),
      NM: this.intl.t('us_states.nm'),
      NY: this.intl.t('us_states.ny'),
      NC: this.intl.t('us_states.nc'),
      ND: this.intl.t('us_states.nd'),
      OH: this.intl.t('us_states.oh'),
      OK: this.intl.t('us_states.ok'),
      OR: this.intl.t('us_states.or'),
      PA: this.intl.t('us_states.pa'),
      PR: this.intl.t('us_states.pr'),
      RI: this.intl.t('us_states.ri'),
      SC: this.intl.t('us_states.sc'),
      SD: this.intl.t('us_states.sd'),
      TN: this.intl.t('us_states.tn'),
      TX: this.intl.t('us_states.tx'),
      UT: this.intl.t('us_states.ut'),
      VT: this.intl.t('us_states.vt'),
      VA: this.intl.t('us_states.va'),
      WA: this.intl.t('us_states.wa'),
      WV: this.intl.t('us_states.wv'),
      WI: this.intl.t('us_states.wi'),
      WY: this.intl.t('us_states.wy'),
    }
  }

  _canadianProvinces() {
    return {
      AB: this.intl.t('provinces.ab'),
      BC: this.intl.t('provinces.bc'),
      MB: this.intl.t('provinces.mb'),
      NB: this.intl.t('provinces.nb'),
      NL: this.intl.t('provinces.nl'),
      NS: this.intl.t('provinces.ns'),
      ON: this.intl.t('provinces.on'),
      PE: this.intl.t('provinces.pe'),
      QC: this.intl.t('provinces.qc'),
      SK: this.intl.t('provinces.sk'),
      NT: this.intl.t('provinces.nt'),
      NU: this.intl.t('provinces.nu'),
      YT: this.intl.t('provinces.yt'),
    }
  }
}
