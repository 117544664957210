import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop'
import { alias } from '@ember/object/computed';
import PortalSoftDeclineController from '../soft-decline';
import { serializeForCreate } from 'financeit-components/utils/prepare-borrower-for-submit'
import { isPresent } from '@ember/utils'
import { tracked } from '@glimmer/tracking'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { applyErrors } from 'financeit-components/utils/model-helpers'

@classic
class PortalSoftDeclineAddCoborrowerController extends PortalSoftDeclineController {
  @service
  intl;

  @service store

  @controller('borrower')
  borrowerController;

  @alias('borrowerController.model')
  borrower;

  @tracked holdingForBureau = false;
  submitTimeoutMs = 180 * 1000;
  applicationSubmittedAt = null;

  holdForDecision() {
    return later(this, async () => {
      const response = await fetch(`/${this.intl.language}/api/v3/direct/loans/${this.get('loan.id')}/coborrower_hold_for_decision`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })

      const responseData = await response.text()

      if (isPresent(responseData)) {
        const { loan, borrower, coborrower, borrowers, status } = JSON.parse(responseData)

        this.store.pushPayload('loan', { loan })

        if (status) {
          this.store.push(this.store.normalize('borrower', borrower));
          this.store.push(this.store.normalize('borrower', coborrower));
          if (this.get('loan.state') === 'declined') {
            return this.transitionToRoute('portal.hard-decline', this.get('loan.loginKey'));
          } else if (coborrower.hard_declined) {
            return this.transitionToRoute('portal.offers.offer-approved', this.get('loan.loginKey'), { queryParams: { isDeclinedCobo: true }})
          } else if ((coborrower.attributes_to_resolve.length > 0) && (status === "resolve-mismatch-warnings")) {
            return this.transitionToRoute('portal.reprompt', this.get('loan.loginKey'));
          } else {
            return this.transitionToRoute('portal.soft-decline', this.get('loan.loginKey'));
          }
        } else {
          this.store.push(this.store.normalize('borrower', borrowers[0]));
          this.store.push(this.store.normalize('borrower', borrowers[1]));

          return this.transitionToRoute('portal.offers.offer-approved', this.get('loan.loginKey'));
        }
      } else {
        if (Date.now() > this.applicationSubmittedAt + this.submitTimeoutMs) {
          this.showGeneralError()
        } else {
          this.holdForDecision()
        }
      }
    }, 3000)
  }

  @action
  back() {
    history.back()
  }

  @action
  onSubmit() {
    this.applicationSubmittedAt = Date.now()
    this.set('showSpinner', true);

    let qq_options;
    // eslint-disable-next-line ember/no-jquery
    if ($(".quick_qa_container").length) { qq_options = QuickQA.get_qq_options_hash(); }

    // OR-4399 E-sign checkbox has been eliminated; set value on model to '1' for implicit acceptance before submitting
    this.borrower.set('esignConsentReceived', '1');
    // OR-4400 phoneType has been removed from application form, but API still requires it; set value on model to 'Mobile' before submitting
    this.borrower.set('phoneType', 'Mobile');

    const coborrower = serializeForCreate({ model: this.borrower })["borrower"]
    const data = {
      id: this.get('loan.id'),
      loan: this.loan.serialize(),
      coborrower_form: coborrower,
      qq_options
    };

    // eslint-disable-next-line ember/no-jquery
    return $.ajax({
      type: 'PUT',
      url: `/${this.intl.language}/api/v3/direct/loans/${this.get('loan.id')}/add_coborrower`,
      data
    }).then(() => {
      this.holdingForBureau = true;
      this.holdForDecision();
    }, jqXHR => {
      this.set('showSpinner', false);
      const errors = this.store.adapterFor('borrower').ajaxError(jqXHR);

      if (!this.get('borrower.isValid')) { this.get('borrower.errors').clear(); }

      applyErrors(this.borrower, errors);
    });
  }

  showGeneralError() {
    this.set('showSpinner', false)
    UiModalUtils.showModal('general-error-modal')
  }
}


export default PortalSoftDeclineAddCoborrowerController;
