import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

export default class UiCheckboxBasicComponent extends Component {
  @tracked _checked

  get checked() {
    if (this.args.checked !== undefined) {
      return this.args.checked
    }

    return this._checked
  }

  get name() {
    return this.args.name ?? this.args.label
  }

  @action
  onChange(event) {
    this._checked = event.target.checked
    this.args.onChange?.(event)
  }
}
