import classic from 'ember-classic-decorator';
import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';
import { transitionToDefaultRoute } from '../../../utils/redirect-session';

@classic
class PortalBankingChooseAccountRoute extends Route {
  beforeModel() {
    const loan = this.controllerFor('loan').get('model');

    if (!isPresent(loan.get('flinksSummaries'))) {
      return transitionToDefaultRoute(this);
    }
  }

  setupController() {
    const loan = this.controllerFor('loan').get('model');
    const borrower = this.controllerFor('borrower').get('model');
    if (isPresent(loan.get('flinksSummaries'))) {
      return borrower.set('chosenBankAccountId', loan.get('flinksSummaries')[0].id);
    }
  }
}

export default PortalBankingChooseAccountRoute;
