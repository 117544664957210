import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import PortalOffersController from '../offers';

@classic
export default class AlternativePoiController extends PortalOffersController {
  @service('loading-indicator')
  loading_animation;

  @action
  useNewPoiAmount() {
    this.loading_animation.show();
    this.loan.togglePoi().then(() => this.transitionToRoute('portal.offers.offer-approved', { queryParams: { reload: true } }));

    return false;
  }
}
