import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { closeModal } from 'tag/utils/ui-modal'

@classic
class PortalOffersOfferApprovedRoute extends Route {
  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setTitle();
  }

  @action
  didTransition() {
    this.controller.showCoboDeclinedModal();
  }

  @action
  willTransition() {
    closeModal('declined_cobo')
    return true
  }
}

export default PortalOffersOfferApprovedRoute;
