import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
/* eslint-disable
    ember/jquery-ember-run,
    ember/no-jquery,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import PortalController from '../portal';

@classic
class PortalPaymentController extends PortalController {
  paymentMethod = 'transfer';
  errorMessage = '';
  additionalErrorMessage = '';
  errorMessageCount = 0;
  processing = false;
}

export default PortalPaymentController;
