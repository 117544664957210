import Model, { belongsTo, attr } from "@ember-data/model";
import { memberAction } from "ember-api-actions";

export default class ShoppingPass extends Model {
  @belongsTo("borrower") borrower;
  @attr widgetConfig;
  @attr physicalCard;
  @attr transactions;
  @attr("date") originalCreditIssuedEndDate;
  @attr("number") potentialAmountLeft;
  @attr("number") potentialAmountSpent;
  @attr("number") pendingAmount;
  @attr("string") clientAccessToken;
  @attr("boolean") frozen;
  @attr("boolean") pendingFinalization;
}

ShoppingPass.prototype.orderPhysicalCard = memberAction({
  path: "order_physical_card",
  type: "post",
});

ShoppingPass.prototype.getSingleUseBarcode = memberAction({
  path: "get_single_use_barcode",
  type: "get",
});

ShoppingPass.prototype.getMultiUsePhysicalCardOnetimeToken = memberAction({
  path: "get_multi_use_physical_card_onetime_token",
  type: "get",
});
