import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';

@classic
class PortalProfileIndexController extends Controller {
  @service
  intl;

  @service bioauth

  @alias('model.borrower')
  borrower;

  @alias('model.loan')
  loan;

  queryParams = ['saveSucceeded'];
  saveSucceeded = false;

  @computed('borrower.{isMailingAddressDifferentThanResidential,mailingAddress,mailingCity,mailingPostalCode,mailingProvince,mailingUnitNumber}')
  get mailingAddress() {
    if (this.get('borrower.isMailingAddressDifferentThanResidential')) {
      return this.addressFormat([this.get('borrower.mailingUnitNumber'), this.get('borrower.mailingAddress'), this.get('borrower.mailingCity'), this.get('borrower.mailingProvince'), this.get('borrower.mailingPostalCode')])
    } else {
      return this.intl.t('profile.personal_details.same_as_home_address')
    }
  }

  @computed('borrower.{unitNumber,address,city,province,postalCode}')
  get homeAddress() {
    return this.addressFormat([this.get('borrower.unitNumber'), this.get('borrower.address'), this.get('borrower.city'), this.get('borrower.province'), this.get('borrower.postalCode')])
  }

  @computed('borrower.{employerAddress,employerCity,employerProvince}')
  get employerAddress() {
    return this.addressFormat([this.get('borrower.employerAddress'), this.get('borrower.employerCity'), this.get('borrower.employerProvince')])
  }

  addressFormat(addressArray) {
    return addressArray.filter(addressPart => !(isEmpty(addressPart))).join(", ")
  }

  @computed('borrower.canSelfUpdatePii')
  get disableEdit() {
    return !(this.get('borrower.canSelfUpdatePii'))
  }

  pushErrorMessages(changeset, errors) {
    errors.forEach((error) => {
      for (let key in error) {
        const errorsArray = Array.isArray(error[key]) ? error[key] : [error[key]]
        changeset.pushErrors(key, ...errorsArray);
      }
    });
  }
}

export default PortalProfileIndexController;
