import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'
/* eslint-disable
    ember/avoid-leaking-state-in-ember-objects,
    ember/no-jquery,
    ember/no-observers,
    ember/use-brace-expansion,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { schedule } from '@ember/runloop';
import PortalController from '../portal';
import { addClass, removeClass } from 'financeit-components/utils/dom-node-helpers';
import * as UiModalUtils from 'tag/utils/ui-modal'
import { A } from '@ember/array';
import { formatAsCurrency } from 'tag/utils/number-formatters'

@classic
export default class ShoppingPassDashboardController extends PortalController {
  @service
  intl;

  @service
  store;

  @controller
  session;

  showPurchaseAmountValidationMessage = false;
  isSelectedAmortizationValid = true;
  opcDisabled = false;
  changePinUrl = '';
  setPinUrl = '';
  activateCardUrl = '';
  showSpinner = false;
  showLoanModalSpinner = false;
  errorMessage = null;
  sessionData = null;
  loginKey = null;

  @computed('session.activeLoansCount')
  get multipleActiveLoans() {
    return this.session.activeLoansCount > 1
  }

  @computed('session.loggedInUser.id')
  get loggedInEmail() {
    const loggedInUserId = this.get('session.loggedInUser.id')
    const loggedInBorrower = this.store.peekAll('borrower').findBy('id', loggedInUserId)
    return loggedInBorrower.email
  }

  init() {
    super.init(...arguments);
    this.set('notifications', A([]));
  }

  orderWidgetSuccessURL() {
    return `https://financeit.ca/${this.intl.language}-marqeta-order-success.html`;
  }

  resetWidgetSuccessURL() {
    return `https://financeit.ca/${this.intl.language}-marqeta-reset-success.html`;
  }

  get arrearsOrFrozenOrPendingFinalization() {
    return this.isInArrears || this.model.frozen || this.shoppingPass.pendingFinalization
  }

  @computed('intl.language')
  get getPageLocale() {
    return this.intl.language === 'fr' ? 'fr-CA' : 'en-US';
  }

  setupDashboard() {
    return schedule('afterRender', this, () => {
      this.checkTransactionSize;

      $("#barcode").off().on("hidden.bs.modal", (() => $('#barcodeLoader').hide()));

      addClass('.footer-offer-expiry', 'tw-hidden')

      if (this.get('loan.isShoppingPass') && (this.get('loan.requestedAmount') < this.globalMinRequestedAmount)) {
        return $('#payment_calculator').off();
      }
    });
  }

  @computed('model.transactions')
  get transactions() {
    return this.get('model.transactions');
  }

  @computed('model.originalCreditIssuedEndDate')
  get shoppingPassExpiryDate() {
    return moment(this.get('model.originalCreditIssuedEndDate')).format('MMM D, YYYY');
  }

  @computed('model.physicalCard.pin_is_set')
  get pinNotSet() {
    return !this.get('model.physicalCard.pin_is_set');
  }

  @computed('loan.nextScheduledPayment.{date,raw_date}')
  get nextScheduledPayment() {
    return moment(this.get('loan.nextScheduledPayment.raw_date')).format('MMM D, YYYY');
  }

  @computed('loan.nextScheduledPayment', 'loan.nextScheduledPayment.due')
  get nextPaymentAmount() {
    if (this.get('loan.nextScheduledPayment') !== null) {
      return this.get('loan.nextScheduledPayment.due');
    } else {
      return 0;
    }
  }

  @computed('model.potentialAmountLeft')
  get availCredit() {
    return this.get('model.potentialAmountLeft');
  }

  @computed('model.potentialAmountSpent')
  get purchaseBalance() {
    return this.get('model.potentialAmountSpent');
  }

  @computed('model.pendingAmount')
  get pendingAmount() {
    return this.get('model.pendingAmount');
  }

  @computed('loan.requestedAmount')
  get globalMinRequestedAmount() {
    return 1000;
  }

  @computed('loan.maxAmount')
  get globalMaxRequestedAmount() {
    return this.get('loan.maxAmount');
  }

  @computed('model.physicalCard.is_ordered')
  get physicalCardOrdered() {
    return this.get('model.physicalCard.is_ordered');
  }

  // See https://www.marqeta.com/docs/developer-guides/using-activate-card-and-set-pin-widgets
  // for info about Marqueta iframe params
  preparePinWidget(data) {
    this.set('model.physicalCard', data.physical_card);
    this.set('setPinUrl', `${this.get('model.widgetConfig.url')}/set_pin?one_time_token=${this.get('model.physicalCard.onetime_token')}&user_token=${this.get('model.physicalCard.child_user_token')}&card_token=${this.get('model.physicalCard.token')}&application_id=${this.get('model.widgetConfig.application_id')}&success_url=${this.orderWidgetSuccessURL()}&locale=${this.getPageLocale}`);
    return removeClass('#processingLoading .ui.dimmer', 'active')
  }

  generateChangePinUrl() {
    this.set('changePinUrl', '');
    return this.model.getMultiUsePhysicalCardOnetimeToken().then((data, status, xhr) => {
      this.set('model.physicalCard', data.physical_card);
      this.set('changePinUrl', `${this.get('model.widgetConfig.url')}/set_pin?one_time_token=${this.get('model.physicalCard.onetime_token')}&user_token=${this.get('model.physicalCard.child_user_token')}&card_token=${this.get('model.physicalCard.token')}&application_id=${this.get('model.widgetConfig.application_id')}&success_url=${this.resetWidgetSuccessURL()}&locale=${this.getPageLocale}`);
    });
  }

  generateActivateCardUrl() {
    this.set('activateCardUrl', '');
    return this.model.getMultiUsePhysicalCardOnetimeToken().then((data, status, xhr) => {
      this.set('model.physicalCard', data.physical_card);
      this.set('activateCardUrl', `${this.get('model.widgetConfig.url')}/activate_card?one_time_token=${this.get('model.physicalCard.onetime_token')}&user_token=${this.get('model.physicalCard.child_user_token')}&application_id=${this.get('model.widgetConfig.application_id')}&locale=${this.getPageLocale}`);
    });
  }

  async getLoans() {
    this.set('sessionData', null);
    this.set('loginKey', this.store.peekAll('loan').objectAt(0).loginKey);

    try {
      const data = await this.store.peekRecord('session', 0).loans({login_key: this.loginKey});
      this.set('sessionData', data);
    } catch(e) {
      this.set('errorMessage', e.errors[0]);
    }

    this.set('showSpinner', false);
    this.set('showLoanModalSpinner', false);
  }

  setPinWidgetUrl() {
    this.set('setPinUrl', "");
    addClass('#processingLoading .ui.dimmer', 'active')
    return this.model.getMultiUsePhysicalCardOnetimeToken().then((data, status, xhr) => {
      return this.preparePinWidget(data);
    });
  }

  get anyTransactions() {
    return this.transactions.length > 0
  }

  get moreThanFiveTransactions() {
    return this.transactions.length > 5
  }

  get hasPurchaseBalance() {
    return this.loan.principalAmount > 0
  }

  get shoppingPassInfoRows() {
    let rows = []

    rows.push({
      label: this.intl.t('dashboard.purchase_balance'),
      value: formatAsCurrency(this.purchaseBalance, { precision: 2 }),
      valueBold: true,
      divider: true,
    })

    if (this.pendingAmount > 0) {
      rows.push({
        label: this.intl.t('dashboard.pending_amount'),
        value: formatAsCurrency(this.pendingAmount, { precision: 2 }),
        divider: !this.shoppingPass.pendingFinalization,
      })
    }

    if (!this.shoppingPass.pendingFinalization) {
      rows.push({
        label: this.intl.t('dashboard.available_credit'),
        value: formatAsCurrency(this.availCredit, { precision: 2 }),
        divider: this.hasPurchaseBalance,
      })

      if (this.hasPurchaseBalance) {
        rows.push({
          label: this.intl.t('dashboard.remaining_principal_balance'),
          value: formatAsCurrency(this.loan.amountNeededToPayOffLoan, { precision: 2 }),
          divider: true,
        })

        const label = this.intl.t('dashboard.next_principal_only_payment')

        rows.push({
          label: label,
          value: formatAsCurrency(this.nextPaymentAmount, { precision: 2 }),
          valueBold: true,
          labelInfoModal: 'minimum-payment-modal',
          divider: true,
        })

        rows.push({
          label: this.intl.t('dashboard.next_payment_due'),
          value: this.nextScheduledPayment,
        })
      }
    }

    return rows
  }

  get creditIssued() {
    return this.loan.state === 'credit_issued'
  }

  get showShopUntilDate() {
    return this.creditIssued && !this.arrearsOrFrozenOrPendingFinalization
  }

  get physicalCardActivated() {
    return this.model.physicalCard.is_activated
  }

  get physicalCardOrderedButNotActivated() {
    return this.physicalCardOrdered && !this.physicalCardActivated
  }

  get showSetUpPhysicalCardAlert() {
    return this.physicalCardOrderedButNotActivated && !this.arrearsOrFrozenOrPendingFinalization
  }

  get showCardDetails() {
    return this.creditIssued && !this.physicalCardActivated
  }

  get showVirtualCardTerms() {
    return !this.physicalCardActivated && !this.arrearsOrFrozenOrPendingFinalization
  }

  @action
  orderPhysicalCard() {
    addClass('#processingLoading .ui.dimmer', 'active')
    return this.model.orderPhysicalCard().then((data, status, xhr) => {
      return this.preparePinWidget(data);
    });
  }

  @action
  async showLoanModal(id) {
    UiModalUtils.showModal(id)
    this.set('showLoanModalSpinner', true);
    await this.getLoans()
  }

  @action
  closeModal(id) {
    UiModalUtils.closeModal(id);
  }

  @action
  openShoppingPassLearnMoreModal() {
    UiModalUtils.showModal('shopping_pass_learn_more')
  }

  @action
  openPaymentCalculator() {
    return this.transitionToRoute('portal.shopping-pass-dashboard.calculator');
  }
}
