import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';

@classic
export default class ShoppingPassDashboardRoute extends Route {
  @service
  intl;

  @service store
  @service router

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel(transition) {
    const isLoggedIn = checkAuthenticated(transition, this);
    if (!isLoggedIn) { return; }
    // eslint-disable-next-line ember/no-jquery
    $(document).attr('title', this.intl.t('title.hub'));
    return this.getCreditsData();
  }

  getCreditsData() {
    const id = this.controllerFor('loan').get('model.id');
    const shoppingPassApiUrl = `/${this.intl.language}/api/v3/direct/shoppingPasses/${id}`;

    // eslint-disable-next-line ember/no-jquery
    return $.ajax({ type: 'GET', url: shoppingPassApiUrl }).then((data) => {
      if (data && data.shopping_pass) {
        this.store.push(this.store.normalize('shoppingPass', data.shopping_pass));
        // eslint-disable-next-line ember/no-jquery
        $('.ember-loader').css({ display: 'none', opacity: 0 });
        return this.transitionDeclinedLoans();
      } else {
        return this.transitionInvalidUsers();
      }
    }, () => {
      return this.transitionInvalidUsers();
    });
  }

  model() {
    const id = this.controllerFor('loan').get('model.id');
    let shoppingPassModel = this.store.peekRecord('shoppingPass', id);
    return shoppingPassModel
  }

  transitionInvalidUsers() {
    this.controllerFor('loan').set('model.state', 'approved');
    return this.router.transitionTo('portal.hub');
  }

  transitionDeclinedLoans() {
    if (this.controllerFor('loan').get('model.state') !== 'approved') {
      return transitionToDefaultRoute(this);
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    return controller.setupDashboard();
  }
}
