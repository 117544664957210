import Route from '@ember/routing/route';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';

export default class SoftDeclineRoute extends Route {
  beforeModel(transition) {
    checkAuthenticated(transition, this);

    const loan = this.controllerFor('loan').get('model')

    if (transition.to.name === 'portal.soft-decline.add-coborrower') {
      return
    }

    if (!['high_tdsr', 'coborrower_mandatory'].includes(loan.state)) {
      if (!loan.isReactivatable) {
        transitionToDefaultRoute(this);
      }
    }
  }
}
