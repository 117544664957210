import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class UiRadioButtonComponent extends Component {
  get textSize() {
    return this.args.textSize || 'lg'
  }

  get textColor() {
    return this.args.disabled ? 'ui-gray-dark' : 'ui-black'
  }

  get checkmarkClasses() {
    if (this.args.disabled) {
      return 'tw-border-ui-gray-dark'
    }

    return 'tw-border-interactive-100'
  }

  @action
  onDidRender(element) {
    // Required for IE11 to correctly report its value
    element.querySelector('input[type=radio]').value = this.args.value
  }

  @action
  onChange(event) {
    this.args.onChange?.(event.target?.value)
  }
}
