import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal @id=\"loan-selection-modal\" as |m|>\n  <m.header @title={{t \"hub.navigate_loans\"}} />\n  <m.content>\n    {{#if @showSpinner}}\n      <UiSpinnerOverlay @position=\"relative\"/>\n    {{/if}}\n    <div class=\"tw-flex tw-flex-wrap tw-items-start tw--mx-3\">\n      {{#each @loans.loans as |loanItem|}}\n        <div class=\"tw-p-3 tw-w-full md:tw-w-1/2\" id=\"loan-item-{{loanItem.name}}\">\n          <OptionCard::Button @loanName={{loanItem.name}} @loanSelectionHeading={{loanItem.loan_selection_heading}} @onClick={{action @setCurrentlySelectedLoan loanItem.login_key}}/>\n        </div>\n      {{/each}}\n    </div>\n    <UiText @tagName=\"span\" @text={{@errorMessage}} @my=\"4\" @textColor=\"error-100\" />\n  </m.content>\n</UiModal>", {"contents":"<UiModal @id=\"loan-selection-modal\" as |m|>\n  <m.header @title={{t \"hub.navigate_loans\"}} />\n  <m.content>\n    {{#if @showSpinner}}\n      <UiSpinnerOverlay @position=\"relative\"/>\n    {{/if}}\n    <div class=\"tw-flex tw-flex-wrap tw-items-start tw--mx-3\">\n      {{#each @loans.loans as |loanItem|}}\n        <div class=\"tw-p-3 tw-w-full md:tw-w-1/2\" id=\"loan-item-{{loanItem.name}}\">\n          <OptionCard::Button @loanName={{loanItem.name}} @loanSelectionHeading={{loanItem.loan_selection_heading}} @onClick={{action @setCurrentlySelectedLoan loanItem.login_key}}/>\n        </div>\n      {{/each}}\n    </div>\n    <UiText @tagName=\"span\" @text={{@errorMessage}} @my=\"4\" @textColor=\"error-100\" />\n  </m.content>\n</UiModal>","moduleName":"direct/components/hub/loan-selection-modal.hbs","parseOptions":{"srcName":"direct/components/hub/loan-selection-modal.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'
import * as UiModalUtils from 'tag/utils/ui-modal'

export default class LoanSelectionModalComponent extends Component {
  @action
  closeModal(id) {
    UiModalUtils.closeModal(id)
  }
}
