import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import RSVP from 'rsvp';
import { isPresent } from '@ember/utils';

@classic
class PaymentPlanApplyRoute extends Route {
  @service
  store;

  @service
  snowplow;

  @service
  intl;

  activate() {
    resetScroll()
  }

  model(params) {
    const { store } = this;

    if (params?.inviteId) {
      return store.queryRecord('direct-invite', { invite_id: params.inviteId }).then(invite => RSVP.hash({
        directInvite: invite,
        quote: invite.get('quote')
      }));
    } else {
      // To retrieve the quote for a shareable link (no direct inviteId)
      // A hacky way to access what should be the only quote
      return { quote: store.peekAll('quote').objectAt(0) }
    }
  }

  afterModel(model) {
    if ((model != null) && (model.quote != null)) {
      const { quote } = model;
      const quote_params = quote.serialize();
      quote.set('subvention', this.store.peekAll('subvention').objectAt(0))
      quote_params["subvention_id"] = model.quote.get('subvention.id')

      return quote.calculate(quote_params).then(response => {
        return quote.set('calculations', response);
      });
    }
  }

  setupController(controller, model, transition) {
    // eslint-disable-next-line ember/no-jquery
    $(document).attr('title', this.intl.t('title.apply_now'));

    if (transition.from == null || transition.from.name !== 'payment-plan.application-form') {
      // if coming back from application - we dont want to 'lock' the calculator into a specific quote, if it wasnt already
      controller.set('hasQuoteWithAmount', isPresent(model.quote.get('amount')));
    }

    controller.set('model', model);

    if (controller.showCalculator) {
      this.triggerSnowplowEvent();
    }
  }

  triggerSnowplowEvent() {
    this.snowplow.snowplow('trackStructEvent', {
      category: 'Apply Now',
      action: 'Calculator is Visible',
      property: 'true'
    });
  }
}

export default PaymentPlanApplyRoute;
