import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { action } from '@ember/object'
import { inject as service } from '@ember/service';

export default class SoftDeclineIndexRoute extends Route {
  @service intl;

  constructor() {
    super(...arguments);
    this.on('routeWillChange', this.willTransition.bind(this))
    this.on('routeDidChange', this.didTransition.bind(this))
  }

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel() {
    document.title = this.intl.t('title.soft_decline');
  }

  @action
  didTransition() {
    this.controller.showVerifyIncomeModal();
  }

  @action
  willTransition(transition) {
    if (!transition.targetName.match('soft-decline')) {
      this.controller.set('verifyIncomeModalShown', false);
    }

    return true;
  }
}
