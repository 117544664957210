import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

@classic
class ShoppingPassCalculator extends Route {
  @service store

  globalMinRequestedAmount = 1000;

  model() {
    const loan = this.controllerFor('loan').get('model');
    const quote = this.getQuote(loan);
    return RSVP.hash({
      loan,
      quote
    })
  }

  deactivate() {
    const quote = this.controller.get('model.quote');
    
    if (quote) {
      this.store.deleteRecord(quote);
    }
  }

  async getQuote(loan) {
    const partner = loan.get('partner');
    const vertical = loan.get('vertical');

    let subvention = this.store.peekRecord('subvention', loan.get('subventionProgramId'));

    if (!subvention) {
      subvention = this.store.createRecord('subvention', { id: loan.get('subventionProgramId') })
    }

    this.store.pushPayload('vertical', { vertical });

    let quote = await loan.quote;

    if (!quote) {
      quote = this.store.createRecord('quote', {
        feesAbsorbed: loan.get('feesAbsorbed'),
        feesWaived: partner.get('adminFeesWaived'),
        partner,
        loan,
        subvention,
        vertical: this.store.peekRecord('vertical', vertical.id),
        paymentFrequency: loan.get('paymentFrequency'),
        quebec: loan.get('fromQuebec'),
        selectedPartnerId: partner.get('id'),
        useMaxAmortization: true,
        amortization: loan.get('originalAmortization'),
        amount: Math.max(this.globalMinRequestedAmount, loan.currentPrincipalBalance),
      });

      loan.quote = quote;
    }

    const quoteParams = {
      ...quote.serialize(),
      subvention_id: subvention.get('id'),
    };

    return quote.calculate(quoteParams).then(response => {
      quote.set('calculations', response);
      return quote;
    });
  }
}


export default ShoppingPassCalculator;
