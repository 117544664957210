import {
  addClass,
  removeClass,
} from 'financeit-components/utils/dom-node-helpers'

export function onActivate() {
  addClass('html', 'stepOpen')

  document.querySelectorAll('.stepWrapper').forEach(el => {
    el.style.transform = 'translateX(-100vw)'
  })

  document.querySelectorAll('.step').forEach(el => {
    el.style.transform = 'translateX(0vw)'
  })
}

export function onDeactivate() {
  removeClass('html', 'stepOpen')

  document.querySelectorAll('.stepWrapper').forEach(el => {
    el.style.transform = 'translateX(0vw)'
  })
}
