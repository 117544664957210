import AccountLoginRoute from './login'
import { service } from '@ember/service'

class HdLoginRoute extends AccountLoginRoute {
  @service mobile
  @service router

  templateName = 'account.login'

  beforeModel(transition) {
    if (this.doRedirectToFitLogin(transition)) {
      return this.router.transitionTo('account.login')
    }

    this.controllerFor('account').set('shoppingPassLoan', true)
    this.controllerFor('session').set('managingGroup', 'home_depot')
    return super.beforeModel(transition)
  }

  afterModel() {
    const { pendo, pendoData } = window
    if (pendo && pendoData) {
      const newPendoData = { ...pendoData }
      newPendoData.visitor.productLine =
        this.controllerFor('session').get('managingGroup')
      pendo.updateOptions(newPendoData)
    }

    return super.afterModel()
  }

  doRedirectToFitLogin(transition) {
    return this.mobile.isMobileApp && !this.mobile.isHdMobileApp && transition.to.name === 'account.hdlogin'
  }
}

export default HdLoginRoute
