import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
class PortalPaymentOptionsConfirmationRoute extends Route {
  @service
  intl;

  model() {
    return this.modelFor('portal.payment-options.review.index')
  }

  beforeModel() {
    document.title = this.intl.t('payment_options.review.title');
  }
}

export default PortalPaymentOptionsConfirmationRoute;
