import Component from '@glimmer/component'

export default class UiButtonContentComponent extends Component {
  get iconColor() {
    switch (this.args.kind) {
      case 'secondary':
      case 'tertiary':
        return this.args.isDisabled ? 'ui-gray-dark':  'interactive-100'
      case 'basic':
        return 'interactive-100'
      default:
        return 'white'
    }
  }

  get iconSizeComputed() {
    switch (this.args.textSize) {
      case 'xs':
        return 3
      case 'sm':
        return 4
      case 'xl':
      case '2xl':
        return 6
      case '3xl':
      case '4xl':
      case '5xl':
      case '6xl':
        return 8
      case 'base':
      case 'lg':
      default:
        return 5
    }
  }
}
