import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"tw-flex tw-flex-col\">\n  <div class=\"tw-flex\">\n    {{!-- template-lint-disable form-components-must-be-wrapped --}}\n    <UiDateTimePicker\n      @label={{t \"resolve_mismatch_warnings.enter_dob\" htmlSafe=true}}\n      @minDate={{@minBirthdate}}\n      @maxDate={{@maxBirthdate}}\n      @onChange={{this.onChange}}\n    />\n  </div>\n  {{#if @errorMessage}}\n    <div class=\"tw-w-full\">\n      <UiHint @kind=\"error\" @text={{@errorMessage}} />\n    </div>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"tw-flex tw-flex-col\">\n  <div class=\"tw-flex\">\n    {{!-- template-lint-disable form-components-must-be-wrapped --}}\n    <UiDateTimePicker\n      @label={{t \"resolve_mismatch_warnings.enter_dob\" htmlSafe=true}}\n      @minDate={{@minBirthdate}}\n      @maxDate={{@maxBirthdate}}\n      @onChange={{this.onChange}}\n    />\n  </div>\n  {{#if @errorMessage}}\n    <div class=\"tw-w-full\">\n      <UiHint @kind=\"error\" @text={{@errorMessage}} />\n    </div>\n  {{/if}}\n</div>\n","moduleName":"direct/components/portal/reprompt/date-of-birth.hbs","parseOptions":{"srcName":"direct/components/portal/reprompt/date-of-birth.hbs"}});
import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'

export default class DateOfBirthComponent extends Component {
  get f() {
    return this.args.f
  }

  /**
   * @param {Date} selectedDate 
   */
  @action
  onChange(selectedDate) {
    const date = selectedDate.getDate()
    const month = selectedDate.getMonth() + 1 // getMonth is 0-indexed
    const year = selectedDate.getFullYear()

    this.f.object.set('birthDate', date)
    this.f.object.set('birthMonth', month)
    this.f.object.set('birthYear', year)
  }
}
