import Component from '@glimmer/component'
import { isEmpty } from '@ember/utils'

export default class UiHorizontalProgressBarComponent extends Component {
  get mappedSteps() {
    if (isEmpty(this.args.steps) || isEmpty(this.args.currentStepIndex)) {
      return []
    }

    return this.args.steps.map((step, index) => {
      const completed = this.args.currentStepIndex > index
      let leftColor = 'ui-gray-80'
      let rightColor = 'ui-gray-80'
      let incompleteCircleColor = 'ui-gray-80'

      if (completed) {
        leftColor = this.args.color
        rightColor = this.args.color
      }

      if (this.args.currentStepIndex === index) {
        incompleteCircleColor = this.args.color
        leftColor = this.args.color
      }

      return { leftColor, rightColor, incompleteCircleColor, completed, step }
    })
  }
}
