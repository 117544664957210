import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
class InviteExpiredRoute extends Route {
  @service
  intl;

  beforeModel() {
    const model = this.controllerFor('partner').get('model');
    if (model.get('isBigBox')) document.title = this.intl.t('invite_expired.home_depot.title');
  }
}

export default InviteExpiredRoute;
