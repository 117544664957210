import classic from 'ember-classic-decorator';
import { inject as controller } from '@ember/controller';
import { alias } from '@ember/object/computed';
import FinanceitComponentsSubventionController from 'financeit-components/controllers/subvention';

@classic
class SubventionController extends FinanceitComponentsSubventionController {
  @controller('services/paymentCalculator')
  calculator;

  @controller
  subvention;

  @controller('loan')
  loanController;

  @alias('loanController.model')
  loan;
}

export default SubventionController;
