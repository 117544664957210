import classic from 'ember-classic-decorator';
/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';

let PortalHubAbortedRoute;

export default PortalHubAbortedRoute = @classic
class AbortedRoute extends Route {
  model() {
    return this.controllerFor('loan').get('model');
  }
};
