import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { isBlank } from '@ember/utils';
import resetScroll from 'financeit-components/utils/reset-scroll';

@classic
export default class ConfirmationRoute extends Route {
  @service router

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel() {
    const { changeset } = this.modelFor('portal.profile.change-email');
    if (isBlank(changeset.get('email'))) {
      this.router.transitionTo('portal.profile.change-email');
    }
  }

  model() {
    return this.modelFor('portal.profile.change-email');
  }
}
