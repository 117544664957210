import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class UiDropdownItemButtonComponent extends Component {
  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(...arguments)
    }

    this.args.closeDropdown()
  }
}
