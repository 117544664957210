import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Profile::ProfileSection @sectionName={{@sectionName}} @saveSucceeded={{this.saveSucceeded}} @backButtonRoute={{@backButtonRoute}}>\n  <UiForm @object={{this.borrowerChangeset}} @onSubmit={{this.submit}} as |f|>\n    <div class=\"tw-flex-grow\">\n      {{yield f this.borrowerChangeset this.onEmploymentStatusChange}}\n      <div class=\"tw-my-6\">\n        <f.submit @text={{t \"profile.save\"}} @fullWidthMobile={{true}} />\n      </div>\n    </div>\n  </UiForm>\n</Profile::ProfileSection>\n", {"contents":"<Profile::ProfileSection @sectionName={{@sectionName}} @saveSucceeded={{this.saveSucceeded}} @backButtonRoute={{@backButtonRoute}}>\n  <UiForm @object={{this.borrowerChangeset}} @onSubmit={{this.submit}} as |f|>\n    <div class=\"tw-flex-grow\">\n      {{yield f this.borrowerChangeset this.onEmploymentStatusChange}}\n      <div class=\"tw-my-6\">\n        <f.submit @text={{t \"profile.save\"}} @fullWidthMobile={{true}} />\n      </div>\n    </div>\n  </UiForm>\n</Profile::ProfileSection>\n","moduleName":"direct/components/profile/edit-profile-section.hbs","parseOptions":{"srcName":"direct/components/profile/edit-profile-section.hbs"}});
import Component from '@glimmer/component';
import Changeset from 'ember-changeset';
import { underscore } from '@ember/string';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class EditProfileSectionComponent extends Component {
  @service router
  @service notification
  @service intl

  borrowerChangeset = new Changeset(this.args.borrower)

  /**
   When updating the contact section, we should include the home and mobile phone numbers in
   the borrower object. This allows the backend the make a correct choice on which phone type
   should be used for this borrower
   */
  get doSendPhoneNumbers() {
    if (this.args.sectionKey !== 'contact') {
      return false;
    }

    return !!this.borrowerChangeset.changes.length;
  }

  @action
  async submit() {
    this.processInputData();

    try {
      await this.args.borrower.updatePii({
        id: this.args.borrower.id,
        borrower: this.buildBorrowerObjectToSend(),
        login_key: this.args.loan?.loginKey,
        section: this.args.sectionKey
      });

      this.borrowerChangeset.execute();
      window.scrollTo(0, 0);
      if (this.args.backButtonRoute === 'portal.hub') {
        this.notification.setNotification('success', this.intl.t('hub.personal_details_updated'))
        this.router.transitionTo('portal.hub')
      } else {
        this.router.transitionTo('portal.profile', { queryParams: { saveSucceeded: true } });
      }

    } catch (e) {
      this.pushErrorMessages(e.errors);
    }
  }

  @action
  onEmploymentStatusChange() {
    const employmentProperties = [
      "employerName", "occupation", "positionTitle", "employerAddress", "employerCity", "employerProvince",
    ]
    if (this.args.loan?.isCreditTopUp){
      employmentProperties.push('employmentMonthlyIncome')
    }

    if (this.args.borrower.employmentStatus != this.borrowerChangeset.get('employmentStatus')) {
      employmentProperties.forEach((property) =>
        this.borrowerChangeset.set(property, '')
      )
    } else {
      employmentProperties.forEach((property) =>
        this.borrowerChangeset.set(property, this.args.borrower.get(property))
      )
    }
  }


  processInputData() {
    this.borrowerChangeset.get('changes').forEach((change) => {
      if (change.key === 'employmentStatus') {
        if (change.value === 'Other Income Source') {
          this.borrowerChangeset.setProperties({
            employerAddress: '',
            employerCity: '',
            employerProvince: '',
            positionTitle: ''
          });
        } else if (change.value === 'Unemployed') {
          this.borrowerChangeset.setProperties({
            employerName: '',
            occupation: '',
            positionTitle: '',
            employerAddress: '',
            employerCity: '',
            employerProvince: '',
          });
        }
      } else if (change.key === 'isMailingAddressDifferentThanResidential') {
        if (change.value === false) {
          this.borrowerChangeset.setProperties({
            mailingAddress: '',
            mailingCity: '',
            mailingProvince: '',
            mailingPostalCode: '',
            mailingUnitNumber: ''
          });
        }
      }
    });
  }

  buildBorrowerObjectToSend() {
    const borrowerObjectToSend = {}

    this.borrowerChangeset.get('changes').forEach((change) => {
      borrowerObjectToSend[underscore(change.key)] = change.value
    });

    if (this.doSendPhoneNumbers) {
      borrowerObjectToSend.home_phone_number = borrowerObjectToSend.home_phone_number ?? this.args.borrower.get('homePhoneNumber')
      borrowerObjectToSend.mobile_phone_number = borrowerObjectToSend.mobile_phone_number ?? this.args.borrower.get('mobilePhoneNumber')
    }

    return borrowerObjectToSend
  }

  pushErrorMessages(errors) {
    // eslint-disable-next-line no-unused-vars
    errors.forEach((error) => {
      this.borrowerChangeset.pushErrors(error.meta.key, error.meta.errors);
    })
  }
}
