import FormFieldComponent from './ui-form-field'
import { A } from '@ember/array'
import { action } from '@ember/object'
import { isPresent, typeOf } from '@ember/utils'
import { assert } from '@ember/debug'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'

@uiFormFieldValue
export default class UiSelectComponent extends FormFieldComponent {
  get powerSearchField() {
    if (this.args.optionsKey) {
      return this.args.optionsValue
    }

    return 'label'
  }

  get powerSelected() {
    return this.selectedValue
  }

  get powerSelectComponentName() {
    return this.args.multiSelect ? 'power-select-multiple' : 'power-select'
  }

  get optionsToDisplay() {
    // using this instead of each-in in the template for now because of https://github.com/emberjs/ember.js/issues/17529
    return this._mapOptions(this.args.options)
  }

  get optionGroupsToDisplay() {
    assert('UiSelect: Cannot display optgroups in powerselect mode', this.args.kind !== 'power')
    assert('UiSelect: Cannot display optgroups if @optgroups is not set to `true`', this.args.optgroups === true)
    assert('UiSelect: When @optgroups is set to true, @options must be an array', Array.isArray(this.args.options) || !isPresent(this.args.options))

    return this.args.options.map(group => {
      return {
        label: group.label,
        options: this._mapOptions(group.options)
      }
    })
  }

  get stringValue() {
    if (this.selectedValue) {
      return this.selectedValue.toString()
    }

    return null
  }

  get selectedValue() {
    return this.value
  }

  set selectedValue(value) {
    if (this.args.optionsKey) {
      const options = this.args.optgroups ? this.args.options.flatMap(group => group.options) : this.args.options
      const optionToSet = options.find(
        (option) => option[this.args.optionsKey].toString() === value
      )

      this.value = optionToSet
    } else {
      this.value = value
    }
  }

  @action
  onDidInsert() {
    const idKey = this.args.optionsKey || 'id'

    if (!this.args.prompt && !this.selectedValue?.[idKey] && !this.selectedValue) {
      const options = this.args.optgroups ?
        this._flattenOptgroups(this.args.options) :
        this.args.options

      if (options.length) {
        this.selectedValue = options.firstObject ? options.firstObject[idKey] : options[0][idKey]
      } else if (typeOf(options) === 'object' || typeOf(options) === 'instance') {
        this.selectedValue = Object.keys(options)[0]
      }
    }
  }

  @action
  onChange(event) {
    this.selectedValue = event.target.value

    this.args.onChange?.(event)
  }

  @action
  powerOnChange(item) {
    if (this.args.multiSelect) {
      A(this.selectedValue).clear().addObjects(item)
    } else {
      this.selectedValue = item[this.args.optionsKey]
    }

    this.args.powerOnChange?.()
  }

  _mapOptions(options) {
    assert(
      'UiSelect: If @options is an array of objects, then both @optionsKey and @optionsValue must be passed',
      !(Array.isArray(options) && options.some(option => typeOf(option) === 'object' || typeOf(option) === 'instance')) || (this.args.optionsKey && this.args.optionsValue)
    )

    if (this.args.optionsKey) {
      return options
    } else {
      const displayOptions = Object.keys(options).map((key) => (
        { label: options[key], key: key }
      ))

      return displayOptions
    }
  }

  _flattenOptgroups(optgroups) {
    if (!this.args.optionsKey) {
      return optgroups.reduce((acc, group) => ({ ...acc, ...group.options }), {})
    }

    return optgroups.flatMap(group => group.options)
  }
}
