import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { inject as service } from '@ember/service';

export default class SoftDeclineAddCoborrowerRoute extends Route {
  @service intl;
  @service store

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel() {
    document.title = this.intl.t('title.add_coborrower');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    this.controllerFor('borrower').set('model', this.store.createRecord('borrower', { borrowerType: "CoBorrower",
                                                                                      optionalHousingAndEmployerInfo: this.controllerFor('borrower').get('model.optionalHousingAndEmployerInfo'),
                                                                                      primaryBorrowerHousingMonthlyCosts: this.controllerFor('borrower').get('model.housingMonthlyCosts') }));
  }
}
