import IntlService from 'ember-intl/services/intl';

export default class _IntlService extends IntlService {
  get language() {
    switch (this.primaryLocale) {
      case 'fr-ca':
        return 'fr';
      default:
        return 'en';
    }
  }
}
