import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class SortingHeaderComponent extends Component {
  @service intl

  get ariaLabel() {
    if (!this.args.column.useSorting) return this.args.column.title

    switch (this.args.column.sorting) {
      case 'asc':
        return `${this.args.column.title} ${this.intl.t('ui_table.sort_asc')}`
      case 'desc':
        return `${this.args.column.title} ${this.intl.t('ui_table.sort_desc')}`
      case 'none':
      default:
        return `${this.args.column.title} ${this.intl.t('ui_table.sort_none')}`
    }
  }
}
