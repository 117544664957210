import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';

@classic
class PortalFundedRoute extends Route {
  @service
  intl;

  beforeModel(transition) {
    // eslint-disable-next-line ember/no-jquery
    $(document).attr('title', this.intl.t('title.funded'));
    if (checkAuthenticated(transition, this) && !['funded', 'msf_funded_but_not_finalized'].includes(this.controllerFor('loan').get('model.state'))) {
      return transitionToDefaultRoute(this);
    }
  }

  model() {
    return this.controllerFor('loan').get('model');
  }
}

export default PortalFundedRoute;
