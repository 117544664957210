import Component from '@glimmer/component'

export default class UiText extends Component {
  // Default properties
  get textSize() {
    return this.args.textSize ?? 'lg'
  }
  get textColor() {
    return this.args.textColor ?? 'ui-black-100'
  }
  get bold() {
    return this.args.bold ?? false
  }
  get italics() {
    return this.args.italics ?? false
  }
  get my() {
    return this.args.my ?? 0
  }

  defaultClasses = ['tw-font-body', 'tw-break-words', 'tw-leading-normal'].join(' ')

  get dynamicClasses() {
    const classes = [`tw-text-${this.textSize}`, `tw-text-${this.textColor}`]

    if (this.args.tagName === 'p') {
      classes.push(`tw-my-${this.my}`)
    }

    if (this.bold) {
      classes.push('tw-font-bold')
    }

    if (this.italics) {
      classes.push('tw-italic')
    }

    return classes.join(' ')
  }
}
