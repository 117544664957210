import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';


export default class MobileAppHelper extends Helper {

  @service
  mobile;

  get isMobileApp() {
    return this.mobile.isMobileApp;
  }

  compute() {
    return this.isMobileApp;
  }
}
