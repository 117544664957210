import FormFieldComponent from './ui-form-field'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'
import { action } from '@ember/object'
@uiFormFieldValue
export default class UiCheckboxStandaloneComponent extends FormFieldComponent {
  get titleTextSize() {
    return this.args.titleTextSize ?? 'lg'
  }

  @action
  onChange(event) {
    this.value = event.target.checked
    this.args.onChange?.(event)
  }
}
