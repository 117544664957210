import Service, { service } from '@ember/service'

export default class ScanIdService extends Service {
  @service mobile
  
  get isEnabled() {
    if (this.mobile.isIOSApp) {
      return this._isIosEnabled()
    } else if (this.mobile.isAndroidApp) {
      return true
    }

    return false
  }

  _isIosEnabled() {
    const iosVersion = this.mobile.iosVersion
    const [version, appId] = iosVersion.split(':')
    const [major, minor, patch] = version.split('.').map(Number)

    if (appId === 'home-depot') {
      return true
    }

    // A bug in v5.0.0 and v5.0.2 of the iOS app causes the app to crash when the scan ID feature is enabled
    if (major === 5 && minor === 0) {
      return patch >= 2
    }

    return true
  }
}
