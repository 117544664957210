import Component from '@glimmer/component'

export default class UiCollapsiblePanelComponent extends Component {
  get isExpanded() {
    return this.args.isExpanded ?? true
  }

  get _classes() {
    return this.args.kind === 'card' ? 'tw-p-3 tw-bg-ui-gray-40 tw-rounded' : ''
  }
}
