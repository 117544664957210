import { tracked } from '@glimmer/tracking'
import { A } from '@ember/array'

export default class UiFileQueue {
  listeners = new Set()
  @tracked uploadedFiles = A()
  @tracked fileQueue

  constructor(name, url, fileQueue) {
    this.name = name
    this.url = url
    this.fileQueue = fileQueue
  }

  get files() {
    const fileQueueFiles = this.fileQueue?.files ?? []
    return [
      ...this.uploadedFiles,
      ...fileQueueFiles,
    ]
  }

  get isUploading() {
    return this.files.filter(file => file.state === 'uploading').length > 0
  }

  removeAllFiles() {
    this.uploadedFiles.clear()
    this.fileQueue.files.forEach(f => this.fileQueue.remove(f))
  }

  removeFile(file, headers) {
    if (this.uploadedFiles.includes(file)) {
      const i = this.uploadedFiles.findIndex(file)
      this.uploadedFiles.removeAt(i)
    }

    this.fileQueue?.remove(file, { headers })
  }

  uploadFile(file, headers) {
    if (!this.files.find(f => f === file)) {
      return Promise.reject('File not found in queue')
    }
    return file.upload(this.url, { headers })
      .then(results => this._onUploadSuccess([file], [results]))
      .catch(error => this._onUploadFail(error))
  }

  uploadAll(headers) {
    const filesToUpload = this.files.filter(file => file.state === 'queued')
    const uploadPromises = filesToUpload.map(file => file.upload(this.url, { headers }))

    if (!filesToUpload.length) {
      return Promise.resolve()
    }

    return Promise.all(uploadPromises)
      .then(results => this._onUploadSuccess(filesToUpload, results))
      .catch(error => this._onUploadFail(error))
  }

  addListener(listener) {
    this.listeners.add(listener)
  }

  removeListener(listener) {
    this.listeners.delete(listener)
  }

  _onUploadSuccess(files, results) {
    this.uploadedFiles.pushObjects(files)
    this.listeners.forEach(listener => listener.onUploadSuccess?.(files, results))
  }

  _onUploadFail(error) {
    this.listeners.forEach(listener => listener.onUploadFail?.(error))
  }
}
