import Component from '@glimmer/component'

export default class UiAlertComponent extends Component {
  get kind() {
    return this.args.kind ?? 'default'
  }

  get shadow() {
    if (this.args.flash) {
      return 'shadow-lg'
    } else {
      return 'shadow-none'
    }
  }

  get themeClass() {
    let themeClasses = {
     success: 'tag-theme-alert-success',
     warning: 'tag-theme-alert-warning',
     error: 'tag-theme-alert-error',
     note: 'tag-theme-alert-note',
     default: 'tag-theme-alert-default'
    }
    return themeClasses[this.kind]
  }

  get bgColor() {
    let colorOptions = {
     success: 'accent-40',
     warning: 'warning-40',
     error: 'error-40',
     note: 'ui-gray-40',
     default: 'interactive-40'
    }
    return colorOptions[this.kind]
  }

  get borderColor() {
    let borderColorOptions = {
      success: 'accent-100',
      warning: 'warning-dark',
      error: 'error-100',
      note: 'ui-black-40',
      default: 'interactive-100'
    }
    return borderColorOptions[this.kind]
  }

  get icon() {
    if (this.args.iconName) return this.args.iconName

    let iconNames = {
      success: 'check-circle',
      warning: 'alert',
      error: 'warning',
      default: 'info'
    }
    return iconNames[this.kind]
  }

  get iconPositionClass() {
    const positionClasses = {
      'top': 'tw-items-start',
      'center': 'tw-items-center',
      'bottom': 'tw-items-end'
    }

    return positionClasses[this.args.iconPosition] ?? 'tw-items-center'
  }
}
