import classic from 'ember-classic-decorator';
import PortalHubBankingController from '../banking';

@classic
class PortalHubBankingLogInController extends PortalHubBankingController {
  queryParams = [{borrowerType: 'borrowerType'}];
  borrowerType = "Borrower";
}

export default PortalHubBankingLogInController;
