import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class AuthCodeSendComponent extends Component {
  get f() {
    return this.args.f
  }

  @action
  triggerAuthCode(type) {
    this.args.triggerAuthCode(type)
  }
}
