import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { checkAuthenticated } from '../../utils/redirect-session';

@classic
class RedirectSignRestructureQuebecLoanAgreementRoute extends Route {
  beforeModel(transition) {
    return checkAuthenticated(transition, this);
  }

  redirect() {
    // We need this route since due to ember and rails mixing for financeit direct
    // its almost impossible to setup a rails redirect without breaking ember redirect and routes
    return window.location.href = this.controllerFor('session').get('loggedInUser.restructureQuebecSigningUrl').replace(':login_key', this.controllerFor('loan').get('model.loginKey'));
  }
}

export default RedirectSignRestructureQuebecLoanAgreementRoute;
