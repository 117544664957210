import Component from '@glimmer/component'

export default class UiStack extends Component {
  get gap() {
    return this.args.gap ?? 'md'
  }

  get spacing() {
    let stackSpacing = {
      none: 'tw-space-y-0',
      sm: 'tw-space-y-2',
      md: 'tw-space-y-4',
      lg: 'tw-space-y-6'
    }
    return stackSpacing[this.gap]
  }

  get divider() {
    return this.args.divider ?? false
  }

  get dividerColor() {
    return this.args.dividerColor ?? 'ui-gray-40'
  }

  get dynamicClasses() {
    const classes = []
    if (this.divider) {
      classes.push(`tw-divide-y tw-divide-${this.dividerColor}`)
    } else {
      classes.push(`${this.spacing}`)
    }
    return classes.join(' ')
  }
}
