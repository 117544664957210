import FormFieldComponent from './ui-form-field'
import { A } from '@ember/array'
import { action, set } from '@ember/object'
import { tracked } from '@glimmer/tracking'

class Checkbox {
  @tracked selection
  @tracked value
  @tracked object
  @tracked name
  @tracked option
  @tracked label

  constructor({ selection, value, object, name, option, label }) {
    this.selection = selection
    this.value = value
    this.object = object
    this.name = name
    this.option = option
    this.label = label
  }

  get isSelected() {
    return this.selection.includes(this.value)
  }

  set isSelected(checked) {
    const selection = this.selection
    const selected = selection.includes(this.value)

    if (checked && !selected) {
      selection.addObject(this.value)
    } else if (!checked && selected) {
      selection.removeObject(this.value)
    }

    if (this.object) {
      set(this.object, this.name, this.selection)
    }
  }
}

export default class UiCheckboxComponent extends FormFieldComponent {
  get selection() {
    return this.args.selection ?? []
  }

  get checkboxes() {
    const labelProperty = this.args.labelProperty
    const valueProperty = this.args.valueProperty
    const selection = A(this.selection)
    const options = A(this.args.options)
    const object = this.args.object
    const name = this.args.name

    const checkboxes = options.map((option) => {
      let label, value

      if (labelProperty) {
        if (typeof option.get === 'function') {
          label = option.get(labelProperty)
        } else {
          label = option[labelProperty]
        }
      } else {
        label = String(option)
      }

      if (valueProperty) {
        if (typeof option.get === 'function') {
          value = option.get(valueProperty)
        } else {
          value = option[valueProperty]
        }
      } else {
        value = option
      }

      return new Checkbox({
        option,
        label,
        value,
        selection,
        object,
        name,
      })
    })

    return A(checkboxes)
  }

  @action
  onChange(checkbox, event) {
    checkbox.isSelected = event.target.checked
    this.args.onChange?.(event, this.checkboxes)
  }
}
