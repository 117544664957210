import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { later } from '@ember/runloop';
import PortalHubLoanStepsController from '../../hub';

@classic
class SecureYourAccountController extends PortalHubLoanStepsController {
  @service
  router;

  queryParams = ['borrowerType'];
  borrowerType = "Borrower";
  showOnfidoResults = false;
  onfidoCheck = null;

  @controller("portal.hub")
  hubController;

  @action
  showSecureAccountOptions() {
    return this.set('showOnfidoResults', false);
  }

  @action
  createOnfidoCheck(callbackData) {
    const borrower = this.get('model.borrower');
    borrower.createOnfidoCheck(callbackData).then((response) => {
      if (isPresent(response)) {
        this.set('onfidoCheck', response.onfido_check);
        this.set('showOnfidoResults', true);
        this.send('inProgressOnfidoCheckDirectState', this.get("model.loanStep.name"));
      } else {
        this.send("markCheckCompleted");
        this.transitionToRoute("portal.hub");
      }
    }).catch(() => {
      this.transitionToRoute("portal.hub.secure-your-account.error");
    });
  }

  @action
  markCheckCompleted() {
    return this.send('completeOnfidoCheckDirectState', this.get("model.loanStep.name"));
  }

  @action
  markCheckInProgress()  {
    return this.send('inProgressOnfidoCheckDirectState', this.get("model.loanStep.name"));
  }

  @action
  markCheckAlerted() {
    return this.send('alertOnfidoCheckDirectState', this.get("model.loanStep.name"));
  }

  @action
  setLoading(isLoading) {
    this.hubController.set("loading", isLoading);
  }

  @action
  fetchOnfidoCheck() {
    return later(this, () => {
      this.model.borrower.getOnfidoChecks().then((response) => {
        this.set('onfidoCheck', response.onfido_check);
        if (response.onfido_check.status === 'complete') {
          if (response.onfido_check.loan_state === 'declined') {
            this.loan.set('state', response.onfido_check.loan_state);
            this.router.transitionTo('portal.hard-decline');
          } else if (response.onfido_check.result === 'clear') {
            this.send('markCheckCompleted');
            this.set('loan.isSecureYourAccountStepCompleted', response.onfido_check.is_onfido_check_status_complete)
            this.set('loan.financeitStepContent', response.onfido_check.financeit_step_content)
            this.router.transitionTo('portal.hub');
          } else if (response.onfido_check.onfido_considered_declined && !response.onfido_check.show_banking_after_limit_exceeded) {
            this.send('markCheckInProgress');
          } else {
            this.send('markCheckAlerted');
            if (!response.onfido_check.show_banking_after_limit_exceeded) {
              this.set('loan.isSecureYourAccountStepMandatory', true)
            }
          }
        } else if (response.onfido_check.status === 'in_progress') {
          this.send('fetchOnfidoCheck');
        }
      });
    }, 5000);
  }
}

export default SecureYourAccountController;
