import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { schedule } from '@ember/runloop';
import Route from '@ember/routing/route';
import { onActivate, onDeactivate } from '../../../../utils/step-transition';
import { transitionToDefaultRoute } from '../../../../utils/redirect-session';
import RSVP from 'rsvp';

@classic
class SecureYourAccountRoute extends Route {
  @service
  intl;

  @service store
  @service router

  activate() {
    super.activate(...arguments)
    onActivate()
  }

  deactivate() {
    super.deactivate(...arguments)
    onDeactivate()
  }

  beforeModel() {
    if (this.controllerFor('loan').get('model.state') === 'declined') {
      return transitionToDefaultRoute(this);
    } else {
      return this.controllerFor('portal.hub').set('loading', true);
    }
  }

  model(params) {
    const loanId = this.controllerFor('loan').get('model.id');
    const borrower = this.store.peekAll('borrower').filterBy('borrowerType', params.borrowerType).lastObject;
    const loanStepName = this.getLoanStepName(params.borrowerType);

    return RSVP.hash({
      partner: this.controllerFor('partner').get('model'),
      borrower,
      loanId,
      // eslint-disable-next-line ember/no-jquery
      loanStep: $.ajax({
        type: 'GET',
        url: `/${this.intl.language}/api/v3/direct/loan_steps/${loanStepName}`,
        data: { login_key: this.controllerFor("loan").get("model.loginKey") },
      }).then((data) => {
        this.store.pushPayload("loanStep", { loanStep: data.step });
        return this.store.peekAll("loan-step").findBy("name", loanStepName);
      })
    });
  }

  getLoanStepName(borrowerType) {
    const name = this.controllerFor('loan').get('model.stepToUseForSecureYourAccount');
    if (borrowerType === "Borrower") {
      return name;
    } else {
      return name + "_coborrower"
    }
  }

  afterModel(model) {
    if (model.loanStep.directState === "completed") {
      this.controllerFor('portal.hub').set('loading', false);
      this.router.transitionTo('portal.hub');
    } else {
      return model.borrower.getOnfidoChecks().then((response) => {
        this.controllerFor('portal.hub').set('loading', false);

        if (response.onfido_check != null) {
          this.controllerFor('portal.hub.secure-your-account.index').set('showOnfidoResults', true);
          this.controllerFor('portal.hub.secure-your-account.index').set('onfidoCheck', response.onfido_check);
          if ((response.onfido_check.status === 'complete') && (response.onfido_check.result === 'clear')) {
            return this.router.transitionTo('portal.hub');
          }
        }
      });
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (this.controllerFor('partner').get('model.isBigBox')) {
      return schedule('afterRender', this, () => {
        if (window.hj) { return window.hj('trigger', 'checklist_step_poll'); }
      });
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      return controller.set("showOnfidoResults", false);
    }
  }
}

export default SecureYourAccountRoute;
