import FormFieldComponent from './ui-form-field'
import { action, set } from '@ember/object'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'

@uiFormFieldValue
export default class UiAddressAutocompleteComponent extends FormFieldComponent {

  get updateCoordinates() {
    return this.args.updateCoordinates ?? false
  }

  /**
   * The results can be restricted to a certain type. The list of types can be found at:
   * https://developers.google.com/places/web-service/autocomplete#place_types
   * If you want to return all types, pass in `placeType=null`
   */
  get placeType() {
    if (this.args.placeType === null) {
      return null
    }

    return this.args.placeType ?? 'address'
  }

  parseAddressComponents(addressComponents) {
    const result = {}

    addressComponents.forEach(component => {
      component.types.forEach(type => {
        result[type] = component.long_name
        result[type + '_short'] = component.short_name
      })
    })

    return result
  }

  buildStreetAddress(streetNumber, route) {
    return [streetNumber, route].filter(Boolean).join(' ')
  }

  city(parsed) {
    /*
     * "Brooklyn and other parts of New York City do not include the city as part of the address. Instead, they use sublocality_level_1.
     * "In the UK and in Sweden, the component to display the city is postal_town."
     * https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/examples/places-autocomplete-addressform
     */
    return parsed.locality || parsed.sublocality_level_1 || parsed.postal_town
  }

  updateProperties(place) {
    const { address_components, geometry } = place
    const parsed = this.parseAddressComponents(address_components)
    const streetAddress = this.buildStreetAddress(parsed.street_number, parsed.route)

    const propertiesToUpdate = {
      ['object.streetAddress']: streetAddress,
      ['object.city']: this.city(parsed),
      ['object.province']: parsed.administrative_area_level_1_short,
      ['object.postalCode']: parsed.postal_code,
      ['object.country']: parsed.country,
      ['object.countryCode']: parsed.country_short // two letter country code
    }

    if (this.updateCoordinates) {
      propertiesToUpdate['object.lng'] = geometry.location.lng()
      propertiesToUpdate['object.lat'] = geometry.location.lat()
    }

    Object.entries(propertiesToUpdate).forEach(([key, value]) => {
      set(this.args, key, value)
    })

    const streetAddressField = this.name === 'streetAddress'

    if (!streetAddressField) {
      this.value = document.getElementById(this.id).value
    }

    if (streetAddressField && this.value === streetAddress) {
      document.getElementById(this.id).value = streetAddress
    }
  }

  @action
  onPlaceChanged(place) {
    if (!(place && place.address_components)) {
      return
    }

    if (typeof this.args.onPlaceChanged === 'function') {
      const { address_components, geometry } = place
      const parsed = this.parseAddressComponents(address_components)
      const streetAddress = this.buildStreetAddress(parsed.street_number, parsed.route)

      return this.args.onPlaceChanged({
        geometry,
        addressComponents: {
          ...parsed,
          street_address: streetAddress
        }
      })
    }

    this.updateProperties(place)

    this.args.afterSelected?.()
  }
}
