// this file is necessary for UiTableArray and UiTableServerPaginated components to strip out
// inline bootstrap classes (see https://github.com/onechiporenko/ember-models-table)

export function initialize(appInstance) {
  appInstance.inject('component:models-table', 'themeInstance', 'theme:plain-html')
  appInstance.inject('component:models-table-server-paginated', 'themeInstance', 'theme:plain-html')
}

export default {
  name: 'emt-inject',
  initialize
}
