import Component from '@glimmer/component'
import { action } from '@ember/object'
import { showModal } from 'tag/utils/ui-modal'

export default class LoanDetailsComponent extends Component {
  paymentProtectionModalId = 'payment-protection-modal'

  get isAnyBadgePromotion() {
    return (this.args.subvention.isBadgePromotion || this.args.subvention.specialPromo) && !(this.args.loan.isJade || this.args.loan.isRestructured)
  }

  @action
  showModal(modalId) {
    showModal(modalId)
  }
}
