import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import PortalController from '../portal';

@classic
export default class AbortedController extends PortalController {
  @service
  intl;

  @computed('loan.isShoppingPass')
  get creditType() {
    let key;
    if (this.get('loan.isShoppingPass')) {
      key = 'hd';
    } else {
      key = 'classic';
    }

    return this.intl.t(`closed.${key}`);
  }
}
