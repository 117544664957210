import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert this.onDidInsert}} class=\"progress-bar-outer tw-w-64 md:tw-w-80 tw-rounded tw-bg-interactive-60\">\n  <div class=\"progress-bar-inner tw-h-2 tw-rounded tw-bg-interactive-100\"></div>\n</div>\n", {"contents":"<div {{did-insert this.onDidInsert}} class=\"progress-bar-outer tw-w-64 md:tw-w-80 tw-rounded tw-bg-interactive-60\">\n  <div class=\"progress-bar-inner tw-h-2 tw-rounded tw-bg-interactive-100\"></div>\n</div>\n","moduleName":"direct/components/progress-bar.hbs","parseOptions":{"srcName":"direct/components/progress-bar.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking'
import { cancel, later } from '@ember/runloop';
import { action } from '@ember/object';
import { isDestroyed, isDestroying } from '@ember/destroyable';

export default class ProgressBarComponent extends Component {
  @tracked initialProgress = 14
  @tracked progress = 14
  @tracked upperBoundWhileWaiting = 86
  @tracked progressPerIntervalComplete = 1
  @tracked maxProgress = 101
  @tracked element;
  @tracked updateTimer;

  get interval() {
    return this.args.interval ?? 10
  }

  get progressPerInterval() {
    return this.args.progressPerInterval ?? 0.028
  }

  @action
  onDidInsert(element) {
    this.element = element
    this.updateProgress();
  }

  willDestroy() {
    cancel(this.updateTimer);
    this.progress = this.initialProgress;

    super.willDestroy(...arguments);
  }

  updateProgress() {
    if (isDestroying(this) || isDestroyed(this)) {
      return
    }

    if (this.progress < this.upperBound) {
      let innerProgressBar = this.element.querySelector(".progress-bar-inner");
      innerProgressBar.style.width = `${this.progress}%`;

      this.progress += this.progressIncrement;
    }

    const updateTimer = later(this, this.updateProgress, this.interval);
    this.updateTimer = updateTimer;
  }

  get upperBound() {
    if (this.shouldComplete === true) {
      return this.maxProgress;
    } else {
      return this.upperBoundWhileWaiting;
    }
  }

  get progressIncrement() {
    if (this.shouldComplete === true) {
      return this.progressPerIntervalComplete;
    } else {
      return this.progressPerInterval;
    }
  }
}
