import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MobileQuote extends Component {
  @action
  showLearnMoreModal() {
    this.args.showLearnMoreModal();
  }

  @action
  showMobileQuoteModal() {
    this.args.showMobileQuoteModal();
  }
}
