import Component from '@glimmer/component'

export default class UiBadge extends Component {
  get kind() {
    return this.args.kind || 'default'
  }

  get size() {
    return this.args.size || 'small'
  }

  get textSize() {
    if (this.size === 'small') {
      return 'xs'
    }

    return 'sm'
  }

  get pX() {
    if (this.size === 'small') {
      return '2'
    }

    return '4'
  }

  get pY() {
    return '1'
  }

  get bgColor() {
    const colorOptions = {
      success: 'accent-40',
      warning: 'warning-40',
      error: 'error-40',
      disabled: 'ui-gray-60',
      neutral: 'ui-gray-80',
      default: 'interactive-40',
    }
    return colorOptions[this.kind]
  }

  get textColor() {
    const borderColorOptions = {
      success: 'accent-dark',
      warning: 'warning-dark',
      error: 'error-dark',
      disabled: 'ui-black-80',
      neutral: 'ui-black-80',
      default: 'interactive-dark',
    }
    return borderColorOptions[this.kind]
  }
}
