import UiButtonBase from './ui-button-base'
import { action } from '@ember/object'

export default class UiButtonComponent extends UiButtonBase {
  get type() {
    return this.args.type ?? 'button'
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(...arguments)
    }
  }
}
