import Component from '@glimmer/component'
import { action } from '@ember/object'
import { showModal } from 'tag/utils/ui-modal'

export default class ShoppingPassDetailsComponent extends Component {
  get calculatorRoute() {
    return this.args.loan.state == 'credit_issued' ? 'portal.shopping-pass-dashboard.calculator' : 'portal.offers.offer'
  }

  get hasPurchaseBalance() {
    return this.args.loan.state == 'credit_issued' && this.args.loan.principalAmount > 0
  }

  @action
  openShoppingPassLearnMoreModal() {
    showModal('shopping_pass_learn_more')
  }
}
