import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { resetModalToTargetSection } from 'financeit-components/utils/learn-more-show-modal-helper'
import * as UiModalUtils from 'tag/utils/ui-modal'

export default class AboutFinanceitComponent extends Component {
  @service intl

  get marketingSiteLocale() {
    const locale = this.intl.primaryLocale
    return locale === 'fr-ca' ? 'fr' : locale
  }

  @action
  showLearnMoreModal(sectionId) {
    resetModalToTargetSection(sectionId)
    return UiModalUtils.showModal('learn-more-modal');
  }
}
