/* eslint-disable ember/no-jquery */
import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import PortalHubBankingController from '../banking';

@classic
class PortalHubBankingProcessingController extends PortalHubBankingController {
  @service
  intl;

  @service store

  @alias('borrowerController.model')
  borrower;

  queryParams = ['source', { idvStepId: 'idvStepId' }];
  source = 'verify_id';
  idvStepId = null;

  @computed('loan.flinksSummaries')
  get processingLabels() {
    return {
      description: this.intl.t('hub.banking_processing.payment_plan'),
      submit: this.intl.t('hub.banking_processing.to_set_up_payment')
    };
  }

  @action
  onSubmit() {
    this.send('updateIdWasVerifiedStatus');
    return this.transitionToRoute('portal.hub.banking.choose-account', { queryParams: { sourceFlow: this.source } });
  }

  @action
  cancel() {
    $('#processingLoading .ts-loader div').html(this.intl.t('hub.agreement.loading'));
    $('#processingLoading .ui.dimmer').addClass('active');
    const responseId = this.get('loan.currentFlinksResponse.id');
    return $.ajax({
      type: 'DELETE',
      url: `/${this.intl.language}/api/v3/direct/flinks/responses/${responseId}`
    }).then((data) => {
      this.store.pushPayload('loan', { loan: data.loan });
      this.store.pushPayload('loanStep', { loanStep: data.step });
      this.transitionToRoute('portal.hub.banking.confirmation', { queryParams: { sourceFlow: this.source } });
      $('#processingLoading .ui.dimmer').removeClass('active');
      return this.send('updateIdWasVerifiedStatus');
    });
  }
}

export default PortalHubBankingProcessingController;
