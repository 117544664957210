import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import Changeset from 'ember-changeset';

@classic
class PortalPaymentOptionsRoute extends Route {
  model() {
    const loan = this.controllerFor('loan').get('model');
    const changeset = new Changeset(loan);

    let formatFrequency;
    if (loan.get('paymentFrequency') === 'm'){
      formatFrequency = "monthly";
    } else if (loan.get('paymentFrequency') === 'w'){
      formatFrequency = "weekly";
    } else {
      formatFrequency = 'biweekly';
    }
    changeset.set('changeFrequency', formatFrequency);
    return { changeset, loan };
  }
}

export default PortalPaymentOptionsRoute;
