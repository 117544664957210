import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class RowSelectAllCheckboxComponent extends Component {
  get selectedItemsIds() {
    return this.args.selectedItems.map(item => item.id)
  }

  get dataIds() {
    return this.args.data.map(item => item.id)
  }

  get intersectionIds() {
    return this.dataIds.filter(value => this.selectedItemsIds.includes(value))
  }

  get differenceIds() {
    return this.dataIds.filter(value => !this.selectedItemsIds.includes(value))
  }

  get allSelected() {
    return this.intersectionIds.length === this.dataIds.length
  }

  @action
  toggleRowSelection(event) {
    event.preventDefault()

    const { data } = this.args

    if (!this.allSelected) {
      data
        .filter(item => this.differenceIds.includes(item.id))
        // This forces ember-models-table to trigger the onDisplayDataChanged action. Hacky, but works for now...
        .forEach(item => this.args.clickOnRow(-1, item))
    } else {
      const toDeselect = data.filter(item => this.intersectionIds.includes(item.id))

      // Super horrible hack introduced to deal with ember-models-table's new TrackedArray internal implementation
      toDeselect.forEach((item) => this.args.clickOnRow(-1, item))
    }
  }

  @action
  noop() {
    console.log('noop')
  }
}
