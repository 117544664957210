import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class PostalCodeComponent extends Component {
  @service intl

  get label() {
    const translationKey = `address.postal_code.${this.args.countryCode || ''}`
    return this.intl.t(translationKey, {
      default: 'address.postal_code.international',
    })
  }
}
