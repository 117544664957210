import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { later } from '@ember/runloop';
import PortalHubBankingController from '../banking';
import secureFetch from '../../../../utils/secure-fetch';

@classic
class PortalHubBankingChooseAccountController extends PortalHubBankingController {
  @service
  intl;

  @service
  snowplow;

  @service store

  @alias('borrowerController.model')
  borrower;

  queryParams = ['sourceFlow'];
  sourceFlow = 'verify_id';
  showSpinner = false;
  showProgressBar = true;
  shouldCompleteProgressBar = false;
  poiStatus = 'keep_polling';
  poiCheckCounter = 0;
  poiStatusMaxNumOfChecks = 44;
  poiStatusCheckInterval = 1000;
  showVerifyingAccountText = false;
  sixMonthsDepositsFailure = false;
  showCancelText = false;

  get language() {
    return this.intl.language
  }

  @computed('loan.flinksSummaries.firstObject')
  get accountName() {
    const account = this.get('loan.flinksSummaries.firstObject');
    return account.name;
  }

  @computed('accountName')
  get afterNameMatch() {
    return isPresent(this.accountName);
  }

  @computed('loan.flinksSummaries')
  get multipleFlinksSummaries() {
    return this.get('loan.flinksSummaries').length > 1
  }

  @computed('loan.isShoppingPass', 'sourceFlow')
  get processingLabels() {
    // In the processing screen, the UI changes whether we have one account returned or many accounts returned.

    let descriptionKey = 'description'
    if(this.get('loan.isShoppingPass')){
      let suffix = this.sourceFlow === 'poi' ? '_poi' : '_hd'
      descriptionKey = `${descriptionKey}${suffix}`
    }

    return {
      description: this.intl.t(`hub.banking_processing.${descriptionKey}`),
      interest_payments: this.intl.t('hub.banking_processing.interest_payments_hd'),
      principal_only_payments: this.intl.t('hub.banking_processing.principal_only_payments_hd'),
      monthly_installments: this.intl.t('hub.banking_processing.monthly_installments_hd'),
      income_deposited: this.intl.t('hub.banking_processing.income_deposited'),
      submit: this.intl.t('hub.banking_processing.confirm_account'),
      isShoppingPass: this.get('loan.isShoppingPass')
    };
  }

  @computed('sourceFlow')
  get stepTitle() {
    switch(this.sourceFlow) {
      case 'poi':
        return this.intl.t('hub.submit_proof_of_income.title');
      default:
        return this.intl.t('hub.set_up_automated_payments.title');
    }
  }

  async getProofOfIncomeStatusData(loanId) {
    return await secureFetch(`/${this.language}/api/v3/direct/proof_of_income_status_checks/${loanId}?flinks_account_id=${this.get('borrower.chosenBankAccountId')}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    ).then(res => res.json()).catch(() => {return {status: 'keep_polling'}})
  }

  async postBankAccountData() {
    const purpose = this.sourceFlow === 'poi' ? 'poi' : 'set_up_payments'
    return await secureFetch(`/${this.language}/api/v3/direct/bank_accounts`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        source: this.sourceFlow,
        purpose: purpose,
        account_id: this.get('borrower.chosenBankAccountId'),
        loan_id: this.get('loan.id'),
        borrower_id: this.get('session.loggedInUser.id')
      })
    }).then(res => res.json()).catch(() => {
      return {
        loan: null,
        step: null,
        error: true
      }
    });
  }

  async sendCancelRequest(responseId) {
    return await secureFetch(`/${this.language}/api/v3/direct/flinks/responses/${responseId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json()).catch(() => {
        return {
          loan: null,
          step: null
        }
      });
  }

  async updatePoiStatus(bankAccountData) {
    const data = await this.getProofOfIncomeStatusData(this.get('loan.id'));
    this.set('poiStatus', data.status)
    this.set('poiCheckCounter', this.poiCheckCounter+1)
    if (this.poiStatus === 'not_eligible_for_auto_clear') {
      this.continueSubmit(bankAccountData)
    } else if (this.poiStatus === 'keep_polling' && this.poiCheckCounter < this.poiStatusMaxNumOfChecks) {
      later(() => {
        this.updatePoiStatus(bankAccountData)
      },this.poiStatusCheckInterval)
    } else if (this.poiStatus === 'completed') {
      const loanRecord = this.store.peekRecord('loan', data.loan_id);
      loanRecord.set('loanSteps', []);
      data.loan_steps.forEach(step => {
        this.store.push(this.store.normalize('loanStep', step));
        loanRecord.get('loanSteps').addObject(this.store.peekRecord('loanStep', step.id));
      });
      this.continueSubmit(bankAccountData)
    } else {
      if(data.max_flinks_poi_attempts_reached){
        this.set('borrower.atMaxFlinksPoiAttempts', data.max_flinks_poi_attempts_reached)
      }
      this.set('sixMonthsDepositsFailure', data.six_months_deposits_failure)
      this.continueSubmit(bankAccountData)
    }
  }

  continueSubmit(data) {
    this.store.pushPayload('loan', { loan: data.loan });
    this.store.pushPayload('loanStep', { loanStep: data.step });
    this.send('updateIdWasVerifiedStatus');
    if (isPresent(data.error)) {
      this.transitionToRoute('portal.hub.banking.error', { queryParams: { sourceFlow: this.sourceFlow } });
    } else if(this.sourceFlow === 'poi' && this.poiStatus !== 'completed'){
      this.transitionToRoute('portal.hub.banking.error', { queryParams: { sourceFlow: 'poi', poiFailure: true, sixMonthsDepositsFailure: this.sixMonthsDepositsFailure } });
    } else if (this.sourceFlow === 'poi' && this.paymentsAreNotSetup){
      this.transitionToRoute('portal.hub.banking.also-set-up-payments')
    }else{
      this.transitionToRoute('portal.hub.banking.confirmation', { queryParams: { sourceFlow: this.sourceFlow  } });
    }

    this.set('shouldCompleteProgressBar', true);
    this.set('showSpinner', false);
    this.set('showVerifyingAccountText', false)
  }

  logSubmit() {
    this.snowplow.snowplow('trackStructEvent', {
      category: 'flinks choose bank account',
      action: 'confirm account',
      property: 'true'
    });
  }

  logPageUnload() {
    this.snowplow.snowplow('trackStructEvent', {
      category: 'flinks loading screen when bank account is selected',
      action: 'page is refreshed, closed, or back button is clicked',
      property: 'true'
    });
  }

  setUpBeforeUnload() {
    window.addEventListener("beforeunload", () => {
      if (this.showSpinner) {
        this.logPageUnload();
      }
    })
  }

  @action
  async onSubmit() {
    this.set('showVerifyingAccountText', true);
    this.set('showSpinner', true);
    this.set('showProgressBar', true);
    this.logSubmit();
    const bankAccountData = await this.postBankAccountData()
    await this.updatePoiStatus(bankAccountData)
  }

  @action
  async cancel() {
    this.set('showCancelText', true)
    this.set('showProgressBar',false)
    this.set('showSpinner', true);
    const responseId = this.get('loan.currentFlinksResponse.id');
    const data = await this.sendCancelRequest(responseId)
    this.store.pushPayload('loan', { loan: data.loan });
    this.store.pushPayload('loanStep', { loanStep: data.step });
    this.send('updateIdWasVerifiedStatus');
    this.transitionToRoute('portal.hub.banking.confirmation', { queryParams: { sourceFlow: this.sourceFlow } });
    this.set('showSpinner', false);
    this.set('showCancelText', false)
  }
}

export default PortalHubBankingChooseAccountController;
