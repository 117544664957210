import Component from '@glimmer/component'
import { action } from '@ember/object'
import { showModal } from 'tag/utils/ui-modal'

export default class LearnMoreLinkComponent extends Component {
  @action
  onClick() {
    showModal(this.args.learnMoreModalId)
  }
}
