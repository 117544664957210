import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'

export default class ViewComponent extends Component {
  @service intl

  @action
  editSignature() {
    this.args.onValueChanged?.(null)
    this.args.editSignature?.()
  }
}
