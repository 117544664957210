import Component from '@glimmer/component'
import { inject as service } from '@ember/service';

export default class LoanDetailsHeaderComponent extends Component {
  @service intl;

  get greetingMessage () {
    const { borrower, coborrower, approvedCoborrower } = this.args.loan;

    if (approvedCoborrower) {
      return this.intl.t('hub.greeting_with_coborrower', {
        borrower: borrower.get('firstName'),
        coborrower: coborrower.get('firstName')
      });
    }

    return this.intl.t('hub.greeting', { borrower: borrower.get('firstName') });
  }

  get headerClassString () {
    if (this.args.loan.isCreditTopUp && !this.args.loan.isDisbursedOrClosed && !this.args.submitLoanAgreementStep.hasBorrowerCompleted) {
      return 'expanded';
    }
    return 'collapse';
  }

  get showLoanDetailsDropdown () {
    if (!this.args.loan.isCreditTopUp || this.args.loan.isDisbursedOrClosed || this.args.submitLoanAgreementStep.hasBorrowerCompleted) {
      return true;
    }
    return false;
  }
}
