import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import { next } from '@ember/runloop'
import { isPresent } from '@ember/utils'
import Controller, { inject as controller } from '@ember/controller'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { tracked } from '@glimmer/tracking'
import secureFetch from 'direct/utils/secure-fetch'

class AccountLoginController extends Controller {
  @controller
  session

  @controller('loan')
  loanController

  @service
  intl

  @service router
  @service store
  /** @type {import('financeit-components/app/services/mobile').default} */
  @service mobile
  /** @type {import('direct/app/services/bioauth').default} */
  @service bioauth

  @tracked authCodeError = null
  @tracked message = null
  @tracked messageKind = null
  @tracked showError = false
  @tracked showAuthCodeSpinner = false
  @tracked disableBioauth = false
  @tracked bioauthButtonDisabled = false

  shoppingPassLoan = false
  previousTransition = null
  queryParams = [
    'redirect',
    'for',
    'email',
    'updateSuccessful',
    'showMergeMessage',
    'borrowerEmail',
  ]
  showMergeMessage = false
  borrowerEmail = null
  redirect = null
  for = null
  email = null
  updateSuccessful = null

  _bioauthSuccessHandler = null

  get loan() {
    return this.loanController.model
  }

  get showBioauthLogin() {
    return this.bioauth.isBioauthEnrolled && !this.disableBioauth
  }

  get bioauthLoginText() {
    if (this.bioauth.isFaceIdEnrolled) {
      return this.intl.t('bioauth.login.login_button_face')
    } else {
      return this.intl.t('bioauth.login.login_button_touch')
    }
  }

  constructor() {
    super(...arguments)

    document.addEventListener(this.bioauth.events.bioauthRecognized, this._bioauthSuccessHandler = async (event) => {
      this.bioauthButtonDisabled = true
      const token = event.detail?.bioauthToken

      const data = await this.bioauth.processBioauthToken(token)
      this.bioauthButtonDisabled = false

      if (data.errors) {
        this.message = data.errors[0]
        this.disableBioauth = true
        return
      }

      this.redirectAfterLogin(data)
    })
  }

  willDestroy() {
    super.willDestroy(...arguments)

    if (this._bioauthSuccessHandler) {
      document.removeEventListener(this.bioauth.events.bioauthRecognized, this._bioauthSuccessHandler)
    }
  }

  redirectAfterLogin(data) {
    this.session.setSession(data)
    if (isPresent(this.previousTransition)) {
      return this.previousTransition.retry()
    } else if (isPresent(this.redirect)) {
      return this.router.transitionTo(this.redirect, this.loan.get('loginKey'))
    } else {
      return this.router.transitionTo('portal.hub', this.loan.get('loginKey'))
    }
  }

  authCodeRequired(data) {
    this.model.setProperties({
      authCode: null,
      password: null,
      authCodeContactMethod: null,
      validityMins: data.validity_mins,
      authCodePhoneNumbers: data.borrower_phone_numbers,
    })

    this.authCodeError = null

    return next(this, () => {
      if (isPresent(this.model.get('authCodePhoneNumbers'))) {
        return UiModalUtils.showModal('auth-code-modal')
      } else {
        return UiModalUtils.showModal('no-phone-number-modal')
      }
    })
  }

  @action
  forgotPassword() {
    const data = {
      email: this.model.get('email'),
      for: this.for,
      redirect: this.redirect,
    }

    return this.store
      .peekRecord('session', 0)
      .forgotPassword(data)
      .then(
        (data) => {
          this.message = data.message
          this.messageKind = 'success'
          this.showError = false
          this.model.get('errors').clear()
          return UiModalUtils.closeModal('forgot-password-modal')
        },
        (EmberError) => {
          const errors = this.store
            .adapterFor('borrower')
            .emberError(EmberError)

          this.showError = false
          this.message = errors.meta.errors.email
          this.messageKind = 'error'

          return UiModalUtils.closeModal('forgot-password-modal')
        }
      )
  }

  @action
  async triggerAuthCode(type) {
    this.model.set('authCode', null)
    this.authCodeError = null
    this.showAuthCodeSpinner = true

    try {
      const response = await secureFetch(
        `/${this.intl.language}/api/v3/direct/sessions/trigger_auth_code`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contact_method: type,
            phone_number_type: this.model.get('authCodePhoneNumberType'),
            is_shopping_pass: this.shoppingPassLoan,
          }),
        }
      )

      const data = await response.json()
      this.model.set('authCodeContactMethod', data.contact_method)
    } catch (error) {
      console.error(error)
    } finally {
      this.showAuthCodeSpinner = false
    }
  }

  @action
  async processAuthCode() {
    const authCodeInput = document.querySelector('[name=authCode]')
    if (authCodeInput && authCodeInput.value !== this.model.get('authCode')) {
      this.model.set('authCode', authCodeInput.value)
    }

    this.showAuthCodeSpinner = true

    try {
      const response = await secureFetch(
        `/${this.intl.language}/api/v3/direct/sessions/process_auth_code`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ auth_code: this.model.get('authCode') }),
        }
      )

      const data = await response.json()

      if (response.ok) {
        UiModalUtils.closeModal('auth-code-modal')
        this.showAuthCodeSpinner = false

        return this.redirectAfterLogin(data)
      } else {
        this.showAuthCodeSpinner = false
        return (this.authCodeError = data.message)
      }
    } catch (error) {
      this.showAuthCodeSpinner = false
      return (this.authCodeError = error.message)
    }
  }

  @action
  async onSubmit() {
    const borrowerData = this.model.serialize()

    if (this.mobile.isMobileApp && borrowerData.email === 'financeit') {
      return this.openEnvironmentModal()
    }

    const data = {
      login_key: this.for,
      login: borrowerData.email,
      password: borrowerData.password,
      email: this.email,
      is_shopping_pass: this.shoppingPassLoan,
    }

    try {
      const responseData = await this.store.peekRecord('session', 0).login(data)

      if (responseData && responseData.auth_code_required) {
        return this.authCodeRequired(responseData)
      }

      return this.redirectAfterLogin(responseData)
    } catch (error) {
      console.error(error)
      this.showError = true
      this.message = ''
    }
  }

  @action
  promptBioauthLogin() {
    this.bioauth.promptBioauth()
    this.message = ''
    this.showError = false
  }

  openEnvironmentModal() {
    UiModalUtils.showModal('environment-modal')
    document.querySelector('[name=environment]')?.focus()
  }
}


export default AccountLoginController
