import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils'

@classic
class PortalPaymentReviewRoute extends Route {
  @service router

  beforeModel() {
    const { changeset } = this.modelFor("portal.payment");
    if (isEmpty(changeset.get('makePaymentAmount'))){
      this.router.transitionTo('portal.payment.make-payment')
    }
  }
}

export default PortalPaymentReviewRoute;
