
import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import PortalHubBankingController from '../banking';

@classic
class PortalHubBankingConfirmationController extends PortalHubBankingController {
  @service
  intl;

  @service store

  @alias('borrowerController.model')
  borrower;

  queryParams = ['sourceFlow'];
  sourceFlow = 'set_up_payments';
  borrowerIdVerifiedStatus = null;
  coborrowerIdVerifiedStatus = null;
  borrowerPoiAlreadyCompleted = false;
  coborrowerPoiAlreadyCompleted = false;
  setUpPaymentsAlreadyCompleted = false;
  accountName = null;

  idNewlyVerified() {
    const currentBorrowerIdVerifiedStatus = this.get('loan.borrowerIdVerified');
    const currentCoborrowerIdVerifiedStatus = this.get('loan.coborrowerIdVerified');
    return (currentBorrowerIdVerifiedStatus !== this.borrowerIdVerifiedStatus) || (currentCoborrowerIdVerifiedStatus !== this.coborrowerIdVerifiedStatus);
  }

  poiNewlyCompleted() {
    return (!this.borrowerPoiAlreadyCompleted && this._isStepComplete('submit_proof_of_income')) ||
      (!this.coborrowerPoiAlreadyCompleted && this._isStepComplete('submit_proof_of_income_coborrower'))
  }

  setUpPaymentsNewlyCompleted() {
    const setUpPaymentsCompleted = this.get('loan.setUpPaymentsCompletedOrInReview')
    return !this.setUpPaymentsAlreadyCompleted && setUpPaymentsCompleted
  }

  @computed('accountName', 'loan.hasCoborrower')
  get confirmationMessage() {
    if (this.get('loan.hasCoborrower') && this.accountName) {
      return this.intl.t(`${this._confirmationMessageStringIdPrefix()}`, { full_name: this.accountName })
    } else {
      return this.intl.t(`${this._confirmationMessageStringIdPrefix()}_single_borrower`)
    }
  }

  _isStepComplete(stepName) {
    const loanSteps = this.store.peekAll('loan-step')
    const step = loanSteps.findBy('name', stepName)
    return step !== undefined && step.get('isCompleted')
  }

  _confirmationMessageStringIdPrefix() {
    if (this.idNewlyVerified() && this.setUpPaymentsNewlyCompleted() && this.poiNewlyCompleted()) {
      return 'hub.banking_processing.set_up_automated_payments_and_verified_id_and_completed_poi_successfully'
    } else if (this.idNewlyVerified() && this.setUpPaymentsNewlyCompleted() && !this.poiNewlyCompleted()) {
      return 'hub.banking_processing.set_up_automated_payments_and_verified_id_successfully'
    } else if (this.idNewlyVerified() && !this.setUpPaymentsNewlyCompleted() && this.poiNewlyCompleted()) {
      return 'hub.banking_processing.completed_poi_and_verified_id_successfully'
    } else if (!this.idNewlyVerified() && this.setUpPaymentsNewlyCompleted() && this.poiNewlyCompleted()) {
      return 'hub.banking_processing.set_up_automated_payments_and_completed_poi_successfully'
    } else if (this.idNewlyVerified() && !this.setUpPaymentsNewlyCompleted() && !this.poiNewlyCompleted()) {
      return 'hub.banking_processing.description_verify'
    } else if (!this.idNewlyVerified() && this.setUpPaymentsNewlyCompleted() && !this.poiNewlyCompleted()) {
      return 'hub.banking_processing.set_up_automated_payments_successfully'
    }

    // Should not arrive at this screen if nothing has been completed
    return 'hub.banking_processing.completed_poi_successfully'
  }

  @action
  complete() {
    this.send('updateIdWasVerifiedStatus');
    return this.send('slideToHub');
  }
}

export default PortalHubBankingConfirmationController;
