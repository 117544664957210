import Component from '@glimmer/component'
import { isPresent } from '@ember/utils'

export default class UiFieldsetComponent extends Component {
  get textSize() {
    if (isPresent(this.args.legendTextSize)) {
      return this.args.legendTextSize
    }

    return 'lg'
  }

  get bold() {
    if (isPresent(this.args.legendBold)) {
      return this.args.legendBold
    }

    return true
  }
}
