import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { guidFor } from '@ember/object/internals'

const KEYBOARD_PAGE_UP = 33
const KEYBOARD_PAGE_DOWN = 34
const KEYBOARD_END = 35
const KEYBOARD_HOME = 36
const KEYBOARD_UP_ARROW = 38
const KEYBOARD_DOWN_ARROW = 40

export default class UiAccordionComponent extends Component {

  @tracked active = this.args.active

  get toggle() {
    return this.args.toggle ?? true
  }

  inputId = 'ui_accordion_' + guidFor(this)

  constructor() {
    super(...arguments)
    this.activePanels = new Set([this.active])
  }

  @action
  selectAccordionItem(event) {
    let allowToggle = this.toggle;
    let active = this.active;
    if (allowToggle &&  active === event) {
      this.active = ''
    } else {
      this.active = event
    }
  }

  @action
  keyboardSelectAccordionItem(event) {
    let triggers = Array.from(document.getElementById(this.inputId).querySelectorAll('.accordion-trigger'))
    let key = event.keyCode
    let index, direction, length, newIndex
    switch (key) {
      case KEYBOARD_DOWN_ARROW:
      case KEYBOARD_UP_ARROW:
      case KEYBOARD_PAGE_DOWN:
      case KEYBOARD_PAGE_UP:
        index = triggers.indexOf(event.target)
        direction = (key === KEYBOARD_DOWN_ARROW || key === KEYBOARD_PAGE_DOWN) ? 1 : -1
        length = triggers.length
        newIndex = (index + length + direction) % length
        triggers[newIndex].focus()
        event.preventDefault()
        break
      case KEYBOARD_END:
        triggers[triggers.length - 1].focus()
        event.preventDefault()
        break
      case KEYBOARD_HOME:
        triggers[0].focus()
        event.preventDefault()
        break
      default:
        break
    }
  }
}
