import classic from 'ember-classic-decorator';
/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';
import { transitionToDefaultRoute } from '../../../../utils/redirect-session';

@classic
class PortalHubBankingChooseAccountRoute extends Route {
  beforeModel() {
    const loan = this.controllerFor('loan').get('model');
    const borrower = this.controllerFor('borrower').get('model');

    if (!isPresent(loan.get('flinksSummaries'))) {
      return transitionToDefaultRoute(this);
    }
  }

  setupController(controller) {
    controller.setUpBeforeUnload();
    const loan = this.controllerFor('loan').get('model');
    const borrower = this.controllerFor('borrower').get('model');
    if (isPresent(loan.get('flinksSummaries'))) {
      return borrower.set('chosenBankAccountId', loan.get('flinksSummaries')[0].id);
    }
  }
}

export default PortalHubBankingChooseAccountRoute;
