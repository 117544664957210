import { action } from '@ember/object'
import PortalHubInsuranceController from '../insurance';

export default class extends PortalHubInsuranceController {
  @action
  confirm() {
    this.submitInsuranceOfferDecisionStep(true)
  }

  @action
  cancel() {
    return this.router.transitionTo('portal.hub.insurance.decision');
  }
}
