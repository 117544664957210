import Model from "@ember-data/model";
import { collectionAction } from "ember-api-actions";
import classic from 'ember-classic-decorator'

@classic
export default class Session extends Model {}

Session.prototype.login = collectionAction({
  path: "login",
  type: "post",
  urlType: "findRecord",
});

Session.prototype.logout = collectionAction({
  path: "logout",
  type: "post",
  urlType: "findRecord",
});

Session.prototype.setPassword = collectionAction({
  path: "set_password",
  type: "post",
  urlType: "findRecord",
});

Session.prototype.forgotPassword = collectionAction({
  path: "forgot_password",
  type: "post",
  urlType: "findRecord",
});

Session.prototype.needsPassword = collectionAction({
  path: "needs_password",
  type: "post",
  urlType: "findRecord",
});

Session.prototype.resetPassword = collectionAction({
  path: "reset_password",
  type: "post",
  urlType: "findRecord",
});

Session.prototype.session = collectionAction({
  path: "session",
  type: "get",
  urlType: "findRecord",
});

Session.prototype.loans = collectionAction({
  path: "loans",
  type: "get",
  urlType: "findRecord"
});

Session.prototype.triggerAuthCode = collectionAction({
  path: "trigger_auth_code",
  type: "post",
});

Session.prototype.processAndSetAccount = collectionAction({
  path: "process_and_set_account",
  type: "post",
});

Session.prototype.twoFactorPhoneNumbers = collectionAction({
  path: "two_factor_phone_numbers",
  type: "post",
});

Session.prototype.directAccountVerify = collectionAction({
  path: "direct_account_verify",
  type: "post",
});

Session.prototype.resendConfirmationEmail = collectionAction({
  path: "resend_confirmation_email",
  type: "post",
});

Session.prototype.processAndResetPassword = collectionAction({
  path: "process_and_reset_password",
  type: "post",
});
