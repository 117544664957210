import Controller, { inject as controller } from '@ember/controller'
import { inject as service } from '@ember/service'
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object'
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed'
import { isEmpty } from '@ember/utils'

export default class PaymentScheduleService extends Controller {
  @service intl
  @controller('loan') loanController
  @alias('loanController.model') loan

  @computed('formattedPayment', 'loan.{dbState,paymentSchedule,paymentScheduleWithInterestAdjustments,termMaturesDate}')
  get scheduleList() {
    if (this.loan.dbState == 'closed') {
      return null
    }

    const payments = this.loan.paymentScheduleWithInterestAdjustments
    const lastDate = this.loan.termMaturesDate

    return payments.filter(payment => {
      //remove defferals
      return parseFloat(payment.payment_due).toFixed(2) !== '0.00';
    }).filter(function(payment) {
      //cap to term length
      const payment_date = new Date(payment.date);
      return payment_date <= lastDate;
    }).map(this.formattedPayment);
  }

  formattedPayment(payment) {
    let formattedPayment = {
      approvalDate: payment.date,
      paymentAmount: payment.payment_due,
      principal: payment.principal_due,
      interest: payment.interest_due,
      remainingPrincipal: payment.remaining_principal
    }

    if (payment.interest_adjustment) {
      formattedPayment['interestAdjustment'] = payment.interest_adjustment;
    }

    return formattedPayment;
  }

  @computed('loan.paidScheduledLoanPayments')
  get paidList() {
    const paidPayments = this.loan.paidScheduledLoanPayments
    if (isEmpty(paidPayments)) {
      return 'empty'
    } else {
      return paidPayments.map(function (payment) {
        return {
          approvalDate: payment.date,
          paymentAmount: payment.total_paid,
          principal: payment.principal_paid,
          interest: payment.interest_paid,
          remainingPrincipal: payment.remaining_principal,
          nsfFee: payment.nsf_fee
        }
      })
    }
  }

  get approvalDateColumn() {
    return {
      disableSorting: true,
      title: this.intl.t('payment_details.date'),
      propertyName: 'approvalDate',
      component: 'ui-table-array/cells/date',
    }
  }
  get paymentAmountColumn() {
    return {
      disableSorting: true,
      title: this.intl.t('payment_details.payment_amount'),
      propertyName: 'paymentAmount',
      component: 'ui-table-array/cells/payment-schedule/payment-amount',
      componentProperties: {
        forPaymentHistory: false
      },
      cellClassName: 'tw-flex tw-justify-end md:tw-justify-start tw-text-right md:tw-text-left'
    }
  }
  get principalColumn() {
    return {
      disableSorting: true,
      title: this.intl.t('payment_details.principal'),
      propertyName: 'principal',
      component: 'ui-table-array/cells/money',
      className: 'tw-hidden md:tw-table-cell'
    }
  }
  get interestColumn() {
    return {
      disableSorting: true,
      title: this.intl.t('payment_details.interest'),
      propertyName: 'interest',
      component: 'ui-table-array/cells/money',
      className: 'tw-hidden md:tw-table-cell'
    }
  }
  get remainingPrincipalColumn() {
    return {
      disableSorting: true,
      title: this.intl.t('payment_details.remaining_principal'),
      propertyName: 'remainingPrincipal',
      component: 'ui-table-array/cells/money',
      className: 'tw-hidden md:tw-table-cell'
    }
  }

  get paymentScheduleColumns() {
    return [
      this.approvalDateColumn,
      this.paymentAmountColumn,
      this.principalColumn,
      this.interestColumn,
      this.remainingPrincipalColumn
    ]
  }
  get paymentHistoryColumns() {
    let paymentAmountColumn = {...this.paymentAmountColumn}
    paymentAmountColumn.componentProperties.forPaymentHistory = true

    return [
      this.approvalDateColumn,
      paymentAmountColumn,
      this.principalColumn,
      this.interestColumn,
      this.remainingPrincipalColumn
    ]
  }

  _stripColumnAndCellClasses(column) {
    delete column.className
    delete column.cellClassName
    return column
  }
  get paymentScheduleColumnsPrinterFriendly() {
    return this.paymentScheduleColumns.map(this._stripColumnAndCellClasses)
  }
  get paymentHistoryColumnsPrinterFriendly() {
    return this.paymentHistoryColumns.map(this._stripColumnAndCellClasses)
  }
}
