import classic from 'ember-classic-decorator'
import { action, computed } from '@ember/object'
import { inject as controller } from '@ember/controller'
import { inject as service } from '@ember/service'
import PortalController from '../../portal'
import { isEmpty } from '@ember/utils'

@classic
class PortalPaymentMakePaymentController extends PortalController {
  @controller('portal/funded') funded
  @service router

  error = null
  showSpinner = false

  @computed(
    'loan.currentArrears.is_in_arrears', 'model.changeset.{makeExtraPaymentAmount,makePaymentAmount,makePaymentType}'
  )
  get emptyValue() {
    const { changeset } = this.model
    if (this.loan.currentArrears.is_in_arrears)
      return isEmpty(changeset.get('makePaymentAmount'))
    else
      if (changeset.get('makePaymentType') === 'principal') {
        return isEmpty(changeset.get('makeExtraPaymentAmount'))
      } else if (changeset.get('makePaymentType') === 'next_payment') {
        return false
      } else if (changeset.get('makePaymentType') === 'full_balance') {
        return false
      }
      else {
        return isEmpty(changeset.get('makePaymentType')) || isEmpty(changeset.get('makePaymentAmount'))
      }
  }

  @computed
  get todaysDate() {
    return moment().format('MMMM D, YYYY')
  }

  @computed('loan.bankAccountDigits')
  get noBankAccount() {
    return isEmpty(this.loan.bankAccountDigits)
  }

  @computed('noBankAccount', 'emptyValue')
  get disableButton() {
    return this.noBankAccount || this.emptyValue
  }

  async paymentValidation() {
    const {changeset, payment, loan} = this.model
    const field = this.model.changeset.get('makePaymentType') === 'principal' ? 'makeExtraPaymentAmount' : 'makePaymentAmount'

    try {
      await payment.validatePaymentAmount({
        payment_type: this.model.changeset.get('makePaymentType'),
        payment_method: this.model.changeset.get('paymentMethod'),
        amount: changeset.get(field),
        login_key: loan.get('loginKey')
      })
      return this.router.transitionTo('portal.payment.review.index')
    }
    catch(errorResponse) {
      errorResponse.errors.forEach(error => {
        changeset.pushErrors(field, error.meta.errors)
      })
    }
  }

  @action
  updateBankClick() {
    return this.transitionToRoute('portal.banking.change-account')
  }

  @action
  reviewPaymentClick() {
    this.model.changeset.set('paymentMethod', 'transfer')

    if (!this.loan.currentArrears.is_in_arrears) {
      if (this.model.changeset.get('makePaymentType') === 'principal') {
        this.model.changeset.set('makePaymentAmount', this.model.changeset.get('makeExtraPaymentAmount'))
      } else if (this.model.changeset.get('makePaymentType') === 'next_payment') {
        this.model.changeset.set('makePaymentAmount', this.model.loan.get('nextNonHolidayScheduledPayment.due'))
      } else if (this.model.changeset.get('makePaymentType') === 'full_balance') {
        this.model.changeset.set('makePaymentAmount', this.model.loan.get('amountNeededToPayOffLoan'))
      }
    }

    this.paymentValidation()
  }
}

export default PortalPaymentMakePaymentController
