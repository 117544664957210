import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class UiPageSelector extends Component {

  get currPage() {
    return Number.parseInt(this.args.currPage)
  }

  get pageCount() {
    let pageSize = Number.parseInt(this.args.pageSize)
    if (Number.isNaN(pageSize) || pageSize === 0) return 1

    return Math.ceil(this.args.recordCount / this.args.pageSize)
  }

  get pageList() {
    let pages = []
    const buffer = 3
    for (let i = 1; i <= this.pageCount; i++) {
      if (!this.shouldCondense) {
        pages.push(i)
        continue
      }

      const isWithinStartBuffer = i <= buffer && this.currPage <= buffer
      const isWithinEndBuffer = i > (this.pageCount - buffer) && this.currPage > (this.pageCount - buffer)

      /*
        The "buffer" is the number of pages to show around the current page. This includes the current
        page as well. So for example if there's a buffer of 3, we should show pages 4,5,6

        The exceptions to this rule are:
          - Always show the first page, regardless of current page
          - Always show the last page, regardless of current page
          - If the current page is within the first n pages, where n is the buffer size, show the first n pages
          - If the current page is with the last n pages, where n is the buffer size, show the last n pages
       */
      if (
        (i === 1) || // always show first page
        (i === this.pageCount) || // always show last page
        isWithinStartBuffer ||
        isWithinEndBuffer ||
        (!isWithinStartBuffer && !isWithinEndBuffer && i >= this.currPage - Math.floor(buffer / 2) && i <= this.currPage + Math.floor(buffer / 2))
      ) {
        pages.push(i)
      } else if (pages[pages.length - 1] !== '...') {
        pages.push('...')
      }
    }
    return pages
  }

  get shouldCondense() {
    return this.pageCount > (Number.parseInt(this.args.condenseWhenCount) || 15)
  }

  @action
  setPage(page) {
    let currPage = this.currPage
    let pageCount = this.pageCount

    if (page < 1 || page > pageCount || page === currPage) {
      return
    }

    if (this.args.onPageChange) {
      this.args.onPageChange(page)
    }
  }
}
