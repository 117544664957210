import { inject as service } from '@ember/service'
import FormFieldComponent from './ui-form-field'
import { action } from '@ember/object'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'
import { tracked } from '@glimmer/tracking'


@uiFormFieldValue
export default class UiSignaturePad extends FormFieldComponent {
  @service intl

  @tracked editMode = false

  get height() {
    return this.args.height ?? 200
  }

  /* if an error is triggered on this component while it's in the first screen / mode,
  it will transition to edit mode and display the error and red border */
  @action
  onSwitchToEditModeFromInitialMode() {
    if (this.errored && !this.value) {
      this.editMode = true
    }
  }

  @action
  editSignature() {
    this.editMode = true
  }

  @action
  doneEditing() {
    this.editMode = false
  }

  @action
  onSignatureChange(value) {
    this.value = value
  }
}
