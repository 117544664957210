import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { formatAsCurrency } from 'tag/utils/number-formatters'

@classic
class PortalPaymentOptionsReviewIndexRoute extends Route {
  @service
  intl;

  async model() {
    try {
      const { changeset, loan } = this.modelFor('portal.payment-options');
      const frequencyData = await loan.previewNewPaymentFrequencyAndDates({
        next_payment_date: changeset.get('paymentDate'),
        frequency: changeset.get('changeFrequency'),
      });

      return {
        changeset,
        frequency: frequencyData.frequency,
        // eslint-disable-next-line no-undef
        nextPaymentDate: moment(this.formattedPayment(frequencyData.next_scheduled_payment).approvalDate).format(this.intl.t('date.formats.date_only')),
        nextPaymentAmount: formatAsCurrency(this.formattedPayment(frequencyData.next_scheduled_payment).paymentAmount, { precision: 2 }),
        previewPaymentDetails: frequencyData.payments.map(this.formattedPayment)
      }
    } catch {
      this.transitionToRoute('portal.payment-options.error');
    }
  }

  beforeModel() {
    document.title = this.intl.t('payment_options.review.title');
  }

  formattedPayment(payment) {
    let formattedPayment = {
      approvalDate: payment.date,
      paymentAmount: payment.payment_due,
      principal: payment.principal_due,
      interest: payment.interest_due,
      remainingPrincipal: payment.remaining_principal
    }

    if (payment.interest_adjustment) {
      formattedPayment['interestAdjustment'] = payment.interest_adjustment;
    }

    return formattedPayment;
  }
}

export default PortalPaymentOptionsReviewIndexRoute;
