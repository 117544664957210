import classic from 'ember-classic-decorator';
import { inject as controller } from '@ember/controller';
import FinanceitComponentsBorrowerController from 'financeit-components/controllers/borrower';

@classic
class BorrowerController extends FinanceitComponentsBorrowerController {
  @controller
  session;
}

export default BorrowerController;
