import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import PortalHubBankingController from '../banking';

@classic
class PortalHubBankingNoneFoundController extends PortalHubBankingController {
  @alias('borrowerController.model')
  borrower;

  queryParams = ['sourceFlow', { idvStepId: 'idvStepId', borrowerType: 'borrowerType' }];
  sourceFlow = 'set_up_payments';
  idvStepId = null;
  borrowerType = "Borrower";
}

export default PortalHubBankingNoneFoundController;
