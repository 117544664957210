import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'

class AccountNewRoute extends Route {
  @service
  intl

  @service store

  beforeModel(transition) {
    const new_controller = this.controllerFor('account.new')
    new_controller.set('showError', false)
    new_controller.set('backendId', transition.to.queryParams.backendId)
    // eslint-disable-next-line ember/no-jquery
    return $(document).attr('title', this.intl.t('title.create_account'))
  }

  model(params) {
    return this.store.createRecord('borrower')
  }

  resetController(controller, isExiting) {
    return controller.set('showError', false)
  }
}

export default AccountNewRoute
