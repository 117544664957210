import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class FundingConditionsModalComponent extends Component {
  modalId = 'funding_conditions'

  get approvalExpiryText() {
    return this.args.loan?.approvalExpiryText ?? ''
  }

  @action
  closeModal() {
    closeModal(this.modalId)
  }
}
