import PortalPaymentController from '../payment'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'

export default class PortalPaymentPayoutStatementController extends PortalPaymentController {
  @service intl
  @service snowplow
  @service mobile

  setTitle() {
    document.title = this.intl.t('payment_options.payout_statement.title')
  }
  get currentDate() {
    return new Date(moment().startOf('day'))
  }

  get hasNextScheduledPayment() {
    return this.model.loan.nextNonHolidayScheduledPayment != null
  }

  get maxEligibleStatementDate() {
    if (this.hasNextScheduledPayment) {
      return new Date(moment(this.model.loan.nextNonHolidayScheduledPayment.raw_date).subtract(1, 'days'))
    } else {
      return null
    }
  }

  get paymentDate() {
    return this.model.changeset.get('paymentDate')
  }

  @action
  openPayoutStatement() {
    this.snowplow.snowplow('trackStructEvent', {
      category: 'FinanceIt::Direct::Portal::Payments::PayoutStatementController',
      action: 'generate_payout_statement_document'
    });
    const documentDate = moment(this.paymentDate).format('MMMM-DD-YYYY');

    const url = `/${this.intl.language}/api/v3/direct/loans/${this.loan.id}/documents/create_payout_statement?date=${documentDate}`

    if (this.mobile.isMobileApp) {
      return this.mobile.callBridgeFunction('download_and_open_file', url)
    } else {
      return window.open(url);
    }
  }

}
