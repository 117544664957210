import FormFieldComponent from './ui-form-field'
import { action } from '@ember/object'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'

@uiFormFieldValue
export default class UiRadioGroupComponent extends FormFieldComponent {
  @action
  onChange(value) {
    this.value = value
  }
}
