import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
/* eslint-disable
    ember/no-jquery,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils';
import Controller, { inject as controller } from '@ember/controller';

@classic
class SessionController extends Controller {
  @controller
  subvention;

  @controller
  borrower;

  @controller
  loan;

  @controller
  partner;

  @service store

  /** @type {import('financeit-components/app/services/mobile').default} */
  @service mobile
  @service router;

  redirect = null;
  key = null;
  loggedIn = false;
  loggedInUser = null;
  loggedInAsAdmin = false;
  activeLoansCount = 0;
  returnForLoan = null;
  managingGroup = null;

  setTheme() {
    $('html').addClass(this.managingGroup).attr("data-theme", this.managingGroup);

    // Manually sets the Home Depot theme hook
    if (isPresent(this.managingGroup) && (this.managingGroup.indexOf('home_depot') !== -1)) {
      return $('html').attr("data-theme", "home_depot");
    }
  }

  setSession(data) {
    if (data.redirect) {
      this.set('redirect', data.redirect);
    }

    if (data.return_for_loan) {
      this.set('returnForLoan', data.return_for_loan);
    }

    if (data.is_shopping_pass) {
      const params = { for: this.returnForLoan };
      this.router.transitionTo('account.hdlogin', { queryParams: params });
    }

    if (data.borrower && data.partner && data.loan) {
      this.mobile.callBridgeFunction('setup_user', data.borrower.id);
      this.store.pushPayload('borrower', { borrower: data.borrower });
      this.borrower.set('model', this.store.peekRecord('borrower', data.borrower.id));

      const oldPartner = this.store.peekAll('partner').objectAt(0)
      if(oldPartner && data.partner) {
        run(() => oldPartner?.unloadRecord());
      }

      this.store.pushPayload('partner', { partner: data.partner });
      this.partner.setModel(this.store.peekRecord('partner', data.partner.id));

      const oldLoan = this.store.peekAll('loan').objectAt(0)
      if(oldLoan && data.loan) {
        run(() => oldLoan?.unloadRecord());
      }

      this.store.pushPayload('loan', { loan: data.loan });
      this.loan.set('model', this.store.peekRecord('loan', data.loan.id));

      if(data.loan_funding_tranches) {
        const oldTranches = this.store.peekAll('loanTranche')
        run(() => oldTranches.forEach(tranche => tranche?.unloadRecord()))
      }

      data.loan_funding_tranches.forEach(tranche => {
        this.store.pushPayload('loanTranches', { loanTranche: tranche });
      });

      data.loan_funding_tranches.forEach(tranche => {
        this.store.peekRecord('loan', data.loan.id).get('loanTranches').addObject(this.store.peekRecord('loanTranche', tranche.id));
      });

      if (data.loan_steps) {
        const oldSteps = this.store.peekAll('loanStep')
        run(() => oldSteps.forEach(step => step?.unloadRecord()))
      }

      data.loan_steps.forEach(step => {
        this.store.pushPayload('loanStep', { loanStep: step });
      });

      data.loan_steps.forEach(step => {
        this.store.peekRecord('loan', data.loan.id).get('loanSteps').addObject(this.store.peekRecord('loanStep', step.id));
      });

      if (data.coborrower) { this.store.pushPayload('borrower', { borrower: data.coborrower }); }

      this.subvention.set('vertical', data.loan.vertical);
      const promo = data.loan.subvention_options.findBy('id', +data.loan.promo);
      this.subvention.set('promo', promo);

      this.set('loggedInUser', this.store.peekRecord('borrower', data.current_user_id));
      this.set('activeLoansCount', data.active_loans_count)
      this.set('loggedIn', true);
      //Sets newest csrf-token
      document.querySelector("meta[name='csrf-token']").setAttribute('content', data.csrf_token)
      return this.set('loggedInAsAdmin', data.admin_logged_in);
    }
  }
}

export default SessionController;
