import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';

@classic
class AccountSetupRoute extends Route {
  @service
  intl;

  @service store
  @service router

  beforeModel(transition) {
    document.title = this.intl.t('title.create_account');
    if (!isPresent(transition.to.queryParams.for)) {
      return this.router.transitionTo('error');
    }
  }

  model() {
    return this.store.createRecord('borrower');
  }

  resetController(controller) {
    controller.set('isPasswordCorrect', false);
  }
}

export default AccountSetupRoute;
