import Component from '@glimmer/component'
import { isEmpty } from '@ember/utils'
import { action } from '@ember/object'

export default class UiFormGroupComponent extends Component {
  get srOnlyClass() {
    if (isEmpty(this.args.srOnlyLabel) || this.args.srOnlyLabel === false) {
      return ''
    } else if (this.args.srOnlyLabel === true) {
      return 'tw-sr-only'
    } else {
      return this.args.srOnlyLabel + ':tw-sr-only'
    }
  }

  @action
  onActionClick() {
    this.args.onActionClick?.()
  }
}
