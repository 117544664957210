import { inject as service } from '@ember/service'
import { isPresent } from '@ember/utils'
import Route from '@ember/routing/route'

class AccountLoginRoute extends Route {
  isHdLogin = false
  @service intl
  @service store
  @service router
  /** @type {import('direct/services/bioauth').default} */
  @service bioauth
  @service mobile

  activate() {
    if (this.bioauth.isBioauthEnrolled) {
      this.bioauth.promptBioauth(false)
    }
  }

  async beforeModel(transition) {
    let redirect = this.controllerFor('session').get('redirect')

    if (isPresent(redirect)) {
      return window.location.replace(redirect)
    }

    if (this.doRedirectToHdLogin(transition)) {
      return this.router.transitionTo('account.hdlogin')
    }

    document.title = this.intl.t('title.login')

    const loginController = this.controllerFor(this.routeName)
    loginController.set('showError', false)

    if (isPresent(transition.to.queryParams.for)) {
      try {
        const data = await this.store.peekRecord('session', 0).needsPassword({
          login_key: transition.to.queryParams.for,
          email: transition.to.queryParams.email,
        })

        if (data.logged_in) {
          redirect = transition.to.queryParams.redirect || 'portal.hub'
          return this.router.transitionTo(
            redirect,
            transition.to.queryParams.for
          )
        } else {
          if (data.needs_password) {
            return this.router.transitionTo('account.new', {
              queryParams: { backendId: data.backend_id },
            })
          }

          if (
            !(
              loginController.get('isFirstLogin') &&
              loginController.get('needsPassword')
            )
          ) {
            // eslint-disable-next-line ember/no-jquery
            return $('.modal-backdrop').hide()
          }
        }
      } catch (error) {
        this.router.transitionTo('error')
      }
    }
  }

  model(params) {
    const borrower = this.store.createRecord('borrower')
    const email_field = params.email || ''
    borrower.set('email', email_field)

    return borrower
  }

  afterModel() {
    return this.controllerFor('session').setTheme()
  }

  resetController(controller) {
    controller.set('updateSuccessful', null)
  }

  doRedirectToHdLogin(transition) {
    return this.mobile.isMobileApp && this.mobile.isHdMobileApp && transition.to.name === 'account.login'
  }
}

export default AccountLoginRoute
