import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-jquery,
    ember/no-incorrect-calls-with-inline-anonymous-functions,
    no-undef
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Route from '@ember/routing/route';
import { checkAuthenticated } from '../../utils/redirect-session';

@classic
class PortalMyDocumentsRoute extends Route {
  @service
  intl;

  beforeModel(transition) {
    checkAuthenticated(transition, this);
    return $(document).attr('title', this.intl.t('title.my_documents'));
  }

  model() {
    return this.controllerFor('loan').get('model').getDocuments();
  }
}

export default PortalMyDocumentsRoute;
