import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import PortalHubBankingController from '../banking';

@classic
class PortalHubBankingConnectController extends PortalHubBankingController {
  @controller('portal.hub')
  hubController;

  queryParams = ['sourceFlow', { idvStepId: 'idvStepId', borrowerType: 'borrowerType'}];
  sourceFlow = 'verify_id';
  idvStepId = null;
  borrowerType = "Borrower";

  @service
  intl;

  @computed('borrowerType', 'idvStepId', 'loan.bankDetailsRedirectUrl', 'sourceFlow')
  get redirectUrl() {
    return this.get('loan.bankDetailsRedirectUrl') + encodeURIComponent(`?source=${this.sourceFlow}` + `&idv_step_id=${this.idvStepId}` + `&borrowerType=${this.borrowerType}`);
  }

  @computed('loan.bankConnectParams', 'redirectUrl')
  get connectParams() {
    return this.get('loan.bankConnectParams') + `&redirectUrl=${this.redirectUrl}`;
  }

  @computed('connectParams', 'loan.bankConnectUrl')
  get bankLoginUrl() {
    return this.get('loan.bankConnectUrl') + `?${this.connectParams}`;
  }

  @computed('sourceFlow')
  get stepTitle() {
    switch(this.sourceFlow) {
      case 'verify_id':
        return this.intl.t('hub.verify_id.banking_title');
      case 'poi':
        return this.intl.t('hub.submit_proof_of_income.banking_title');
      default:
        return this.intl.t('hub.set_up_automated_payments.banking_title');
    }
  }
}

export default PortalHubBankingConnectController;
