import Component from '@glimmer/component'

export default class UiHint extends Component {
  get textColor() {
    if (this.args.kind === 'interactive') {
      return 'interactive-100'
    }

    if (this.args.kind === 'error') {
      return 'error-dark'
    }

    return 'ui-black-80'
  }

  get italics() {
    if (this.args.kind === 'error') {
      return false
    }

    return true
  }
}
