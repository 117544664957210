import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

@classic
export default class AuthenticateController extends Controller {
  @controller
  session;

  @service store
  @service intl
  @service router

  queryParams = ['token', 'resetPassword'];
  token = null;
  resetPassword = null;
  authCodeError = null;
  showSpinner = false;

  get backButtonHref() {
    return `/${this.intl.language}/direct/account/login`
  }

  @action
  async onSubmit() {
    const authCodeInput = document.querySelector('[name=authCode]');
    if (authCodeInput && authCodeInput.value !== this.get('model.authCode')) {
      this.set('model.authCode', authCodeInput.value);
    }

    const params = {
      auth_code: this.get('model.authCode'),
      token: this.token
    };
    let promise;

    if (this.resetPassword) {
      promise = this.store.peekRecord('session', 0).processAndResetPassword(params);
    } else {
      promise = this.store.peekRecord('session', 0).processAndSetAccount(params);
    }

    try {
      const data = await promise;
      this.session.setSession(data);
      this.router.transitionTo('portal.hub', data.loan.login_key);
      this.set('showSpinner', false);
    } catch(e) {
      const errors = this.store.adapterFor('session').emberError(e);
      if (errors && errors.authCode) {
        this.set('authCodeError', errors.authCode);
      }
      else if(this.token){
        return window.location.reload();
      }
      this.set('showSpinner', false);
    }
  }

  @action
  async triggerAuthCode(type) {
    this.set('showSpinner', true);
    this.set('model.authCode');
    this.set('authCodeError', null);
    try {
      const data = await this.store.peekRecord('session', 0).triggerAuthCode({
        contact_method: type,
        phone_number_type: this.get('model.authCodePhoneNumberType'),
        token: this.token,
        reset_password: this.resetPassword
      });

      this.set('model.authCodeContactMethod', data.contact_method);
      this.set('showSpinner', false);
    } catch {
      if(this.token) {
        return window.location.reload();
      }
      this.set('showSpinner', false);
    }
  }
}
