import classic from 'ember-classic-decorator';
/* eslint-disable
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';
import { checkAuthenticated } from '../../utils/redirect-session';

@classic
class RedirectResignLoanAgreementRoute extends Route {
  beforeModel(transition) {
    return checkAuthenticated(transition, this);
  }

  redirect(transition) {
    // We need this route since due to ember and rails mixing for financeit direct
    // its almost impossible to setup a rails redirect without breaking ember redirect and routes
    return window.location.href = this.controllerFor('session').get('loggedInUser.paymentHolidayResigningUrl').replace(':login_key', this.controllerFor('loan').get('model.loginKey'));
  }
}

export default RedirectResignLoanAgreementRoute;
