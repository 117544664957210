import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { onActivate, onDeactivate } from '../../../../utils/step-transition';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

@classic
class SecureYourAccountErrorRoute extends Route {
  @service store
  
  activate() {
    super.activate(...arguments)
    onActivate()
  }

  deactivate() {
    super.deactivate(...arguments)
    onDeactivate()
  }

  model(params) {
    return RSVP.hash({
      partner: this.controllerFor('partner').get('model'),
      borrower: this.store.peekAll('borrower').findBy('borrowerType', params.borrowerType),
      loan: this.controllerFor('loan').get('model'),
    })
  }
}

export default SecureYourAccountErrorRoute; 
