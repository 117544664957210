import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class SubmitProofOfIncomeStepComponent extends Component {
  get employmentStatus() {
    return this.poiStepBorrower.get('employmentStatus')
  }

  get isEmployed() {
    return this.employmentStatus === 'Employed'
  }

  get isSelfEmployed() {
    return this.employmentStatus === 'Self Employed'
  }

  get isOtherIncomeSource() {
    return this.employmentStatus === 'Other Income Source'
  }

  get isCoborrower() {
    return this.poiStepBorrower.get('borrowerType') === 'Coborrower'
  }

  get poiStepBorrower() {
    if (this.args.model.get('name') === 'submit_proof_of_income_coborrower') {
      return this.args.loan.coborrower
    }

    return this.args.loan.borrower
  }

  @action
  submitStep() {
    this.args.submitStep?.()
  }
}
