import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

@classic
class AccountRoute extends Route {
  @service store

  activate() {
    return document.querySelector('html').classList.add('disable-legacy-theming');
  }

  deactivate() {
    return document.querySelector('html').classList.remove('disable-legacy-theming');
  }

  beforeModel() {
    super.beforeModel(...arguments);

    const session = this.store.peekRecord('session', 0);
    if (!session) {
      return this.store.createRecord('session', { id: 0 });
    }
  }
}

export default AccountRoute;
