import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

@classic
export default class SetUpPaymentsRoute extends Route {
  @service store
  @service router

  beforeModel(transition) {
    const loan = this.controllerFor('loan').get('model');
    const voidChequeStep = this.store.peekAll('loan-step').findBy('name', 'set_up_payments');

    if (transition.to.queryParams.forceTransition) { return false; }

    //redirect to upload void cheque step if user is not using flinks
    const paymentStrategyInUse = voidChequeStep.get('directState') !== 'new';
    const userIsUsingVoidCheque = paymentStrategyInUse && (loan.get('currentFlinksResponse') === null);
    if (userIsUsingVoidCheque) {
      return this.router.transitionTo('portal.hub.loan-steps', 'set_up_payments');
    }
  }
}
