import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import PortalController from '../../../portal';
import { inject as service } from '@ember/service';

@classic
class PortalPaymentOptionsReviewController extends PortalController {
  @service store

  @controller('services/paymentSchedule')
  paymentSchedule;

  showSpinner = false;

  @action
  async confirmationPaymentOptionsClick() {
    try {
      this.set('showSpinner', true)
      await this.loan.changePaymentFrequencyOrAllDates({
        next_payment_date: this.model.changeset.get('paymentDate'),
        frequency: this.model.changeset.get('changeFrequency')
      }).then((data) => {
        this.set('showSpinner', false)
        this.store.pushPayload('loan', { loan: data.loan });
      });

      window.scrollTo(0, 0);
      return this.transitionToRoute('portal.payment-options.review.confirmation');
    } catch {
      this.set('showSpinner', false)
      return this.transitionToRoute('portal.payment-options.error');
    }
  }
}

export default PortalPaymentOptionsReviewController;
