import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';

@classic
class PaymentPlanRedirectToApplyRoute extends Route {
  @service router

  beforeModel(transition) {
    const { key } = transition.params["payment-plan"];
    return this.router.transitionTo('payment-plan.apply', key);
  }
}

export default PaymentPlanRedirectToApplyRoute;
