import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';
import { removeClass } from 'financeit-components/utils/dom-node-helpers';

@classic
class PortalHubRoute extends Route {
  @service
  intl;

  @service store

  @service
  notification

  beforeModel(transition) {
    const isLoggedIn = checkAuthenticated(transition, this);
    document.title = this.intl.t('title.hub')
    this.controllerFor('partner').setTheme()

    removeClass('body', 'modal-open')

    if (isLoggedIn) {
      const id = this.controllerFor('loan').get('model.id');
      const url = `/${this.intl.language}/api/v3/direct/loans/${id}`;
      // eslint-disable-next-line ember/no-jquery
      return $.ajax({
        type: 'GET',
        url
      }).then((data) => {
        this.store.push(this.store.normalize('loan', data.loan));
        const loanRecord = this.store.peekRecord('loan', data.loan.id);

        this.clearLoanSteps(loanRecord)

        const loanStepRecords = data.loan_steps.map(step => {
          return this.store.push(this.store.normalize('loanStep', step));
        });

        loanRecord.get('loanSteps').setObjects(loanStepRecords);

        const loan = this.controllerFor('loan').get('model');

        if (loan.get('state') !== 'approved') {
          transitionToDefaultRoute(this);
        }

        if (loan.get('flinksAccountDetailBeingRequested')) {
          this.store.peekAll('loan-step').findBy('name', 'verify_id').set('directState', 'completed');
        }

        this.controllerFor('subvention').set('vertical.borrower_fee_mapping', data.borrower_fees.borrower_fee_mapping);
        this.controllerFor('subvention').set('borrowerFeePercent', data.borrower_fees.borrower_fee_percent);
        this.controllerFor('subvention').set('borrowerMaxFee', data.borrower_fees.borrower_max_fee);
        this.controllerFor('subvention').set('applicableAmortizations', data.amortizations.applicable_amortizations);
        this.controllerFor('subvention').set('defaultMaxAmortization', data.amortizations.default_max);
        this.controllerFor('loan').set('model.minRate', data.rate_range.min_rate);
        return this.controllerFor('loan').set('model.maxRate', data.rate_range.max_rate);
      });
    }
  }

  clearLoanSteps(loanRecord) {
    const loanStepRecords = loanRecord.get('loanSteps').map(loanStep => this.store.peekRecord('loanStep', loanStep.id))
    loanRecord.get('loanSteps').setObjects([])
    loanStepRecords.forEach(loanStepRecord => loanStepRecord.unloadRecord())
  }

  model() {
    return this.controllerFor('loan').get('model');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    return controller.initShoppingPassSockets();
  }
}

export default PortalHubRoute;
