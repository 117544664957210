import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import PortalController from '../../../portal';

@classic
class PortalPaymentOptionsConfirmationController extends PortalController {
  @controller('services/paymentSchedule')
  paymentSchedule;

  @action
  donePaymentOptionsClick() {
    return this.transitionToRoute('portal.funded');
  }
}

export default PortalPaymentOptionsConfirmationController;
