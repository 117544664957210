import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import PortalController from '../../portal'

@classic
class PortalBankingConnectController extends PortalController {
  @computed('loan.changeBankDetailsRedirectUrl')
  get redirectUrl() {
    return this.get('loan.changeBankDetailsRedirectUrl');
  }

  @computed('loan.bankConnectParams', 'redirectUrl')
  get connectParams() {
    return this.get('loan.bankConnectParams') + `&redirectUrl=${this.redirectUrl}`;
  }

  @computed('connectParams', 'loan.bankConnectUrl')
  get bankLoginUrl() {
    return this.get('loan.bankConnectUrl') + `?${this.connectParams}`;
  }
}

export default PortalBankingConnectController;
