// Consider using 'model.changedAttributes' instead of manually
// specifying properties not to serialize.

import classic from 'ember-classic-decorator';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from './application';

@classic
export default class LoanSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    carOrFirstVehicleType: { serialize: false },
    canReduceRequestedAmount: { serialize: false },
    addCoborrowerAvailable: { serialize: false },
    loginKey: { serialize: false },
    fixedCost: { serialize: false },
    approvalExpiryText: { serialize: false },
    hasChangedHousingAndIncome: { serialize: false },
    borrowerSubventionPercentage: { serialize: false },
    state: { serialize: false },
    dbState: { serialize: false },
    paymentSchedule: { serialize: false },
    eligibleForSinResubmit: { serialize: false },
    lender: { serialize: false },
    isQuickFund: { serialize: false },
    approvalConditions: { serialize: false },
    vertical: { serialize: false },
    expiryDate: { serialize: false },
    promo: { serialize: false },
    name: { serialize: false },
    annualPercentageRate: { serialize: false },
    spAnnualPercentageRate: { serialize: false },
    fromQuebec: { serialize: false },
    poiThreshold: { serialize: false },
    maxAmountToShow: { serialize: false },
    canChangeAmount: { serialize: false },
    subventionOptions: { serialize: false },
    totalIncome: { serialize: false },
    totalDebt: { serialize: false },
    limitMinRequestedAmount: { serialize: false },
    minRate: { serialize: false },
    maxRate: { serialize: false },
    borrowerFeePercentage: { serialize: false },
    amortLimits: { serialize: false },
    displayGrade: { serialize: false },
  }
}
