import Component from '@glimmer/component'
import { task } from 'ember-concurrency'
import { action } from '@ember/object'

export default class UiFormComponent extends Component {
  @task(function*(event) {
    yield this.args.onSubmit?.(event)
  }) performSubmit

  @action
  submit(event) {
    event.preventDefault()
    event.stopPropagation()

    let changeset = this.args.object
    if (changeset && changeset.isInvalid) {
      changeset.set('_errors', {})
    }
    this.performSubmit.perform(event)
  }
}
