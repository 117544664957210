import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-jquery,
    no-undef
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';
import setTransitionOriginator from '../../utils/set-transition-originator'

@classic
class PortalHardDeclineRoute extends Route {
  @service
  intl;

  init() {
    super.init(...arguments);
    this.on('routeWillChange', this.willTransition.bind(this))
  }

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel(transition) {
    $(document).attr('title', this.intl.t('title.hard_decline'));
    checkAuthenticated(transition, this);
    if (this.controllerFor('loan').get('model.state') !== 'declined') {
      return transitionToDefaultRoute(this);
    }
  }

  setupController(controller, model) {
    controller.set('model', model);
    return controller.scheduleOpenResubmitSinModal();
  }

  @action
  willTransition(transition) {
    const model = this.controller.get('model')
    return setTransitionOriginator(transition, model, this.routeName);
  }
}

export default PortalHardDeclineRoute;
