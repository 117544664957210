import { action } from '@ember/object';
import { inject as service } from '@ember/service'
import * as UiModalUtils from 'tag/utils/ui-modal'

import PortalOffersController from '../offers';

export default class PortalOffersOfferController extends PortalOffersController {
  @service intl
  queryParams = ['fromHub'];
  fromHub = false;

  get subventionType() {
    return this.subvention?.promo?.type;
  }

  get subventionDeferralPeriod() {
    return this.subvention?.promo?.amortization_offset;
  }

  get partner() {
    return this.model.quote.partner;
  }

  get vertical() {
    return this.model.loan.vertical;
  }

  get purchaseAmount() {
    return this.model.loan.requestedAmount;
  }

  get headingText() {
    if (this.model.loan.isShoppingPass) {
      return this.intl.t("apply.calculator.heading_principal_only", {duration: this.model.loan.shoppingPassDuration})
    } else {
      return this.intl.t("apply.calculator.heading")
    }
  }

  @action
  openLearnMoreModal() {
    UiModalUtils.showModal('learn-more-modal')
  }

  @action
  showMobileQuoteModal() {
    UiModalUtils.showModal('quote-modal');
  }
}
