import Component from '@glimmer/component'

const HEADINGS = {
  h1: {
    default: '5xl',
    sm: '6xl'
  },
  h2: {
    default: '4xl',
    sm: '5xl'
  },
  h3: {
    default: '2xl',
    sm: '4xl'
  },
  h4: {
    default: 'xl',
    sm: '2xl'
  },
  h5: {
    default: 'lg',
    sm: 'xl'
  },
  h6: {
    default: 'base',
    sm: 'lg'
  }
}

export default class UiHeading extends Component {
  get classes() {
    return ['tw-w-full', 'tw-font-bold', 'tw-leading-tight', `tw-text-${this.textColor}`,
            `tw-my-${this.my}`, `tw-${this.case}`, `tw-font-${this.font}`,
            `tw-text-${this.textSize.default}`, `sm:tw-text-${this.textSize.sm}`].join(' ')
  }

  get level() {
    return this.args.level ?? 1
  }

  get my() {
    return this.args.my ?? 6
  }

  get textSize() {
    if (this.args.kind === 'block') {
      return {
        default: 'sm',
        sm: 'sm'
      }
    }

    const level = this.args.displayLevel || this.level

    return HEADINGS[`h${level}`]
  }

  get font() {
    const font = this.args.kind === 'block' ? 'body' : 'headline'

    return this.args.font ?? font
  }

  get case() {
    return this.args.kind === 'block' ? 'uppercase' : 'normal-case'
  }

  get textColor() {
    let defaultTextColor = this.args.kind === 'block' ? 'ui-black-60' : 'ui-black-100'

    return this.args.textColor ?? defaultTextColor
  }
}
