import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import { closeModal } from 'tag/utils/ui-modal'
import { applyErrors } from 'financeit-components/utils/model-helpers'

export default class CreatePasswordModalComponent extends Component {
  @service store
  @service loadingIndicator

  @tracked showPassword = false
  @tracked isPasswordValid = false
  @tracked label
  @tracked isSubmitting = false

  get model() {
    return this.args.borrower
  }

  get disableSubmit() {
    return !this.isPasswordValid || this.isSubmitting
  }

  onSetPasswordComplete() {
    this.isSubmitting = false
    this.loadingIndicator.hide();
    closeModal(this.args.modalId)
  }

  @action
  toggleShowPassword() {
    this.showPassword = !this.showPassword
  }

  @action
  onValidate(isValid) {
    this.isPasswordValid = isValid
  }

  @action
  onSubmit() {
    this.isSubmitting = true
    const borrowerData = this.model.serialize();

    const data = {
      login_key: this.args.loan.loginKey,
      email: borrowerData.email,
      password: borrowerData.password,
      promotional_email_opted_in: borrowerData.promotional_email_opted_in
    };

    this.loadingIndicator.show();

    return this.store.peekRecord('session', 0).setPassword(data).then(() => {
      this.args.onSuccess?.()
      this.args.session.loggedInUser.needsPassword = false
      return this.onSetPasswordComplete()
    }, errorResponse => {
      const errors = {}
      errorResponse.errors.forEach((error) => {
        errors[error.meta.key] = error.meta.errors
      })

      applyErrors(this.model, errors)

      this.isSubmitting = false
      this.loadingIndicator.hide()
    });
  }
}
