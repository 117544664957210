import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { addClass, removeClass } from 'financeit-components/utils/dom-node-helpers'
import { closeModal } from 'tag/utils/ui-modal'

export default class PaymentScheduleModalComponent extends Component { 
  @service intl

  modalId = 'payment_schedule'

  get modalTitle() {
    let title = this.intl.t('offer.payment_schedule')

    if (!this.args.hideWhatHappensAfterModal) {
      title += ' ' + this.intl.t('offer_card.payment_schedule_term_label', { num_months: this.args.loan.displayTerm })
    }

    return title
  }

  @action
  printTable() {
    addClass('body', 'print-table')
    return window.print()
  }

  @action
  closeModal() {
    removeClass('body', 'print-table')
    closeModal(this.modalId)
  }
}
