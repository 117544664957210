import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'

export default class MobileAppService extends Service {
  @tracked featureFlags = (window.mobileFeatureFlags || {})

  get isAndroidApp() {
    return !!window.AndroidKotlinBridge || !!window.AndroidBridge
  }

  get isIOSApp() {
    return (
      !!window.webkit?.messageHandlers?.iosSwiftBridge ||
      !!window.webkit?.messageHandlers?.iosBridge
    )
  }

  get isMobileApp() {
    return this.isAndroidApp || this.isIOSApp
  }

  get iosVersion() {
    if (!this.isIOSApp) {
      return null
    }

    return $.cookie('ios_app_version')
  }

  get androidVersion() {
    if (!this.isAndroidApp) {
      return null
    }

    return $.cookie('android_version')
  }

  get isHdMobileApp() {
    return (
      this.isIOSApp && this.iosVersion.includes(':home-depot') ||
      this.isAndroidApp && this.androidVersion.includes(':home-depot')
    )
  }

  constructor() {
    super(...arguments)

    // Allows the mobile apps to dispatch an event when they have updated any feature flags, and
    // then Ember will handle them in a reactive manner
    document.addEventListener('mobileFeatureFlagsUpdated', () => {
      this.featureFlags = { ...(window.mobileFeatureFlags || {}) }
    })
  }

  async callBridgeFunction(functionName, ...args) {
    if (!this.isMobileApp) {
      return
    }

    const bridgeInstance = window.fi.lib.MobileBridge.instance()
    return bridgeInstance?.[functionName]?.apply(bridgeInstance, args)
  }
}
