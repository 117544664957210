import Component from '@glimmer/component'
import { closeModal } from 'tag/utils/ui-modal'
import { action } from '@ember/object'

export default class ReportLostStolenCardModalComponent extends Component {
  id = 'report_lost_stolen_card'

  @action
  closeModal() {
    closeModal(this.id)
  }
}
