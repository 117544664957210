import Service from '@ember/service'

export default class TrustevService extends Service {
  src = 'https://cdn-us.trustev.com/trustev.min.js'

  async setup() {
    await this._loadTrustev()
    this._setupTrustev()
  }

  _loadTrustev() {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`head > script[src='${this.src}']`)) {
        return resolve()
      }

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = this.src

      script.addEventListener('load', () => resolve())
      script.addEventListener('error', () => reject(new Error(`${script.src} failed to load.`)))

      document.head.appendChild(script)
    })
  }

  _setupTrustev() {
    const trustevSessionInput = document.getElementById('trustev_session_id')

    if (trustevSessionInput && trustevSessionInput.value === '') {
      window.TrustevV2.Init(window.data.trustev_public_key, sessionId => {
        trustevSessionInput.value = sessionId
      })
    }
  }
}
