import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';

@classic
class LoginRoute extends Route {
  @service router

  // deprecated, for inflight links only
  beforeModel(transition) {
    return this.router.transitionTo('account.login', { queryParams: transition.to.queryParams });
  }
}

export default LoginRoute;
