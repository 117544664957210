import Component from '@glimmer/component'
import { isPresent } from '@ember/utils';
import { formatAsCurrency } from 'tag/utils/number-formatters'

export default class MoneyCellComponent extends Component {
  /*
    See https://onechiporenko.github.io/ember-models-table/v.2/docs/files/addon_utils_column.js.html#l365
    for description of how arguments are passed into this component via column definition
  */
  cellValue = this.args.record[this.args.column.propertyName]
  formattedAmount = isPresent(this.cellValue) ? formatAsCurrency(this.cellValue, { precision: 2 }) : ''
}
