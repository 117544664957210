import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { schedule } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';
import { onActivate, onDeactivate } from '../../../utils/step-transition';
import { dasherize } from '@ember/string';

@classic
class PortalHubLoanStepsRoute extends Route {
  @service
  intl;

  @service store
  @service router

  init() {
    super.init(...arguments);
    this.set('supplemental_steps', ['supplemental_contact_financeit', 'supplemental_contact_partner', 'supplemental_supported_id', 'supplemental_supported_poi']);
    this.on('routeWillChange', this.willTransition.bind(this))
  }

  activate() {
    super.activate(...arguments)
    onActivate()
  }

  deactivate() {
    super.deactivate(...arguments)
    onDeactivate()
  }

  beforeModel() {
    this.controllerFor('portal.hub').set('loading', true);
    const step_name = this.paramsFor('portal.hub.loan-steps').loan_step;
    const currentLoanSteps = this.store.peekAll('loan-step').mapBy('name');
    if (!currentLoanSteps.concat(this.supplemental_steps).includes(step_name)) {
      this.controllerFor('portal.hub').set('loading', false);
      this.router.transitionTo('portal.hub');
    }

    if (!Array.from(this.supplemental_steps).includes(step_name)) {
      const url = `/${this.intl.language}/api/v3/direct/loan_steps/${step_name}`;
      const data = {
        login_key: this.controllerFor('loan').get('model.loginKey')
      };
      // eslint-disable-next-line ember/no-jquery
      return $.ajax({
        type: 'GET',
        url,
        data
      }).then((data) => {
        this.store.pushPayload('loanStep', { loanStep: data.step });
        this.store.pushPayload('loan', { loan: data.loan });
        this.store.pushPayload('borrower', { borrower: data.borrower });
        if (data.coborrower) { return this.store.pushPayload('borrower', { borrower: data.coborrower }); }
      });
    }
  }

  model(params) {
    const step_name = params.loan_step;
    const loan_step = this.controllerFor('loan').get('model.loanSteps').findBy('name', step_name);
    const is_supplemental_step = Array.from(this.supplemental_steps).includes(step_name);
    const step_completed = isPresent(loan_step) && loan_step.get('isCompleted');
    // eslint-disable-next-line ember/no-string-prototype-extensions
    const path_to_template = `direct/templates/${this.loanStepTemplatePath(dasherize(step_name))}`;
    // eslint-disable-next-line no-undef
    const template_exists = Object.keys(require.entries).includes(path_to_template);

    if (isPresent(step_name) && template_exists && (is_supplemental_step || !step_completed)) {
      this.set('templateName', this.loanStepTemplatePath(step_name));
    } else {
      this.router.transitionTo('portal.hub');
    }
    this.controllerFor('portal.hub').set('loading', false);
    return loan_step;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('submitLoanAgreementDocumentClicked', false);

    if (this.controllerFor('partner').get('model.isBigBox')) {
      return schedule('afterRender', this, () => {
        // eslint-disable-next-line no-undef
        if (window.hj) { return hj('trigger', 'checklist_step_poll'); }
      });
    }
  }

  loanStepTemplatePath(templateName) {
    return `portal/hub/loan-steps/${templateName}`;
  }

  @action
  willTransition(transition) {
    // eslint-disable-next-line ember/no-jquery
    if (!this.get('controller.uploadSubmitted') && ($(".filelist .plupload_file_item").length > 0)) {
      const navigationConfirmed = confirm(this.intl.t('application_form.navigation_warn'));

      if (navigationConfirmed) {
        // eslint-disable-next-line ember/no-jquery
        return $(window).off('beforeunload');
      } else {
        transition.abort();
        window.history.forward();
        return this.activate();
      }
    }
  }
}

export default PortalHubLoanStepsRoute;
