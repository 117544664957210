import Component from '@glimmer/component'
import { action } from '@ember/object'
export default class FileListItemComponent extends Component {
  get displayIcon() {
    return ['uploaded', 'timed_out', 'aborted', 'failed', 'uploading'].includes(this.args.state)
  }

  get icon() {
    switch (this.args.state) {
      case 'uploaded':
        return 'check-circle'
      case 'timed_out':
      case 'aborted':
      case 'failed':
        return 'close-circle'
      case 'uploading':
        return 'upload'
      default:
        return ''
    }
  }

  get iconColor() {
    switch (this.args.state) {
      case 'uploaded':
        return 'accent-100'
      case 'timed_out':
      case 'aborted':
      case 'failed':
        return 'error-100'
      default:
        return 'info'
    }
  }

  get isQueued() {
    return this.args.state === 'queued'
  }

  @action
  onRemoveFile() {
    this.args.onRemoveFile?.(this.args.file)
  }
}
