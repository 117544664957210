import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { schedule } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';

@classic
class PortalRoute extends Route {
  @service
  intl;

  @service store
  @service router

  init() {
    super.init(...arguments);
    this.on('routeWillChange', this.willTransition.bind(this))
    this.on('routeDidChange', this.didTransition.bind(this))
  }

  beforeModel(transition) {
    if (navigator.cookieEnabled === false) {
      return this.router.transitionTo('cookies-disabled');
    }

    const loginKey = this.paramsFor('portal').login_key;

    return this.store.peekRecord('session', 0).session({ login_key: loginKey }).then((response, status, EmberError) => {
      if (isPresent(response)) {
        return this.controllerFor('session').setSession(response);
      }
    }, EmberError => {
      const json = JSON.parse(EmberError.responseText);
      return this.router.transitionTo('portal.hub', json.new_login_key);
    });
  }

  afterModel() {
    const { pendo, pendoData } = window;
    if (pendo && pendoData) {
      const loanId = this.controllerFor('loan').get('model.id');
      const loanVertical = this.controllerFor('subvention').get('vertical.name');
      // eslint-disable-next-line ember/no-jquery
      const newPendoData = $.extend(true, {}, pendoData);
      newPendoData.visitor.loanId = loanId;
      newPendoData.visitor.loanVertical = loanVertical;
      newPendoData.visitor.productLine = this.controllerFor('partner').get('model.loanManagingGroupName');
      return pendo.updateOptions(newPendoData);
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (this.controllerFor('partner').get('model.isBigBox')) {
      return schedule('afterRender', this, () => {
        if (window.hj) { return window.hj('trigger', 'startRecording'); }
      });
    }
  }

  redirect(model, transition) {
    if (transition.targetName === 'portal.index') { return this.router.transitionTo('portal.hub'); }
  }

  @action
  didTransition() {
    return this.controller.set('loadingStatus', false);
  }

  @action
  willTransition(transition) {
    this.controller.set('loadingStatus', true);

    if (transition.targetName === "payment-plan.application-form") {
      const navigationConfirmed = confirm(this.intl.t('portal.navigation_warn'));
      if (!navigationConfirmed) {
        transition.abort();
        return window.history.forward();
      }
    }
  }
}

export default PortalRoute;
