import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Route from '@ember/routing/route';

@classic
class PortalHubBankingProcessingRoute extends Route {
  @service
  intl;

  @service store
  @service router

  beforeModel(transition) {
    const loan = this.controllerFor('loan');
    const id = loan.get('model.id');
    const loginKey = loan.get('model.loginKey');

    const source = (transition.to.queryParams.source)
    const borrowerType = transition.to.queryParams.borrowerType;

    this.controllerFor('portal.hub.banking.confirmation').set('borrowerIdVerifiedStatus', loan.get('model.borrowerIdVerified'));
    this.controllerFor('portal.hub.banking.confirmation').set('coborrowerIdVerifiedStatus', loan.get('model.coborrowerIdVerified'));
    this.controllerFor('portal.hub.banking.confirmation').set('borrowerPoiAlreadyCompleted', this._isStepComplete('submit_proof_of_income'));
    this.controllerFor('portal.hub.banking.confirmation').set('coborrowerPoiAlreadyCompleted', this._isStepComplete('submit_proof_of_income_coborrower'));
    this.controllerFor('portal.hub.banking.confirmation').set('setUpPaymentsAlreadyCompleted', this._isStepComplete('set_up_payments'));

    // eslint-disable-next-line ember/no-jquery
    return $.ajax({
      type: 'POST',
      url: `/${this.intl.language}/api/v3/direct/flinks/responses/process_bank_details_response`,
      data: { loan_id: id, login_key: loginKey, source: source }
    }).then((data, status, xhr) => {
      this.store.pushPayload('loan', { loan: data.loan });
      data.loan_steps.forEach(step => {
        return this.store.pushPayload('loanStep', { loanStep: step });
      });

      if (data.loan.flinks_summaries.length >= 1) {
        this.controllerFor('portal.hub.banking.confirmation').set('accountName', data.loan.flinks_summaries[0].name);
      }

      if (isPresent(data.error_description)) {
        this.controllerFor('portal.hub.banking.error').set('bankingErrorTitle', data.error_title);
        this.controllerFor('portal.hub.banking.error').set('bankingErrorDescription', data.error_description);
        this.controllerFor('portal.hub.banking.error').set('bankingErrorType', data.error_type);
        return this.router.transitionTo('portal.hub.banking.error', { queryParams: { sourceFlow: source } });
      } else if (data.loan.flinks_summaries.length < 1) {
        return this.router.transitionTo('portal.hub.banking.none-found', { queryParams: { sourceFlow: source, borrowerType } });
      } else if (source != 'verify_id') {
        return this.router.transitionTo('portal.hub.banking.choose-account', { queryParams: { sourceFlow: source } });
      } else if (loan.get('model.setUpPaymentsCompletedOrInReview')) {
        return this.router.transitionTo('portal.hub.banking.confirmation', { queryParams: { sourceFlow: source} });
      }
    });
  }

  setupController() {
    const loan = this.controllerFor('loan').get('model');
    const borrower = this.controllerFor('borrower').get('model');
    if (isPresent(loan.get('flinksSummaries'))) {
      return borrower.set('chosenBankAccountId', loan.get('flinksSummaries')[0].id);
    }
  }

  _isStepComplete(stepName) {
    const loanSteps = this.store.peekAll('loan-step')
    const step = loanSteps.findBy('name', stepName)
    return step !== undefined && step.get('isCompleted')
  }
}


export default PortalHubBankingProcessingRoute;
