import FormFieldComponent from './ui-form-field'
import { action } from '@ember/object'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'

@uiFormFieldValue
export default class UiTextAreaComponent extends FormFieldComponent {
  get rows() {
    return this.args.rows ?? 3
  }

  @action
  updateValue(event) {
    this.value = event.target.value

    this.args.onInput?.(this.value)
  }
}
