/**
 * @param {string} state
 * @param {*} currentRoute
 * @param {*} loan
 * @param {*} borrower
 * @returns {DefaultRouteTransitioner}
 */
export const buildRouteTransitionerForLoanState = (state, currentRoute, loan, borrower) => {
  const classMap = {
    'prequalified': PrequalifiedDefaultRouteTransitioner,
    'credit_issued': CreditIssuedDefaultRouteTransitioner,
    'approved': ApprovedDefaultRouteTransitioner,
    'high_tdsr': SoftDeclinedDefaultRouteTransitioner,
    'coborrower_mandatory': SoftDeclinedDefaultRouteTransitioner,
    'declined': DeclinedDefaultRouteTransitioner,
    'funded': FundedDefaultRouteTransitioner,
    'aborted': AbortedDefaultRouteTransitioner,
    'msf_funded_but_not_finalized': MsfFundedButNotFinalizedRouteTransitioner,
  }

  if (!classMap[state]) {
    return new EmptyDefaultRouteTransitioner(currentRoute, loan, borrower)
  }

  const TransitionerClass = classMap[state]
  return new TransitionerClass(currentRoute, loan, borrower)
}

class DefaultRouteTransitioner {
  constructor(currentRoute, loan, borrower) {
    this.currentRoute = currentRoute
    this.loan = loan
    this.borrower = borrower
  }

  /**
   * @abstract
   */
  transition() {
    throw new Error('transition method must be implemented')
  }

  /**
   * @private
   * @param {string} routeNameOrUrl
   */
  transitionWithLoginKey(routeNameOrUrl) {
    this.currentRoute.transitionTo(routeNameOrUrl, this.loan.loginKey)
  }

  /**
   * @private
   * @param {any} value
   * @param {Function} transform
   * @returns
   */
  guard(value, transform) {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
  }
}

class EmptyDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    return
  }
}

class PrequalifiedDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    if (this.loan.isCounterOffer) {
      return this.transitionWithLoginKey('portal.offers.offer-approved')
    }

    return this.transitionWithLoginKey('portal.stage-two-loan-application-form')
  }
}

class CreditIssuedDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    return this.transitionWithLoginKey('portal.shopping-pass-dashboard')
  }
}

class ApprovedDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    const onfidoStep = this.guard(this.loan.hasMany('loanSteps').value(), x => x.findBy('name', 'onfido_check'))
    const onfidoStepIsComplete = onfidoStep?.get('isCompleted')

    if (this.loan.isPurchaseDetailsCompleted || onfidoStepIsComplete) {
      return this.transitionWithLoginKey('portal.hub')
    }

    return this.transitionWithLoginKey('portal.offers.offer-approved')
  }
}

class SoftDeclinedDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    return this.transitionWithLoginKey('portal.soft-decline')
  }
}

class DeclinedDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    if (this.loan.isReactivatable) {
      return this.transitionWithLoginKey('portal.soft-decline')
    } else if (!this.borrower.creditFileLocked && !this.loan.isHardPulled && !this.loan.eligibleForSinResubmit && !this.loan.isShoppingPass) {
      return this.transitionWithLoginKey('portal.stage-two-loan-application-form')
    } else if (this.loan.isCreditTopUp) {
      return this.currentRoute.transitionTo('credit-top-up.application-decline')
    } else {
      return this.transitionWithLoginKey('portal.hard-decline')
    }
  }
}

class FundedDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    return this.transitionWithLoginKey('portal.funded')
  }
}

class AbortedDefaultRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    return this.transitionWithLoginKey('portal.aborted')
  }
}

class MsfFundedButNotFinalizedRouteTransitioner extends DefaultRouteTransitioner {
  transition() {
    if (this.loan.loanTranches.findBy('state', 'authorized')?.isMsfFinalTranche && this.loan.loanSteps.findBy('name', 'insurance_offer_decision')?.isNew) {
      return
    }
    return this.transitionWithLoginKey('portal.hub')
  }
}
