import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-incorrect-calls-with-inline-anonymous-functions,
    ember/no-jquery,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { scheduleOnce } from '@ember/runloop';
import PortalController from '../portal';
import { nextRoute } from '../../utils/application-form-flows'
import { showModal, closeModal } from 'tag/utils/ui-modal'
import { applyErrors } from 'financeit-components/utils/model-helpers'

@classic
class PortalHardDeclineController extends PortalController {
  @service
  router;

  queryParams = ['showResubmitSin'];

  @controller
  subvention;

  @service
  intl;

  @service store

  showResubmitSin = false;

  showModal(modalId) {
    showModal(modalId)
  }

  scheduleOpenResubmitSinModal() {
    return scheduleOnce('afterRender', this, function() {
      if (this.showResubmitSin) {
        return this.showModal('add_sin')
      }
    });
  }

  @computed('borrower.binaryDirectFinancingOption', 'partner.binaryDirectFinancingOption')
  get paymentType() {
    return this.intl.t(`hard_decline.contact_partner.${this.get('partner.binaryDirectFinancingOption')}`);
  }

  @computed('partner.directFinancingOptions')
  get financingOptions() {
    const options = this.get('partner.directFinancingOptions');
    return options.map(option => ({
      label: this.intl.t(`financing_option.label.${option}`),
      icon: this.intl.t(`financing_option.icon.${option}`)
    }));
  }

  @computed('partner.directFinancingOptions')
  get hasFinancingOptions() {
    const options = this.get('partner.directFinancingOptions');
    if ((options.length === 1) && (options[0] === "other")) {
      return false;
    } else {
      return options.length > 0;
    }
  }

  @action
  onSubmit() {
    $('.resubmit-sin .ui.dimmer').addClass('active');

    const data = {
      loan: this.loan.serialize(),
      borrower: this.borrower.serialize()
    };

    return $.ajax({
      type: 'PUT',
      url: `/${this.intl.language}/api/v3/direct/loans/${this.get('loan.id')}/resubmit_with_sin`,
      data
    }).then((data) => {
      const { loan, borrower, borrowers, status } = data

      const borrowerArray = Array.isArray(borrowers) ? borrowers : [borrower]
      borrowerArray.forEach(borrower => this.store.pushPayload('borrower', { borrower }))
      this.store.pushPayload('loan', { loan })

      const promo = this.loan.subventionOptions.findBy('id', this.loan.promo)
      this.subvention.set('promo', promo)

      closeModal('add_sin')
      $('.resubmit-sin .ui.dimmer').removeClass('active')

      this.router.transitionTo(nextRoute({ status, loan: this.loan }), this.loan.loginKey)
    }, jqXHR => {
      $('.resubmit-sin .ui.dimmer').removeClass('active');
      const errors = this.store.adapterFor('borrower').ajaxError(jqXHR);
      if (!this.get('borrower.isValid')) { this.borrower.send('becameValid'); }

      applyErrors(this.borrower, errors)
    });
  }

  @action
  openResubmitSinModal() {
    this.showModal('add_sin')
  }
}

export default PortalHardDeclineController;
