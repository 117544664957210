import Model, { attr } from "@ember-data/model";
import { memberAction } from "ember-api-actions";

export default class Payment extends Model {
  @attr("string") fullName;
  @attr("string") postalCode;
  @attr("number") amount;
  @attr("number") nextPaymentDue;
}

Payment.prototype.borrowerMakePayment = memberAction({
  path: "",
  type: "post",
});

Payment.prototype.previewMakePayment = memberAction({
  path: "preview",
  type: "post",
});

Payment.prototype.validatePaymentAmount = memberAction({
  path: "validate_payment_amount",
  type: "get",
});
