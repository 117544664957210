import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-incorrect-calls-with-inline-anonymous-functions,
    ember/no-jquery,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { scheduleOnce } from '@ember/runloop';
import PortalController from '../../portal';
import { showModal, closeModal } from 'tag/utils/ui-modal'
import { applyErrors } from 'financeit-components/utils/model-helpers'

@classic
class PortalSoftDeclineController extends PortalController {
  @controller('services/paymentCalculator')
  calculator;

  @controller
  subvention;

  @controller
  session;

  @service
  intl;

  @service store

  verifyIncomeModalShown = false;

  showVerifyIncomeModal() {
    if (this.get('loan.hasChangedHousingAndIncome') || this.isCoborrowerMandatory || this.verifyIncomeModalShown) {
      return;
    }

    return scheduleOnce('afterRender', this, function() {
      this.set('verifyIncomeModalShown', true);
      return showModal('verify_income')
    });
  }

  @computed('session.activeLoansCount')
  get multipleActiveLoans() {
    return (this.get('session.activeLoansCount') > 1)
  }

  @computed('loan.{state,isReactivatable}')
  get isCoborrowerMandatory() {
    return (this.get('loan.state') === 'coborrower_mandatory' || this.get('loan.isReactivatable'))
  }

  @action
  onSubmit() {
    $('.verify-income .ui.dimmer').addClass('active');

    const data = {
      loan: this.loan.serialize(),
      borrower: this.borrower.serialize()
    };

    return $.ajax({
      type: 'PUT',
      url: `/${this.intl.language}/api/v3/direct/loans/${this.get('loan.id')}/adjust_income_and_housing`,
      data
    }).then((data) => {
      this.store.pushPayload('loan', { loan: data.loan });
      this.set('loan', this.store.peekRecord('loan', data.loan.id));

      if (data.status) {
        this.store.push(this.store.normalize('borrower', data.borrower));

        if (data.status === 'hard-decline') {
          closeModal('verify_income')
          return this.transitionToRoute('portal.hard-decline', this.get('loan.loginKey'));
        } else {
          $('.verify-income .ui.dimmer').removeClass('active');
          return closeModal('verify_income')
        }
      } else {
        this.store.push(this.store.normalize('borrower', data.borrowers[0]));
        this.set('borrower', this.store.peekRecord('borrower', data.borrowers[0].id));

        closeModal('verify_income')
        return this.transitionToRoute('portal.offers.offer-approved', this.get('loan.loginKey'));
      }
    }, jqXHR => {
      $('.verify-income .ui.dimmer').removeClass('active');

      const errors = this.store.adapterFor('borrower').ajaxError(jqXHR);
      if (!this.get('borrower.isValid')) { this.borrower.send('becameValid'); }

      applyErrors(this.borrower, errors)
    });
  }

  @action
  reduceRequestedAmount() {
    if (this.get('loan.canReduceRequestedAmount')) {
      $('.reduce-requested-amount .ui.dimmer').addClass('active');

      const data = { loan: this.loan.serialize() };

      data['loan']['requested_amount'] = this.get('calculator.promoMaxRequestedAmount');

      return $.ajax({
        type: 'PUT',
        url: `/${this.intl.language}/api/v3/direct/loans/${this.get('loan.id')}/reduce_requested_amount`,
        data
      }).then((data) => {
        this.store.pushPayload('loan', data);

        return this.transitionToRoute('portal.offers.offer-approved', this.get('loan.loginKey'));
      }, jqXHR => {
        $('.reduce-requested-amount .ui.dimmer').removeClass('active');
        const errors = this.store.adapterFor('loan').ajaxError(jqXHR);
        this.loan.send('becomeDirty');

        applyErrors(this.loan, errors);
      });
    }
  }

  @action
  showCoborrowerForm() {
    this.transitionToRoute('portal.soft-decline.add-coborrower', this.get('loan.loginKey'));
  }

  @action
  openVerifyIncomeModal() {
    showModal('verify_income')
  }
}

export default PortalSoftDeclineController;
