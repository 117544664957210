import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import PortalHubController from '../hub';

@classic
class PortalHubBankingController extends PortalHubController {
  @controller('portal.hub.loan-steps')
  loanStepsController;

  bothIdsNotVerified() {
    return !this.get('loan.borrowerIdVerified') && this.get('loan.coborrowerStepExistsAndNotCompleted');
  }

  borrowerQualifiedForBanking() {
    return this.get('loan.borrower.canVerifyIdViaBanking');
  }

  coborrowerQualifiedForBanking() {
    if (!this.get('loan.hasCoborrower')) { return true; }
    return this.get('loan.coborrower.canVerifyIdViaBanking');
  }

  @computed('borrowerQualifiedForBanking', 'coborrowerQualifiedForBanking', 'loan.{borrower,coborrower}')
  get idvStepBorrower() {
    if (this.borrowerQualifiedForBanking() && this.coborrowerQualifiedForBanking()) {
      return this.get('loan.borrower');
    } else if (!this.coborrowerQualifiedForBanking()) {
      return this.get('loan.coborrower');
    } else {
      return this.get('loan.borrower');
    }
  }

  @computed('idvStepBorrower', 'loan.{borrower.id,coborrower.id}', 'model')
  get currentStepBorrowerId() {
    if (this.idvStepBorrower === this.get('loan.borrower')) {
      return {
        id: this.get('loan.borrower.id'),
        key: "borrower"
      };
    } else {
      return {
        id: this.get('loan.coborrower.id'),
        key: "coborrower"
      };
    }
  }

  namesForVerifyID() {
    if (this.borrowerQualifiedForBanking() && this.coborrowerQualifiedForBanking() && this.bothIdsNotVerified()) {
      this.get('loan.approvedBorrowersFullNamesSeparatedByOr');
    } else if (this.get('loan.coborrowerStepExistsAndNotCompleted') && this.coborrowerQualifiedForBanking()) {
      return this.get('loan.coborrower.fullName');
    } else {
      return this.get('loan.borrower.fullName');
    }
  }

  @computed('loan.approvedBorrowersFullNamesSeparatedByOr', 'verifyIdFlow')
  get namesForMessage() {
    if (this.verifyIdFlow) {
      return this.namesForVerifyID();
    } else {
      return this.get('loan.approvedBorrowersFullNamesSeparatedByOr');
    }
  }
}

export default PortalHubBankingController;
