import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
/* eslint-disable
    no-undef,
*/

import PortalController from '../../portal';

@classic
class PortalPaymentOptionsChangeController extends PortalController {
  @computed('loan.formattedFrequency', 'model.changeset.changeFrequency')
  get selectedFrequency() {
    const { changeset } = this.model
    let newFrequency = changeset.get('changeFrequency') == null ? this.get('loan.formattedFrequency') : changeset.get('changeFrequency')
    return newFrequency
  }

  @computed(
    'selectedFrequency',
    'loan.{formattedFrequency,maximumPaymentDateWeekly,maximumPaymentDateBiweekly,maximumPaymentDateMonthly}'
  )
  get maximumPaymentDate() {
    let frequency = this.selectedFrequency
    if (frequency === 'weekly') {
      return this.get('loan.maximumPaymentDateWeekly')
    } else if (frequency === 'biweekly'){
      return this.get('loan.maximumPaymentDateBiweekly')
    } else {
      return this.get('loan.maximumPaymentDateMonthly')
    }
  }

  @computed(
    'intl', 'loan.{formattedFrequency,maximumPaymentDateBiweekly,maximumPaymentDateMonthly,maximumPaymentDateWeekly,nextScheduledPayment.date}', 'selectedFrequency'
  )
  get nextPaymentChangeDate() {
    let next_scheduled_payment = this.get('loan.nextScheduledPayment.date')
    let frequency = this.selectedFrequency
    if (frequency === 'weekly') {
      return this.intl.t('payment_options.eligible_date_payment_frequency_weekly', { next_payment_date: next_scheduled_payment });
    } else if (frequency === 'biweekly'){
      return this.intl.t('payment_options.eligible_date_payment_frequency_biweekly', { next_payment_date: next_scheduled_payment });
    }
    return '';
  }

  @computed('loan.minPaymentDate', 'maximumPaymentDate')
  get errorPaymentFrequencyChangeLocked() {
    let noDatesAvailable = this.maximumPaymentDate < this.get('loan.minPaymentDate')
    return noDatesAvailable
  }

  @action
  reviewPaymentOptionsClick() {
    return this.transitionToRoute('portal.payment-options.review.index');
  }
}

export default PortalPaymentOptionsChangeController;
