import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import PortalController from '../../portal';
import secureFetch from '../../../utils/secure-fetch';

@classic
class PortalBankingChooseAccountController extends PortalController {
  @service
  intl;

  @alias('borrowerController.model')
  borrower;

  @service store

  showSpinner = false;
  showProgressBar = true;
  shouldCompleteProgressBar = false;
  showCancelText = false;

  @controller('portal.banking.error')
  errorController;

  get language() {
    return this.intl.language
  }

  @computed('loan.flinksSummaries.firstObject')
  get accountName() {
    const account = this.get('loan.flinksSummaries.firstObject');
    return account.name;
  }

  @computed('accountName')
  get afterNameMatch() {
    return isPresent(this.accountName);
  }

  @computed('loan.flinksSummaries')
  get multipleFlinksSummaries() {
    return this.get('loan.flinksSummaries').length > 1
  }

  @computed('loan.isShoppingPass')
  get processingLabels() {
    // In the processing screen, the UI changes whether we have one account returned or many accounts returned.

    const descriptionKey = this.get('loan.isShoppingPass') ? 'description_hd' : 'description';
    return {
      description: this.intl.t(`hub.banking_processing.${descriptionKey}`),
      submit: this.intl.t('hub.banking_processing.confirm_account'),
    };
  }

  async updateBankAccountData() {
    return secureFetch(`/${this.language}/api/v3/direct/bank_accounts/change_account`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          account_id: this.get('borrower.chosenBankAccountId'),
          loan_id: this.get('loan.id'),
          borrower_id: this.get('session.loggedInUser.id')
        })
      })
      .then(res => res.json())
      .catch(() => {
        return {
          loan: null,
          error: true
        }
      });
    }

  async sendCancelRequest(responseId) {
    return secureFetch(`/${this.language}/api/v3/direct/flinks/responses/${responseId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(res => res.json())
      .catch(() => {
        return {
          loan: null,
          step: null
        }
      });
  }

  continueSubmit(data) {
    this.store.pushPayload('loan', { loan: data.loan });
    if (isPresent(data.error_description)) {
      this.errorController.set('bankingErrorTitle', data.error_title);
      this.errorController.set('bankingErrorDescription', data.error_description);
      this.transitionToRoute('portal.banking.error');
    } else if (isPresent(data.error)) {
      this.transitionToRoute('portal.banking.error');
    } else {
      this.transitionToRoute('portal.banking.confirmation');
    }
    this.set('shouldCompleteProgressBar', true);
    this.set('showSpinner', false);
  }

  @action
  async onSubmit() {
    this.set('showSpinner', true);
    this.set('showProgressBar', true);
    const bankAccountData = await this.updateBankAccountData()
    await this.continueSubmit(bankAccountData)
  }

  @action
  async cancel() {
    this.set('showCancelText', true)
    this.set('showProgressBar',false)
    this.set('showSpinner', true);
    const responseId = this.get('loan.currentFlinksResponse.id');
    const data = await this.sendCancelRequest(responseId)
    this.store.pushPayload('loan', { loan: data.loan });
    this.transitionToRoute('portal.funded', this.get('loan.loginKey'));
    this.set('showSpinner', false);
    this.set('showCancelText', false)
  }
}

export default PortalBankingChooseAccountController;
