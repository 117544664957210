import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import PortalController from '../../../portal';

@classic
class PortalPaymentReviewConfirmationController extends PortalController {
  @computed('loan.lastPayment.{paid_amount,principal_paid,interest_paid}')
  get towardLateFees() {
    return (this.get('loan.lastPayment.paid_amount') - this.get('loan.lastPayment.principal_paid') - this.get('loan.lastPayment.interest_paid'))
  }

  @action
  doneMakePaymentClick() {
    return this.transitionToRoute('portal.funded');
  }
}

export default PortalPaymentReviewConfirmationController;
