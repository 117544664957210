import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import PortalHubController from '../hub';

@classic
export default class AlternativePoiController extends PortalHubController {
  @service
  intl;

  @action
  useNewPoiAmount() {
    const url = `/${this.intl.language}/api/v3/direct/loans/${this.get('loan.id')}/toggle_poi`;
    // eslint-disable-next-line ember/no-jquery
    $.ajax({
      type: 'POST',
      url
    }).then(() => {
      return this.transitionToRoute('offers.offer-approved', this.get('loan.loginKey'));
    })
    return false;
  }
}
