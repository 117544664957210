import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class ContactFinanceitModalComponent extends Component {
  @service intl

  id = 'contact_financeit_information'

  get title() {
    if (this.args.partner.isBigBox) {
      return this.intl.t('hub.contact_partner_modal.contact_details_shopping_pass')
    }

    return this.intl.t('hub.contact_partner_modal.contact_financeit')
  }

  @action
  closeModal() {
    closeModal(this.id)
  }
}
