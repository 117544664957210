import classic from 'ember-classic-decorator';
import { readOnly } from '@ember/object/computed';
import Controller, { inject } from '@ember/controller';

@classic
class InviteExpiredController extends Controller {
  @inject('partner')
  partnerController;

  @readOnly('partnerController.model')
  partner;
}

export default InviteExpiredController;
