import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { gt, alias } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import Controller, { inject as controller } from '@ember/controller';
const Big = window.Big

@classic
class PaymentCalculatorController extends Controller {
  @controller
  subvention;

  @controller('loan')
  loanController;

  @alias('loanController.model')
  loan;

  /*
  A wrapper with `big.js` library  around the
  requested amount

  @property requestedAmount
  */
  @computed('loan.requestedAmount')
  get requestedAmount() {

    let rA;
    if (isPresent(this.get('loan.requestedAmount'))) {
      rA = this.get('loan.requestedAmount');
    } else {
      rA = 0;
    }

    return new Big(rA);
  }

  /*
  Maps the API's serialized payment frequency to one that
  dependencies would understand

  @property paymentFrequency
  */
  @computed('loan.paymentFrequency')
  get paymentFrequency() {
    const pF = this.get('loan.paymentFrequency');
    switch (pF) {
      case 'm':
        return 'monthly';
      case 'f':
        return 'biweekly';
      case 'fr':
        return 'biweekly_rapid';
      case 'w':
        return 'weekly';
      case 'wr':
        return 'weekly_rapid';
      case 'sm':
        return 'semi_monthly';
      default:
        return '';
    }
  }

  /*
  Normalizes the value of amortization

  @property amortization
  */
  @computed('loan.amortization')
  get amortization() {
    return parseInt(this.get('loan.amortization'));
  }

  @computed('loan.paymentForRequestedRate')
  get paymentForRequestedRate() {
    return this.get('loan.paymentForRequestedRate');
  }

  @computed('paymentForRequestedRate')
  get displayPaymentForRequestedRate() {
    return this.paymentForRequestedRate.toFixed(2);
  }

  @computed('loan.{calculatedPricipalAmount,calculatedPrincipalAmount}')
  get principalAmount() {
    return Big(this.get('loan.calculatedPrincipalAmount'))
  }

  /*
  Normalized loan term that interest is actually accrued on

  @property numPayments
  */
  @computed('amortization', 'deferralPeriod')
  get numPayments() {
    return parseInt(this.amortization) - this.deferralPeriod;
  }

  @computed('selectedSubvention.amortization_offset')
  get deferralPeriod() {
    return this.get('selectedSubvention.amortization_offset');
  }

  @computed('selectedSubvention.subvention_duration')
  get subventionDuration() {
    return this.get('selectedSubvention.subvention_duration');
  }

  @computed('loan.{subventionOptions,subventionProgramId}')
  get selectedSubvention() {
    return this.get('loan.subventionOptions').findBy('id', this.get('loan.subventionProgramId'));
  }

  @computed(
    'isRateDiscount', 'nonSubventedRate', 'selectedSubvention.rate', 'useSubventionRate'
  )
  get rate() {
    if (this.useSubventionRate) {
      let rate;
      if (this.isRateDiscount) {
        rate = this.nonSubventedRate - new Big(this.get('selectedSubvention.rate'));
      } else {
        rate = this.get('selectedSubvention.rate');
      }
      return new Big(rate);
    } else {
      return this.nonSubventedRate;
    }
  }

  @computed('loan.nonSubventedRate')
  get nonSubventedRate() {
    return new Big(this.get('loan.nonSubventedRate')).div(100);
  }

  @computed(
    'loan.{financeitPrimeDelta,financeitPrimeRate,fundedOrPartiallyFunded,requestedRate}', 'nonSubventedRateAsNumber',
  )
  get financeitPrimeDelta() {
    if (this.get('loan.fundedOrPartiallyFunded') && isPresent(this.get('loan.financeitPrimeDelta'))) {
      return this.get('loan.financeitPrimeDelta');
    } else {
      return (parseFloat(this.nonSubventedRateAsNumber) - this.get('loan.requestedRate')) + this.get('loan.financeitPrimeDelta');
    }
  }

  @computed('financeitPrimeDelta', 'loan.financeitPrimeRate')
  get renewalRate() {
    return this.get('loan.financeitPrimeRate') + this.financeitPrimeDelta;
  }

  @computed('loan.displayGrade')
  get displayGrade() {
    return this.get('loan.displayGrade');
  }

  @computed('selectedSubvention.type')
  get useSubventionRate() {
    let needle;
    return needle = this.selectedSubvention.type, ["InterestRateBuyDown", "SameAsCash", "RateDiscount"].includes(needle);
  }

  @computed('selectedSubvention.type')
  get isRateDiscount() {
    return this.selectedSubvention.type === "RateDiscount";
  }

  /*
  Rate as a percentage instead of a fraction. This should be
  refactored out as a handlebars helper if it is only used
  in templates for display purposes.

  @property nonSubventedRateAsNumber
  */
  @computed('loan.{fundedOrPartiallyFunded,rate}', 'nonSubventedRate')
  get nonSubventedRateAsNumber() {
    if (this.get('loan.fundedOrPartiallyFunded')) {
      return this.get('loan.rate');
    } else {
      return this.nonSubventedRate.times(100).toFixed(2);
    }
  }

  /*
  Rate as a percentage instead of a fraction. This should be
  refactored out as a handlebars helper if it is only used
  in templates for display purposes.

  @property rateAsNumber
  */
  @computed(
    'loan.{currentRate,fundedOrPartiallyFunded,isPurchaseDetailsCompleted,requestedRate}', 'rate',
  )
  get rateAsNumber() {
    if (this.get('loan.fundedOrPartiallyFunded') || this.get('loan.isPurchaseDetailsCompleted')) {
      return this.get('loan.currentRate');
    } else {
      return parseFloat(this.rate.times(100).toFixed(2));
    }
  }

  @computed('loan.limitMinRequestedAmount')
  get globalMinRequestedAmount() {
    return this.get('loan.limitMinRequestedAmount');
  }

  @computed('loan.maxAmountToShow')
  get globalMaxRequestedAmount() {
    return new Big(this.get('loan.maxAmountToShow') || 0);
  }

  /*
  Specific calculation to display the leading subvention's max requested
  amount, not affected by the calculator's state.

  @property promoMaxRequestedAmount
  */
  @computed('loan.{subventionOptions,promo}')
  get promoMaxRequestedAmount() {
    const options = this.get('loan.subventionOptions');
    const subvention = options.findBy('id', this.get('loan.promo'));
    if (isPresent(subvention)) {
      return subvention.max_requested_amount;
    } else {
      return 0;
    }
  }

  @computed('loan.carOrFirstVehicleType')
  get carOrFirstVehicleType() {
    return this.get('loan.carOrFirstVehicleType') || "Car";
  }

  borrowerSubventionFeeFor(sub, fC, rA, bSP) {
    if (sub.id === 0) {
      return new Big(0);
    } else {
      const cost = new Big(fC).div(100).times(rA);
      return cost.times(bSP).times(0.01);
    }
  }

  maxAmortizationFor(subvention) {
    const verticalLimits = this.subvention.vertical.amort_limits;
    const newVerticalLimits = verticalLimits || [];

    const amorts = subvention.amortizations;
    const maxAmort = amorts[amorts.length - 1] - subvention.amortization_offset;

    if (newVerticalLimits.length < 1) {
      return maxAmort;
    }

    const limits = newVerticalLimits.map(limit => limit.years * 12).sort((a,b) => a - b);
    const verticalMax = limits[limits.length - 1];
    return Math.min(maxAmort, verticalMax);
  }

  minAmortizationFor(subvention) {
    let a;
    const as = subvention.amortizations;
    return a = as[0];
  }

  principalFeesWithoutBorrowerSubventionFeeFor(f, pIC, fTS) {
    return f.plus(pIC).plus(fTS);
  }
}

export default PaymentCalculatorController;
