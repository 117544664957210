import Service, { inject as service } from '@ember/service'

import UiFileQueue from '../ui-file-queue'

export default class UiFileQueueService extends Service {
  @service FileQueue
  fileQueues = new Map()

  find(name) {
    return this.fileQueues.get(name)
  }

  create(name, url) {
    if (!this.fileQueues.has(name)) {
      const fileQueue = this.FileQueue.find(name) ?? this.FileQueue.create(name)
      this.fileQueues.set(name, new UiFileQueue(name, url, fileQueue))
    }

    return this.find(name)
  }

  uploadAll(name) {
    const queue = this.find(name)

    if (!queue) {
      return Promise.reject(`No UiFileQueue found with name "${name}"`)
    }

    return queue.uploadAll()
  }
}
