import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class EConsentModalComponent extends Component {
  closeModal() {
    closeModal('e-consent-modal')
  }

  @action
  onAcceptEConsent() {
    this.closeModal()
    this.args.onAcceptEConsent?.()
  }

  @action
  onCloseEConsent() {
    this.closeModal()
    this.args.onCloseEConsent?.()
  }
}
