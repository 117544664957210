export const nextRoute = ({ status, loan }) => {
  return loan.isHardPulled ? postHardPullRoute({ status }) : preHardPullRoute({ status, loan })
}

const postHardPullRoute = ({ status }) => {
  const transitionMap = {
    'soft-decline': 'portal.soft-decline',
    'hard-decline': 'portal.hard-decline',
  }

  if (transitionMap[status]) {
    return transitionMap[status]
  }

  return 'portal.offers.offer-approved'
}

const preHardPullRoute = ({ status, loan }) => {
  const transitionMap = {
    'soft-decline': 'portal.soft-decline',
    'hard-decline': 'portal.stage-two-loan-application-form',
  }

  if (transitionMap[status]) {
    return transitionMap[status]
  }

  if (loan.isCounterOffer) {
    return 'portal.offers.offer-approved'
  }

  return 'portal.stage-two-loan-application-form'
}
