import Component from '@glimmer/component'
import {inject as service} from '@ember/service'

export default class RequestedFundsTableComponent extends Component {
  @service intl

  columns = [
    {
      title: this.intl.t('hub.transactions.date'),
      propertyName: 'date',
      disableSorting: true,
      component: 'ui-table-array/cells/date',
      columnHeaderClassNames: 'tw-text-xs',
      className: 'sm:tw-w-1/6 tw-w-1/3 tw-whitespace-no-wrap',
      componentProperties: {
        textSize: 'sm'
      }
    },
    {
      title: this.intl.t('hub.transactions.status'),
      propertyName: 'status',
      disableSorting: true,
      component: 'ui-table-array/cells/badge',
      columnHeaderClassNames: 'tw-text-xs tw-ml-4',
      className: 'sm:tw-w-1/6 tw-w-1/3 tw-whitespace-no-wrap'
    },
    {
      title: this.intl.t('hub.transactions.description'),
      propertyName: 'description',
      disableSorting: true,
      columnHeaderClassNames: 'tw-text-xs',
      className: 'sm:tw-table-cell tw-hidden',
      componentProperties: {
        textSize: 'xs',
      }
    },
    {
      title: this.intl.t('hub.transactions.amount'),
      propertyName: 'amount',
      disableSorting: true,
      component: 'ui-table-array/cells/money',
      columnHeaderClassNames: 'tw-text-xs tw-justify-end',
      className: 'sm:tw-w-1/6 tw-w-1/3 tw-whitespace-no-wrap tw-text-right'
    }
  ]
}
