import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button {{on \"click\" @onClick}} type=\"button\" class=\"{{this.defaultClasses}} {{this.outerClasses}} {{this.borderSize}} {{this.cssClasses}}\">\n  <OptionCard::OptionCard @loanName={{@loanName}} @loanSelectionHeading = {{@loanSelectionHeading}}/>\n</button>", {"contents":"<button {{on \"click\" @onClick}} type=\"button\" class=\"{{this.defaultClasses}} {{this.outerClasses}} {{this.borderSize}} {{this.cssClasses}}\">\n  <OptionCard::OptionCard @loanName={{@loanName}} @loanSelectionHeading = {{@loanSelectionHeading}}/>\n</button>","moduleName":"direct/components/option-card/button.hbs","parseOptions":{"srcName":"direct/components/option-card/button.hbs"}});
import Component from '@glimmer/component'

export default class ButtonComponent extends Component {
  get defaultClasses() {
    return ['tw-rounded', 'tw-px-2', 'tw-text-left'].join(' ')
  }

  get borderSize() {
    return 'tw-border-2'
  }

  get cssClasses() {
    const classes = []

    classes.push(this.specialPromo ? 'tw-py-1' : 'tw-py-4')

    if (this.args.isSelectedOption) {
      classes.push('tw-outline-none', 'tw-shadow-none', 'focus:tw-shadow-none', 'tw-border-interactive-100')
    } else {
      classes.push('tw-border-white')
    }

    return classes.join(' ')
  }

  get outerClasses() {
    return ['tw-w-full', 'tw-shadow-md', 'hover:tw-shadow-lg', 'focus:tw-outline-none', 'focus:tw-border-accent-100', 'focus:tw-shadow-lg', 'tw-transition-shadow', 'tw-duration-150', 'tw-ease-linear'].join(' ')
  }
}
