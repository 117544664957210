import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class DeclinedCoboModalComponent extends Component {
  @action
  closeModal() {
    closeModal('declined_cobo')
  }
}
