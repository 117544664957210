import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { formatAsCurrency } from 'tag/utils/number-formatters'
import { htmlSafe } from '@ember/template';

@classic
class LoanController extends Controller {
  @service
  intl;

  @controller
  subvention;

  @controller('partner')
  partnerController;

  @readOnly('partnerController.model')
  partner;

  @controller('borrower')
  borrowerController;

  @readOnly('borrowerController.model')
  borrower;

  @computed('model.approvalNoAmount')
  get hasPurchaseAmount() {
    return this.get('model.approvalNoAmount') === false;
  }

  @computed('model.state')
  get hideOfferExpiry() {
    return (this.get('model.state') === "funded") || (this.get('model.state') === "declined");
  }

  @computed(
    'borrower.{firstName,lastName}', 'model.{expiryDate,hovAmount,id,maxLtvPercentage,poiThreshold}', 'partner.businessName', 'subvention.isVehicle'
  )
  get fundingConditionVariables() {
    return {
      borrower_name: this.get('borrower.firstName') + ' ' + this.get('borrower.lastName'),
      partner_name: this.get('partner.businessName'),
      loan_id: this.get('model.id'),
      hours: this.intl.t('financeit.business_hours_short'),
      cheque: this.intl.t('approval_conditions.vocab.cheque'),
      invoice_required_vehicle: this.get('subvention.isVehicle') ? this.intl.t('approval_conditions.invoice_required_vehicle') : '',
      // eslint-disable-next-line no-undef
      expiry_date: moment(this.get('model.expiryDate')).format(this.intl.t('time.formats.date_only_full_month')),
      ltv_percentage: `${this.get('model.maxLtvPercentage')}%`,
      qf_amount: "$" + this.get('model.poiThreshold'),
      hov_amount: formatAsCurrency(this.get('model.hovAmount'), { precision: 0 })
    };
  }

  @computed('fundingConditionVariables', 'model.approvalConditions')
  get fundingConditions() {
    return this.get('model.approvalConditions').map(condition => {
      return this.intl.t(`approval_conditions.${condition}`, { ...this.fundingConditionVariables, htmlSafe: true }).toString();
    });
  }

  @computed('fundingConditionVariables', 'model.approvalConditions', 'subvention.isEqualPay')
  get filteredFundingConditions() {
    return this.get('model.approvalConditions').filter(condition => {
      return !['proof_of_income', 'coborrower_mandatory'].includes(condition);
    }).map(condition => {
      let condition_string = this.intl.t(`approval_conditions.${condition}`, { ...this.fundingConditionVariables, htmlSafe: true }).toString();

      if ((condition === 'void_cheque_required') && !this.get('subvention.isEqualPay')) {
        condition_string = condition_string + (`<p class='legal-text'>${this.intl.t("loan_app.approval.void_cheque_explanation")}</p>`);
      }

      return htmlSafe(condition_string);
    });
  }
}

export default LoanController;
