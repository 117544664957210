import Component from '@glimmer/component';

export default class EsignConsentComponent extends Component {
  get f() {
    return this.args.f
  }
  
  get needsEsignConsent() {
    return !!this.args.needsEsignConsent
  }
}
