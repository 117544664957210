import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class LearnMoreModalComponent extends Component {
  @action
  closeModal() {
    closeModal('learn-more-modal')
  }

  @action
  collapseAccordionLearnMore() {
    // eslint-disable-next-line ember/no-jquery
    return $('#learn_more_faq').find('.collapse.in').collapse('hide');
  }
}
