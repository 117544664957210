import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
/* eslint-disable
    ember/no-jquery,
    no-undef
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { next } from '@ember/runloop';
import PortalOffersController from '../offers';

@classic
class PortalOffersContactController extends PortalOffersController {
  @action
  prevSlide() {
    this.send('slideUp');
    if (this.get('loanController.hasPurchaseAmount')) {
      return this.transitionToRoute('portal.offers.offer-approved', this.get('loan.loginKey'));
    } else {
      return this.transitionToRoute('portal.offers.offer', this.get('loan.loginKey'));
    }
  }

  @action
  initializeBusinessHours() {
    return next(this, () => {
      return $('#business_hours').popover({
        html: true,
        placement: 'bottom',
        trigger: 'click',
        content() {
          return $('#business_hours_content').html();
        }
      });
    });
  }
}

export default PortalOffersContactController;
