import Component from '@glimmer/component'
import { inject as service } from '@ember/service';

export default class DateCellComponent extends Component {
  @service intl;

  /*
    See https://onechiporenko.github.io/ember-models-table/v.2/docs/files/addon_utils_column.js.html#l365
    for description of how arguments are passed into this component via column definition
  */
  cellValue = this.args.record[this.args.column.propertyName]
  // eslint-disable-next-line no-undef
  formattedDate = moment(this.cellValue).format(this.intl.t('date.formats.date_only'))
}
