import Service from '@ember/service'

export default class HomeDepotAnalytics extends Service {
  trackStageOnePageView() {
    if (this._fbq) {
      this._fbq('trackSingle', this._fbqStageOnePixelId, 'PageView')
    }
  }

  trackStageOneLoanEvent(loanId, eventName) {
    this._trackFbqLoanEvent(loanId, eventName, this._fbqStageOnePixelId)
    this._trackGtagLoanEvent(loanId, eventName, this._gtagStageOneSendTo)
  }

  trackStageTwoLoanEvent(loanId, eventName) {
    this._trackFbqLoanEvent(loanId, eventName, this._fbqStageTwoPixelId)
    this._trackGtagLoanEvent(loanId, eventName, this._gtagStageTwoSendTo)
  }

  get applicationSubmittedEventName() {
    return 'ApplicationSubmitted'
  }

  get applicationApprovedEventName() {
    return 'ApplicationApproved'
  }

  get applicationDeclinedEventName() {
    return 'ApplicationDeclined'
  }

  _trackFbqLoanEvent(loanId, eventName, pixelId) {
    if (this._fbq) {
      this._fbq('trackSingleCustom', pixelId, eventName, { reference: loanId })
    }
  }

  _trackGtagLoanEvent(loanId, eventName, sendTo) {
    if (this._gtag) {
      this._gtag('event', 'conversion', { 'allow_custom_scripts': true, 'u5': loanId, 'u18': eventName,
                                          'send_to': sendTo })
    }
  }

  get _fbq() {
    return window.fbq
  }

  get _gtag() {
    return window.gtag
  }

  get _fbqStageOnePixelId() {
    return '1120010781744337'
  }

  get _fbqStageTwoPixelId() {
    return '5972910716061572'
  }

  get _gtagStageOneSendTo() {
    return 'DC-5049520/ext/finan0+unique'
  }

  get _gtagStageTwoSendTo() {
    return 'DC-5049520/ext/finan00+unique'
  }
}
