import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import PortalProfileIndexController from '../profile';
import { inject as service } from '@ember/service';

@classic
export default class ChangePasswordController extends PortalProfileIndexController {
  @controller('session')
  session;

  @service store

  showPasswordForCurrent = false;
  showPasswordForConfirm = false;

  @action
  toggleShowPasswordForCurrent() {
    this.toggleProperty('showPasswordForCurrent');
  }

  @action
  toggleShowPasswordForConfirm() {
    this.toggleProperty('showPasswordForConfirm');
  }

  @action
  async onSubmit() {
    try {
      const { borrower, changeset } = this.model;

      await borrower.updatePassword({
        new_password: changeset.get('password'),
        old_password: changeset.get('currentPassword'),
        confirm_password: changeset.get('confirmPassword'),
      });
      window.scrollTo(0, 0);
      this.transitionToRoute("portal.profile", { queryParams: { saveSucceeded: true } });
    } catch (e) {
      const { changeset, loan } = this.model
      this.pushErrorMessages(changeset, e.errors);

      if(e.errors.firstObject.status === "401") {
        let loginRoute;
        if (loan.get('isShoppingPass')) {
          loginRoute = 'account.hdlogin';
        } else {
          loginRoute = 'account.login';
        }
        this.set('session.loggedIn', false);
        this.store.unloadAll();
        return this.transitionToRoute(loginRoute);
      }
    }
  }
}
