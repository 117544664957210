import Component from '@glimmer/component'
export default class UiIcon extends Component {
  // Update iconSize to fixed sizes (small, default, large, xl)
  // For now default default sizes are:
  // Small: 4
  // Default: 6
  get iconSize() {
    return this.args.iconSize ?? 6
  }

  get iconColor() {
    if (this.args.disabled) {
      return 'ui-gray-100'
    }
    if (this.args.iconColor) {
      return this.args.iconColor
    }
    return 'interactive-100'
  }
}
