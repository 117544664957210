import Component from '@glimmer/component'
import { isNone } from '@ember/utils'

export default class UiPanelComponent extends Component {
  get bgColor() {
    if (this.args.kind === 'background') {
      return 'ui-gray-40'
    }

    return 'white'
  }

  get borderColor() {
    const color = this.args.borderColor ?? 'ui-gray-60'

    return `tw-border-${color}`
  }

  get padding() {
    if (isNone(this.args.p)) {
      return '6'
    }

    return String(this.args.p)
  }

  get pt() {
    if (isNone(this.args.pt)) {
      return this.padding
    }

    return String(this.args.pt)
  }

  get mt() {
    if (isNone(this.args.mt)) {
      return '4'
    }

    return String(this.args.mt)
  }

  get mb() {
    if (isNone(this.args.mb)) {
      return '4'
    }

    return String(this.args.mb)
  }
}
