import classic from 'ember-classic-decorator';
/* eslint-disable
    ember/no-jquery,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Service from '@ember/service';

@classic
class LoadingIndicator extends Service {
  isActive = false;

  show(element) {
    if (element == null) { element = 'body'; }
    if (this.isActive === false) {
      $(element).append('<div class="loader loader-active med-with-bg"><div class="spinner-wrapper"><div class="spinner"></div></div></div>');
      $('.ts-loader').hide(); // this prevents a template-based loading indicator from showing underneath (i.e. if the user navigates directly to a leaf node / deeply-nested route)
      this.set('isActive', true);
      return $('.loader').hide();
    }
  }

  hide() {
    if (this.isActive === true) {
      $('.loader').remove();
      $('.ts-loader').show();
      this.set('isActive', false);
      return $('.loader').show();
    }
  }
}

export default LoadingIndicator;
