import Component from '@glimmer/component'
import { action } from '@ember/object'
import { isBlank } from '@ember/utils'

export default class PasswordValidationComponent extends Component {
  get validateUppercase() {
    const password = this.args.password

    if (isBlank(password)) {
      return false
    }

    return /[a-z]/.test(password) && /[A-Z]/.test(password)
  }

  get validateNumeric() {
    const password = this.args.password

    if (isBlank(password)) {
      return false
    }

    return /\d/.test(password)
  }

  get validateLength() {
    const password = this.args.password

    if (isBlank(password)) {
      return false
    }

    return password.length >= 8 && password.length <= 40
  }

  @action
  onPasswordUpdate() {
    const isValid =
      this.validateUppercase && this.validateNumeric && this.validateLength

    this.args.onValidate?.(isValid)
  }
}
