import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class UiActionComponent extends Component {
  get kind() {
    return this.args.kind ?? 'default'
  }

  get iconColor() {
    return this.args.iconColor ?? 'ui-gray-dark'
  }

  get roundedClass() {
    return this.args.rounded ? 'tw-rounded' : ''
  }

  get bgColor() {
    let colorOptions = {
      default: 'ui-gray-40',
      transparent: 'transparent',
      white: 'white'
    }
    return colorOptions[this.kind]
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(...arguments)
    }
  }
}
