import Component from '@glimmer/component'

export default class UiStackItem extends Component {
  get spacing() {
    let stackSpacing = {
      none: '0',
      sm: '2',
      md: '4',
      lg: '6'
    }
    return stackSpacing[this.args.spacing]
  }

  get divider() {
    return this.args.divider ?? false
  }

  get dynamicClasses() {
    const classes = []
    if (this.divider) {
      classes.push(`tw-py-${this.spacing}`)
    }
    return classes.join(' ')
  }
}
