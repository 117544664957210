import classic from 'ember-classic-decorator';
/* eslint-disable
    ember/no-jquery,
    no-undef
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Service from '@ember/service';

@classic
class Receipt extends Service {
  print() {
    $('body').addClass('print-receipt');
    return window.print();
  }
}

export default Receipt;
