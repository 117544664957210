import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { isBlank } from '@ember/utils'
import { action } from '@ember/object'

export default class CreatePasswordComponent extends Component {

  @tracked showPassword = false
  @tracked isPasswordValid = false

  get f() {
    return this.args.f
  }

  get password() {
    return this.args.model.get('password')
  }

  get validateUppercase() {
    const password = this.password

    if (isBlank(password)) {
      return false
    }

    return /[a-z]/.test(password) && /[A-Z]/.test(password)
  }

  get validateNumeric() {
    const password = this.password

    if (isBlank(password)) {
      return false
    }

    return /\d/.test(password)
  }

  get validateLength() {
    const password = this.password

    if (isBlank(password)) {
      return false
    }

    return password.length >= 8 && password.length <= 40
  }

  @action
  onInput() {
    this.isPasswordValid = this.validateUppercase && this.validateNumeric && this.validateLength
    this.args.onPasswordValidityChange?.(this.isPasswordValid)
  }

  @action
  toggleShowPassword() {
    this.showPassword = !this.showPassword
  }
}
