import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<InformationContainer @kind={{this.kind}}>\n  {{#each this.rows as |row|}}\n    <InformationContainer::Row\n      @label={{row.label}}\n      @labelBold={{row.labelBold}}\n      @labelTextSize={{row.labelTextSize}}\n      @labelTextColor={{row.labelTextColor}}\n      @value={{row.value}}\n      @valueBold={{row.valueBold}}\n      @valueTextSize={{row.valueTextSize}}\n      @valueTextSizeMobile={{row.valueTextSizeMobile}}\n      @valueTextColor={{row.valueTextColor}}\n    />\n    {{#if row.divider}}\n      <InformationContainer::Divider @kind={{this.kind}} @mb=\"2\" />\n    {{/if}}\n  {{/each}}\n</InformationContainer>\n", {"contents":"<InformationContainer @kind={{this.kind}}>\n  {{#each this.rows as |row|}}\n    <InformationContainer::Row\n      @label={{row.label}}\n      @labelBold={{row.labelBold}}\n      @labelTextSize={{row.labelTextSize}}\n      @labelTextColor={{row.labelTextColor}}\n      @value={{row.value}}\n      @valueBold={{row.valueBold}}\n      @valueTextSize={{row.valueTextSize}}\n      @valueTextSizeMobile={{row.valueTextSizeMobile}}\n      @valueTextColor={{row.valueTextColor}}\n    />\n    {{#if row.divider}}\n      <InformationContainer::Divider @kind={{this.kind}} @mb=\"2\" />\n    {{/if}}\n  {{/each}}\n</InformationContainer>\n","moduleName":"direct/components/portal/hub/loan-steps/request-funds/request-details-container.hbs","parseOptions":{"srcName":"direct/components/portal/hub/loan-steps/request-funds/request-details-container.hbs"}});
import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { isPresent } from '@ember/utils'
import { formatAsCurrency } from 'tag/utils/number-formatters'

export default class RequestDetailsContainerComponent extends Component {
  @service intl

  kind = 'background'

  get loan() {
    return this.args.loan
  }

  get requestType() {
    return this.args.requestType
  }

  get lastLoanTranche() {
    return this.loan.loanTranches.lastObject;
  }

  get purchaseAmountText() {
    if (this.requestType === 'stagedFundingFinal'){
      return this.intl.t('hub.request_funds.multi_stage_financing.total_purchase_amount')
    } else {
      return this.intl.t('hub.request_funds.multi_stage_financing.total_purchase_estimate')
    }
  }

  get showNextScheduledPaymentInfo() {
    if (isPresent(this.loan.nextScheduledPayment) && isPresent(this.loan.forcedFinalizeDate)) {
      return moment(this.loan.nextScheduledPayment.raw_date).toDate() < this.loan.forcedFinalizeDate
    } else {
      return true
    }
  }

  get requestedAmountRow() {
    const requestedAmount = this.loan.multiStageFinancing ? this.lastLoanTranche.requestedAmount : this.loan.requestedAmount;

    return {
      label: this.intl.t(
        'hub.request_funds.multi_stage_financing.requested_amount'
      ),
      value: formatAsCurrency(requestedAmount, { precision: 2 }),
      labelBold: true,
      valueBold: true,
      valueTextSize: 'lg',
      valueTextSizeMobile: 'base',
      divider: this.requestType === "nonStagedFunding"
    };
  }
  get requestRows() {
    const description = this.loan.multiStageFinancing ? this.lastLoanTranche.description : this.loan.purchaseDetails;
    const fee = this.loan.multiStageFinancing ? (this.loan.adminFee > 0 ? this.lastLoanTranche.fee : 0) : this.loan.feeToDisplay;

    const requestRows = [
      this.requestedAmountRow
    ];

    if (fee > 0) {
      requestRows.push({
        label: this.intl.t(
          'hub.request_funds.multi_stage_financing.administration_fee'
        ),
        value: formatAsCurrency(fee, { precision: 2 }),
      })
    }

    requestRows.push(
      {
        label: this.intl.t(
          'hub.request_funds.multi_stage_financing.request_description'
        ),
        value: description,
        divider: true,
      }
    )

    return requestRows
  }

  get purchaseRows() {
    const purchaseRows = []

    if (this.requestType === "nonStagedFunding") {
      purchaseRows.push(this.requestedAmountRow)
    } else {
      purchaseRows.push({
        label: this.purchaseAmountText,
        value: formatAsCurrency(this.loan.requestedAmount, { precision: 2 }),
      })
    }

    purchaseRows.push(
      {
        label: this.intl.t(
          'hub.request_funds.authorize_release_of_funds.purchase_type'
        ),
        value: this.loan.purchaseType,
      },
      {
        label: this.intl.t(
          'hub.request_funds.authorize_release_of_funds.purchase_description'
        ),
        value: this.loan.purchaseDetails,
        divider: this.requestType !== "nonStagedFunding"
      }
    )

    return purchaseRows
  }

  get multistageFundingRows() {
    if (this.requestType === "nonStagedFunding"){
      return []
    }

    const multistageFundingRows = []
    if (this.requestType === "stagedFundingNonFinal") {
      if (this.showNextScheduledPaymentInfo) {
        multistageFundingRows.push({
          label: this.intl.t(
            'hub.request_funds.multi_stage_financing.next_minimum_payment'
          ),
          value: formatAsCurrency(this.loan.nextMinimumPayment, { precision: 2 }),
          valueBold: true,
          valueTextSize: 'base',
          valueTextSizeMobile: 'sm',
        })
      }

      if (this.loan.loanTranches.length > 1 && this.showNextScheduledPaymentInfo && isPresent(this.loan.nextScheduledPayment)) {
        multistageFundingRows.push({
          label: this.intl.t(
            'hub.request_funds.multi_stage_financing.next_payment_due'
          ),
          value: moment(this.loan.nextScheduledPayment.raw_date).lang(this.intl.language).format(this.intl.t('date.formats.date_only')),
        })
      }

      if (this.loan.loanTranches.length > 1) {
        multistageFundingRows.push({
          label: this.intl.t(
            'hub.request_funds.multi_stage_financing.total_requested_to_date'
          ),
          value: formatAsCurrency(this.loan.fundingTranchesTotalRequestedAmount, { precision: 2 }),
        })
      }
    }
    return multistageFundingRows
  }

  get rows() {
    let rows = this.purchaseRows

    switch (this.requestType) {
      case "stagedFundingFinal":
        rows = this.requestRows.concat(this.purchaseRows)
        rows[rows.length - 1].divider = false
        break;
      case "stagedFundingNonFinal":
        rows = this.requestRows.concat(this.purchaseRows, this.multistageFundingRows)
    }
    return rows
  }
}
