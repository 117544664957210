import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class ShoppingPassLearnMoreModalComponent extends Component {
  @action
  closeModal() {
    closeModal('shopping_pass_learn_more')
  }
}
