import classic from 'ember-classic-decorator';
import { readOnly } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';

@classic
class AccountController extends Controller {
  @controller('financeit')
  financeitController;

  @readOnly('financeitController')
  financeit;

  shoppingPassLoan = false;
}

export default AccountController;
