/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { attr } from "@ember-data/model";
import { inject as service } from "@ember/service";
import BorrowerModel from "financeit-components/models/borrower";
import { collectionAction, memberAction } from "ember-api-actions";
import { isPresent } from "@ember/utils";
import { tracked } from '@glimmer/tracking'

export default class Borrower extends BorrowerModel {
  @service intl;

  @attr("boolean", { defaultValue: false }) appliedFromPartnerPortal;
  @attr("boolean") atMaxFlinksPoiAttempts;
  @attr("string") authCodeContactMethod;
  @attr authCodePhoneNumbers;
  @attr("boolean") canSelfUpdatePii;
  @attr("boolean") emailOptOut;
  @attr esignConsentHistory;
  @attr('boolean') insuranceDecision;
  @attr("boolean") mailOptOut;
  @attr("string") paymentHolidayResigningUrl;
  @attr("string") restructureQuebecSigningUrl;
  @attr("number") treatyNumber;
  @attr("number") validityMins;
  @attr('boolean') verifyIdAndOnfidoCheckStepsInReview;
  @attr ctuOffer;

  eConsentModalShown = false;
  @tracked _authCodePhoneNumberType;

  get authCodePhoneNumberCollection() {
    return this.authCodePhoneNumbers.map((phoneNumber) => ({
      label: this.intl.t("account.auth_code.masked_number", {
        phoneNumber: phoneNumber.phone_number,
      }),
      value: phoneNumber.type,
    }));
  }

  get authCodePhoneNumberType() {
    if (this._authCodePhoneNumberType) {
      return this._authCodePhoneNumberType
    }

    const authCodePhoneNumbers = this.authCodePhoneNumbers;
    if (!isPresent(authCodePhoneNumbers)) {
      return undefined;
    }

    const firstPhoneNumber = authCodePhoneNumbers.get("firstObject");
    if (authCodePhoneNumbers.length === 1) {
      return firstPhoneNumber.type;
    } else if (authCodePhoneNumbers.mapBy("type").includes("mobile")) {
      return "mobile";
    } else {
      return firstPhoneNumber.type;
    }
  }

  set authCodePhoneNumberType(value) {
    this._authCodePhoneNumberType = value
  }

  get authCodePhoneNumberEnding() {
    if (this.authCodePhoneNumbers) {
      return this.authCodePhoneNumbers.findBy(
        "type",
        this.authCodePhoneNumberType
      ).phone_number;
    } else {
      return null;
    }
  }

  get maritalStatusTranslation() {
    switch(this.maritalStatus) {
    case 'Single':
      return this.intl.t('profile.personal_details.single')
    case 'Married':
      return this.intl.t('profile.personal_details.married')
    case 'Separated':
      return this.intl.t('profile.personal_details.separated')
    case 'Divorced':
      return this.intl.t('profile.personal_details.divorced')
    case 'Common Law':
      return this.intl.t('profile.personal_details.common_law')
    case 'Widowed':
      return this.intl.t('profile.personal_details.widowed')
    default:
      return ''
    }
  }
}

Borrower.prototype.updateEsignConsent = collectionAction({
  path: "update_esign_consent",
  type: "put",
  urlType: "findRecord",
});

Borrower.prototype.updatePii = collectionAction({
  path: "update_pii",
  type: "post",
  urlType: "findRecord",
});

Borrower.prototype.updateEmail = collectionAction({
  path: "update_email",
  type: "post",
  urlType: "findRecord",
});

Borrower.prototype.updatePassword = collectionAction({
  path: "update_password",
  type: "post",
  urlType: "findRecord",
});

Borrower.prototype.createOnfidoSdkToken = memberAction({
  path: "onfido_sdk_token",
  type: "post",
  urlType: "findRecord",
});

Borrower.prototype.createOnfidoCheck = memberAction({
  path: "onfido_checks",
  type: "post",
  urlType: "findRecord",
});

Borrower.prototype.getOnfidoChecks = memberAction({
  path: "onfido_checks",
  type: "get",
  urlType: "findRecord",
});

Borrower.prototype.updateTreatyNumber = collectionAction({
  path: "update_treaty_number",
  type: "put",
  urlType: "findRecord",
});

Borrower.prototype.deleteTreatyNumber = collectionAction({
  path: "delete_treaty_number",
  type: "delete",
  urlType: "findRecord",
});
