import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'

export default class CreditTopUpApplyRoute extends Route {
  @service store
  @service router

  async model(params) {
    const [offer, partner, vertical] = await Promise.all([
      this._fetchOffer(params.offerId),
      this._fetchPartner(),
      this._fetchVertical()
    ])

    return {
      quote: this.store.createRecord('quote', {
        amount: offer.get('offerAmount'),
        paymentFrequency: partner.get('defaultPaymentFrequency'),
        useMaxAmortization: true,
        feesAbsorbed: partner.get('absorbBorrowerFees') === 'all',
        feesWaived: partner.get('adminFeesWaived'),
        selectedPartnerId: partner.get('id'),
        partner: partner,
        vertical: vertical,
        quebec: offer.get('isQuebec'),
      }),
      offer: offer,
    }
  }

  afterModel(model) {
    if (model.offer.claimed) {
      return this.router.transitionTo('account.login')
    }

    const quote = model.quote
    const quote_params = quote.serialize()

    return quote.calculate(quote_params).then(response => {
      return quote.set('calculations', response)
    })
  }

  async _fetchOffer(uuid) {
    return this.store.findRecord('credit-top-up-offer', uuid)
  }

  async _fetchPartner() {
    const json = await fetch('/en/api/v3/direct/partners/ctu').then(response => response.json())
    this.store.pushPayload('partner', { partner: json.partner })

    return this.store.find('partner', json.partner.id)
  }

  async _fetchVertical() {
    const json = await fetch('/en/api/v3/direct/verticals/ctu').then(response => response.json())
    this.store.pushPayload('vertical', { vertical: json.vertical })

    return this.store.find('vertical', json.vertical.id)
  }
}
