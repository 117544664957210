import Controller from '@ember/controller';
import { action } from '@ember/object';
import * as UiModalUtils from 'tag/utils/ui-modal'
import { inject as service } from '@ember/service'

export default class extends Controller {
  @service intl
  globalMinRequestedAmount = 1000;

  get minAmount() {
    return Math.max(this.model.loan.limitMinRequestedAmount, this.globalMinRequestedAmount);
  }

  get purchaseAmount() {
    return this.model.loan.requestedAmount || this.minAmount;
  }

  get headingText() {
    if (this.model.loan.isShoppingPass) {
      return this.intl.t("apply.calculator.heading_principal_only", {duration: this.model.loan.shoppingPassDuration})
    } else {
      return this.intl.t("apply.calculator.heading")
    }
  }

  @action
  showMobileQuoteModal() {
    UiModalUtils.showModal('quote-modal');
  }
}
