import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal @id=\"environment-modal\" as |m|>\n  <m.header @title={{t \"account.mobile_env_picker.modal_title\"}} />\n\n  <m.content>\n    {{#if this.formObject.environment}}\n      <ul class=\"tw-mb-4 tw-pl-4 tw-list-disc\">\n        {{#each this.suggestedUrls as |env|}}\n          <li class=\"tw-text-nowrap\">\n            <UiLinkToExternal @kind=\"basic\" @text={{env.label}} @href={{env.href}} @bold={{false}} />\n          </li>\n        {{/each}}\n      </ul>\n    {{/if}}\n\n    <UiForm @object={{this.formObject}} as |f|>\n      <f.UiTextField @name=\"environment\" @label={{t \"account.mobile_env_picker.environment_label\"}} />\n    </UiForm>\n  </m.content>\n</UiModal>\n", {"contents":"<UiModal @id=\"environment-modal\" as |m|>\n  <m.header @title={{t \"account.mobile_env_picker.modal_title\"}} />\n\n  <m.content>\n    {{#if this.formObject.environment}}\n      <ul class=\"tw-mb-4 tw-pl-4 tw-list-disc\">\n        {{#each this.suggestedUrls as |env|}}\n          <li class=\"tw-text-nowrap\">\n            <UiLinkToExternal @kind=\"basic\" @text={{env.label}} @href={{env.href}} @bold={{false}} />\n          </li>\n        {{/each}}\n      </ul>\n    {{/if}}\n\n    <UiForm @object={{this.formObject}} as |f|>\n      <f.UiTextField @name=\"environment\" @label={{t \"account.mobile_env_picker.environment_label\"}} />\n    </UiForm>\n  </m.content>\n</UiModal>\n","moduleName":"direct/components/account/environment-modal.hbs","parseOptions":{"srcName":"direct/components/account/environment-modal.hbs"}});
import Component from '@glimmer/component'
import { service } from '@ember/service'
import Changeset from 'ember-changeset'

export default class EnvironmentModalComponent extends Component {
  @service intl

  formObject = new Changeset({ environment: '' })

  get suggestedUrls() {
    const environment = this.formObject.environment
    return [
      {
        label: environment,
        href: `https://${environment}${this.loginPath}`,
      },

      {
        label: `${environment}.testfinanceit.com`,
        href: `https://${environment}.testfinanceit.com${this.loginPath}`,
      },
      {
        label: `${environment}.gofinanceit.com`,
        href: `https://${environment}.gofinanceit.com${this.loginPath}`,
      },
    ]
  }

  get loginPath() {
    if (this.args.isShoppingPassLoan) {
      return `/${this.intl.language}/direct/account/hdlogin`
    }

    return `/${this.intl.language}/direct/account/login`
  }
}
