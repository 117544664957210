import Component from '@glimmer/component'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { action } from '@ember/object'

export default class NoPhoneNumberModalComponent extends Component {
  @action
  closeModal(id) {
    UiModalUtils.closeModal(id)
  }
}
