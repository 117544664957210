import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { filter, gt } from '@ember/object/computed';
import { defer } from 'rsvp';
import { isPresent } from '@ember/utils';
import { scheduleOnce } from '@ember/runloop';
import PortalController from '../portal';
import { removeClass, addClass } from 'financeit-components/utils/dom-node-helpers';
import { formatAsCurrency } from 'tag/utils/number-formatters'
import * as UiModalUtils from 'tag/utils/ui-modal'
import secureFetch from '../../utils/secure-fetch'

@classic
class PortalHubController extends PortalController {
  recalculateStepsCount = false;

  @service
  socket;

  @service
  intl;

  @service
  router

  @service
  loanStepSlide;

  @service
  notification;

  @controller('services/paymentCalculator')
  calculator;

  @controller
  subvention;

  @controller
  session;

  @controller('services/paymentSchedule')
  paymentSchedule;

  @service store

  loading = false;
  showSpinner = false;
  sessionData = null;
  errorMessage = null;
  loginKey = null;
  submitLoanAgreementDocumentClicked = false;

  async getLoans() {
    this.set('sessionData', null);
    if (this.store.peekAll('loan').objectAt(0)){
      this.set('loginKey', this.store.peekAll('loan').objectAt(0).loginKey);
    }

    if(this.loginKey) {
      try {
        const data = await this.store.peekRecord('session', 0).loans({login_key: this.loginKey});
        this.set('sessionData', data);
      } catch(e) {
        this.set('errorMessage', e.errors[0]);
      }
    }
    this.set('showSpinner', false);
  }

  initShoppingPassSockets() {
    if (window.socketClient) { return; }

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    return scheduleOnce('afterRender', this, function() {
      if (this.get('loan.isShoppingPass')) {
        return this.setupShoppingPassSockets();
      }
    });
  }

  nonUiSteps() {
    return ['request_funds', 'insurance_offer_decision']
  }

  activateShoppingPass() {
    return window.location.reload();
  }

  setupShoppingPassSockets() {
    this.socket.createClient();
    window.socketClient.subscribe(`/shopping_pass/loading/${this.get('loan.id')}`, this.activateShoppingPass.bind(this));

    return window.addEventListener('online', () => {
      return this.loan.reload().then(() => {
        if (this.get('loan.state') === 'credit_issued') {
          return this.activateShoppingPass();
        }
      });
    });
  }

  @computed('loan.transactionsTableData', 'model.loanTranches.@each.state')
  get transactionsTableData() {
    return this.loan.transactionsTableData
  }

  @computed('loan.requestedFundsTableData', 'model.loanTranches.@each.state', 'requestFundsStepCompleted')
  get requestedFundsTableData() {
    return this.loan.requestedFundsTableData
  }

  @computed('requestedFundsTableData.length')
  get requestedFundsTableEmpty() {
    return this.requestedFundsTableData.length < 1
  }

  @computed('loan.transactionsTableData.length')
  get firstPaymentAttempted() {
    return this.loan.transactionsTableData.length >= 1
  }

  @computed('loan.state')
  get loanFunded() {
    return this.get('loan.state') === 'funded'
  }

  @computed('loan.isCreditTopUp')
  get showExpiry() {
    return !this.get('loan.isCreditTopUp')
  }

  @computed('session.activeLoansCount')
  get multipleActiveLoans() {
    return (this.get('session.activeLoansCount') > 1)
  }

  @computed('model.loanSteps', 'store')
  get paymentsAreNotSetup() {
    return this.store.peekAll('loan-step').findBy('name', 'set_up_payments').get('directState') === "new";
  }

  @computed('session.loggedInUser.id', 'store')
  get loggedInEmail() {
    const loggedInUserId = this.get('session.loggedInUser.id')
    const loggedInBorrower = this.store.peekAll('borrower').findBy('id', loggedInUserId)
    return loggedInBorrower.email
  }

  @computed('loan.isShoppingPass', 'model.loanSteps', 'store')
  get setUpPaymentsCanClearPoi() {
    if (!this.get('loan.isShoppingPass')) { return false }

    const poi_step = this.store.peekAll('loan-step').findBy('name', 'submit_proof_of_income');
    if (poi_step == undefined) { return false; }

    return poi_step.get('directState') != "completed";
  }

  @computed('loan.paymentFrequency')
  get paymentFrequency() {
    return this.intl.t(`hub.staged_payment.${this.get('loan.paymentFrequency')}`);
  }

  @computed('controllers.loan.fundingConditions')
  get fundingConditions() {
    return this.get('controllers.loan.fundingConditions');
  }

  @computed('loan.loanSteps.@each.directState', 'recalculateStepsCount')
  get activeStepsCount() {
    if (!this.get('loan.loanSteps')) { return 0; }
    const steps = this.get('loan.loanSteps').filter(step => step.get('directState') !== 'completed');
    return steps.length;
  }

  @computed('model.financeitStepContent')
  get hasFinanceitStep() {
    return isPresent(this.get('model.financeitStepContent'));
  }

  @computed('loan.loanSteps.@each.directState', 'recalculateStepsCount')
  get allUiStepsCompleted() {
    if (!this.get('loan.loanSteps')) {
      return false;
    }
    const steps = this.get('loan.loanSteps').filter(step => !this.nonUiSteps().includes(step.get('name')) && step.get('directState') !== 'completed');
    return steps.length === 0;
  }

  @computed('loan.loanSteps.@each.directState', 'recalculateStepsCount')
  get stagedTitles() {
    const titles = [];
    this.get('loan.loanSteps').forEach(title => {
      if (title.get('signingStep')) {
        return titles.push(title.get('name'));
      }
    });
    return titles;
  }

  @computed('calculator.selectedSubvention', 'loan.subventionProgramId')
  get isStandardProgram() {
    return isPresent(this.get('calculator.selectedSubvention')) && this.get('calculator.selectedSubvention').type === 'StandardProgram';
  }

  @computed('calculator.selectedSubvention', 'loan.subventionProgramId')
  get isNoPaymentsProgram() {
    return isPresent(this.get('calculator.selectedSubvention')) && this.get('calculator.selectedSubvention').type === 'NoPayments';
  }

  @computed('calculator.selectedSubvention', 'loan.subventionProgramId')
  get isSameAsCashProgram() {
    return isPresent(this.get('calculator.selectedSubvention')) && this.get('calculator.selectedSubvention').type === 'SameAsCash';
  }

  @computed('calculator.selectedSubvention', 'loan.subventionProgramId')
  get isRateDiscount() {
    return isPresent(this.get('calculator.selectedSubvention')) && this.get('calculator.selectedSubvention').type === 'RateDiscount';
  }

  @computed('isNoPaymentsProgram', 'isSameAsCashProgram')
  get isBadgePromotion() {
    return this.isNoPaymentsProgram || this.isSameAsCashProgram;
  }

  @computed('loan.{isPurchaseDetailsCompleted,approvalNoAmount}')
  get isPurchaseAmountSet() {
    return !this.get('loan.approvalNoAmount') || this.get('loan.isPurchaseDetailsCompleted');
  }

  @computed('loan.{minRate,maxRate}')
  get rateIsARange() {
    return this.get('loan.minRate') !== this.get('loan.maxRate');
  }

  @computed('loan.{isAwaitingAuthorizeReleaseOfFunds,northStarStreamlinedFlowEligible,fundedOrPartiallyFunded,isCreditTopUp}')
  get showCheckList() {
    if (this.loan.isCreditTopUp) {
      return false;
    }
    return !this.get('loan.northStarStreamlinedFlowEligible') || !(this.get('loan.isAwaitingAuthorizeReleaseOfFunds') || this.get('loan.fundedOrPartiallyFunded'));
  }

  applyRateDiscount() {
    this.set('loan.minRate', this.get('loan.minRate') - (this.get('subvention.promo.rate') * 100));
    return this.set('loan.maxRate', this.get('loan.maxRate') - (this.get('subvention.promo.rate') * 100));
  }

  @computed('loan.loanSteps.content')
  get documentsArchiveItems() {
    const archiveItems = [];

    this.get('loan.loanSteps.content').forEach(function(item) {
      if (item.get('signedDocsUrl') !== undefined) {
        return archiveItems.push({
          title: this.intl.t(`hub.view_completed_document_name.${item.get('name')}`),
          url: item.get('signedDocsUrl')
        });
      }
    });
    return archiveItems;
  }

  @gt('activeStepsCount', 0)
  moreThanZeroSteps;

  @computed('moreThanZeroSteps', 'model.northStarStreamlinedFlowEligible', 'loan.isShoppingPass')
  get showZeroStepMessaging() {
    if (this.get('model.northStarStreamlinedFlowEligible') || this.get('loan.isShoppingPass')) { return false }

    return !this.moreThanZeroSteps
  }

  @computed('partner.businessName')
  get merchantName() {
    return this.get('partner.businessName');
  }

  @computed('model.loanTranches.length')
  get fundingStepsCount() {
    return this.get('model.loanTranches.length');
  }

  @computed('loan.isAwaitingAuthorizeReleaseOfFunds')
  get virtualCardHeaderTranslationKey() {
    return this.loan.isAwaitingAuthorizeReleaseOfFunds ? 'awaiting_release_of_funds_header' : 'header'
  }

  @computed('loan.loanSteps.@each.name')
  get requestFundsStep() {
    return this.loan.loanSteps.find((step) => String(step.name) === 'request_funds')
  }

  @computed('loan.loanSteps.@each.name')
  get submitLoanAgreementStep() {
    return this.loan.loanSteps.find((step) => String(step.name) === 'submit_loan_agreement')
  }

  @computed('requestFundsStep.directState')
  get requestFundsStepCompleted() {
    const step = this.requestFundsStep;

    return step && step.directState === 'completed'
  }

  @computed('requestFundsStepCompleted', 'requestFundsStep.isEditable')
  get requestFundsStepEditable() {
    if (this.requestFundsStepCompleted) {
      return false
    }

    const step = this.requestFundsStep;

    if (!step) {
      return false
    }
    return step.isEditable
  }

  @computed('model.loanTranches')
  get sumTranchesRequestedAmount() {
    let total = 0
    this.get('model.loanTranches').forEach(tranche => {
      total += tranche.requestedAmount
    })
    return total
  }

  @computed('model.loanTranches')
  get sumDisbursedTranchesRequestedAmount() {
    let total = 0
    this.get('model.loanTranches').forEach(tranche => {
      if (tranche.isDisbursed) {
        total += tranche.requestedAmount
      }
    })
    return total
  }

  @computed('model.loanTranches', 'loan.principalOnlyPercentage')
  get multiStageFinancingFirstPaymentAmount() {
    return this.get('model.loanTranches').get('firstObject').principalAmount * this.loan.principalOnlyPercentage / 100
  }

  @computed('sumTranchesRequestedAmount', 'loan.creditLimit')
  get multiStageFinancingPreFundingAvailableCredit() {
    return this.loan.creditLimit - this.sumTranchesRequestedAmount
  }

  @computed('sumDisbursedTranchesRequestedAmount', 'loan.creditLimit')
  get multiStageFinancingPostFundingAvailableCredit() {
    return this.loan.creditLimit - this.sumDisbursedTranchesRequestedAmount
  }

  @computed('intl.language', 'loan.nextScheduledPayment.raw_date')
  get formattedNextScheduledPaymentDate() {
    return moment(this.loan.nextScheduledPayment.raw_date).lang(this.intl.language).format(this.intl.t('date.formats.date_only'))
  }

  @computed('model.loanTranches')
  get firstTrancheFunded() {
    const firstTranche = this.get('model.loanTranches').find(tranche => tranche.trancheNumber == 1 )
    return firstTranche.isDisbursed
  }

  @computed(
    'formattedNextScheduledPaymentDate',
    'loan.{creditLimit,multiStageFinancing,multiStageFinancingFundedButNotFinalized,forcedFinalizeDate,nextScheduledPayment.due,nextScheduledPayment.raw_date}',
    'multiStageFinancingFirstPaymentAmount',
    'multiStageFinancingPostFundingAvailableCredit',
    'multiStageFinancingPreFundingAvailableCredit',
    'requestFundsStepCompleted',
    'sumDisbursedTranchesRequestedAmount',
    'sumTranchesRequestedAmount'
  )
  get multiStageFinancingInfoRows() {
    let rows = []

    if (!this.loan.multiStageFinancing) {
      return rows
    }

    if (this.loan.multiStageFinancingFundedButNotFinalized) {
      const showNextScheduledPaymentInfo = isPresent(this.loan.nextScheduledPayment) &&
                                             moment(this.loan.nextScheduledPayment.raw_date).toDate() < this.loan.forcedFinalizeDate

      rows.push({
        label: this.intl.t('hub.virtual_card_tile.total_funded_amount'),
        value: formatAsCurrency(this.sumDisbursedTranchesRequestedAmount, { precision: 2 }),
        valueBold: true,
        divider: true,
      });
      if (showNextScheduledPaymentInfo) {
        rows.push({
          label: this.intl.t('hub.virtual_card_tile.next_minimum_payment'),
          value: formatAsCurrency(this.loan.nextScheduledPayment.due, { precision: 2 }),
          valueBold: true,
          divider: true,
          labelInfoModal: 'minimum-payment-modal',
        });
      }
      if (showNextScheduledPaymentInfo && this.loan.nextScheduledPayment.due > 0) {
        rows.push({
          label: this.intl.t('hub.virtual_card_tile.next_payment_due'),
          value: this.formattedNextScheduledPaymentDate,
          divider: true,
        });
      }
      rows.push({
        label: this.intl.t('hub.virtual_card_tile.credit_available'),
        value: formatAsCurrency(this.multiStageFinancingPostFundingAvailableCredit, { precision: 2 }),
      });
    } else if (this.requestFundsStepCompleted) {
      rows.push({
        label: this.intl.t('hub.virtual_card_tile.total_funded_amount'),
        value: formatAsCurrency(this.sumTranchesRequestedAmount, { precision: 2 }),
        valueBold: true,
        divider: true,
      });
      rows.push({
        label: this.intl.t('hub.virtual_card_tile.next_minimum_payment'),
        value: formatAsCurrency(this.multiStageFinancingFirstPaymentAmount, { precision: 2 }),
        valueBold: true,
        divider: true,
        labelInfoModal: 'minimum-payment-modal',
      });
      rows.push({
        label: this.intl.t('hub.virtual_card_tile.credit_available'),
        value: formatAsCurrency(this.multiStageFinancingPreFundingAvailableCredit, { precision: 2 }),
      });
    } else {
      rows.push({
        label: this.intl.t('hub.virtual_card_tile.total_funded_amount'),
        value: formatAsCurrency(0, { precision: 2 }),
        valueBold: true,
        divider: true,
      });
      rows.push({
        label: this.intl.t('hub.virtual_card_tile.credit_available'),
        value: formatAsCurrency(this.loan.creditLimit, { precision: 2 }),
      });
    }

    return rows
  }

  @filter('loan.loanSteps.@each.forAfterWorkCompletion', function(loanStep) {
    return !loanStep.forAfterWorkCompletion;
  })
  beforeWorkCompletionSteps;

  @filter('loan.loanSteps.@each.forAfterWorkCompletion', function(loanStep) {
    return loanStep.forAfterWorkCompletion && !this.nonUiSteps().includes(loanStep.name);
  })
  afterWorkCompletionSteps;

  /**
    Updates the request funds & other transactions table data to correct state after authorize release of funds is triggered
    @param {object} data - Amalgamation of step, tranche, and table data
    @returns {boolean} - Returns true if the update was successful; otherwise, false.
   */
  updateMsfTableData(data) {
    if (data.name !== 'request_funds' || !this.loan.multiStageFinancing || (data.last_tranche.state !== 'disbursed' && data.direct_state !== 'completed')) {
      return false
    }

    let loan = this.store.peekAll('loan').objectAt(0)
    loan.set('requestedFundsTableData', data.requestedFundsTableData);
    loan.set('transactionsTableData', data.transactionsTableData)

    return true
  }

  transitionToStep(path) {
    switch(path) {
      case 'submit_proof_of_income':
        if(this.get('loan.flinksPoiAvailable') && !this.get('borrower.atMaxFlinksPoiAttempts')){
          return this.transitionToRoute('portal.hub.banking.submit-proof-of-income');
        }
        return this.transitionToRoute('portal.hub.loan-steps', path)
      case 'submit_proof_of_income_coborrower':
        if(this.get('loan.flinksPoiAvailable') && !this.get('borrower.atMaxFlinksPoiAttempts')){
          return this.transitionToRoute('portal.hub.banking.submit-proof-of-income', {queryParams: {isCoborrowerPoiStep: true}});
        }
        return this.transitionToRoute('portal.hub.loan-steps', path)
      case 'set_up_payments':
        return this.transitionToRoute('portal.hub.banking.set-up-payments');
      case 'onfido_check':
        return this.transitionToRoute('portal.hub.secure-your-account', {queryParams: {borrowerType: 'Borrower'}});
      case 'onfido_check_coborrower':
        return this.transitionToRoute('portal.hub.secure-your-account', {queryParams: {borrowerType: 'Coborrower'}});
      default:
        return this.transitionToRoute('portal.hub.loan-steps', path)
    }
  }

  afterSubmitStep(data) {
    this.set('model.directState', data.direct_state)
    this.set('model.displayName', data.display_name)
    this.set('model.borrowerState', data.borrower_state)
    this.set('model.coborrowerState', data.coborrower_state)
    this.hubController.toggleProperty('recalculateStepsCount')

    if (this.model.get('name') === 'request_funds' && this.model.isCompleted) {
      this.notification.setNotification('success', this.intl.t('hub.release_of_funds_authorized', {merchant:this.loan.partner.get('businessName')}))
    }

    let insuranceOfferDecision = this.store.peekAll('loan-step').findBy('name', 'insurance_offer_decision')
    if (!this.loan.multiStageFinancing && (insuranceOfferDecision?.isNew || insuranceOfferDecision?.isAlerted)) {
      return this.router.transitionTo('portal.hub.insurance.decision')
    }

    if (data.last_tranche) {
      let currentTranche = this.store.peekRecord('loanTranche', data.last_tranche.id)
      currentTranche.set('state', data.last_tranche.state)
      currentTranche.set('isDisbursed', data.last_tranche.state === 'disbursed')
      currentTranche.set('disbursedAt', data.last_tranche.disbursed_at)
      currentTranche.set('isMsfFinalTranche', data.last_tranche.is_msf_final_tranche)

      if (currentTranche.isMsfFinalTranche) {
        if (insuranceOfferDecision?.isNew || insuranceOfferDecision?.isAlerted) {
          return this.router.transitionTo('portal.hub.insurance.decision')
        } else if (currentTranche.isDisbursed) {
          return this.router.transitionTo('portal.funded', this.loan.loginKey)
        }
      }
    }

    if (this.updateMsfTableData(data) || data.direct_state === 'completed' || data.direct_state === 'financeit_review') {
      return this.router.transitionTo('portal.hub', this.loan.loginKey)
    }
  }

  getLoanTrancheData() {
    const url = `/${this.intl.language}/api/v3/direct/loan_funding_tranches?`;
    return secureFetch(url + new URLSearchParams({ login_key: this.get('loan.loginKey') }), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getMsfTableData() {
    const url = `/${this.intl.language}/api/v3/direct/transactions_table_data?`;
    return secureFetch(url + new URLSearchParams({ login_key: this.get('loan.loginKey') }), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getLoan() {
    const id = this.get('loan.id')
    const url = `/${this.intl.language}/api/v3/direct/loans/${id}`;

    return secureFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  @action
  disableSubmitLoanAgreementLink() {
    this.set('submitLoanAgreementDocumentClicked', true);
  }

  @action
  showModal(modalId) {
    UiModalUtils.showModal(modalId)
  }

  @action
  launchVerifyIdBanking() {
    const deferred = defer();
    deferred.promise.then(() => {
      this.transitionToRoute('portal.hub.banking.connect', { queryParams: { sourceFlow: 'verify_id', idvStepId: this.get('currentStepBorrowerId.id'), borrowerType: this.borrowerType } });
      return removeClass('.banking .ui.dimmer', 'active')
    });
    addClass('.banking .ui.dimmer', 'active')
    return this.send('updateFlinksAgreement', this.currentStepBorrowerId, deferred);
  }

  @action
  openStep(path) {
    if (((path === 'verify_id') || (path === 'verify_id_coborrower')) && this.get('loan.hasSecureYourAccountStep')) {
      const borrowerType = path === 'verify_id' ? "Borrower" : "Coborrower";
      this.transitionToRoute('portal.hub.secure-your-account', { queryParams: { borrowerType } });
      return;
    }
    return this.transitionToStep(path)
  }

  @action
  closeNotification() {
    this.notification.reset()
  }

  @action
  updateIdWasVerifiedStatus() {
    this.send('updateBorrowerIdvDirectState');
    if (this.get('loan.hasCoborrower')) {
      return this.send('updateCoborrowerIdvDirectState');
    }
  }

  @action
  updateBorrowerIdvDirectState() {
    const verifyIdStep = this.store.peekAll('loan-step').findBy('name', 'verify_id');
    if (!verifyIdStep) {
      return false;
    }

    const verifyIdStepNotAlreadyCompleted = verifyIdStep.get('directState') !== 'completed';
    if (this.get('loan.borrowerIdVerified') && verifyIdStepNotAlreadyCompleted) {
      return verifyIdStep.set('directState', 'completed');
    }
  }

  @action
  alertBorrowerIdvDirectState() {
    const verifyIdStep = this.store.peekAll('loan-step').findBy('name', 'verify_id');
    if (!verifyIdStep) { return false; }

    return verifyIdStep.set('directState', 'alerted');
  }

  @action
  updateCoborrowerIdvDirectState() {
    const verifyCoborrowerIdStep = this.store.peekAll('loan-step').findBy('name', 'verify_id_coborrower');
    if (!verifyCoborrowerIdStep) {
      return false;
    }

    const verifyCoborrowerIdStepNotAlreadyCompleted = verifyCoborrowerIdStep.get('directState') !== 'completed';
    if (this.get('loan.coborrowerIdVerified') && verifyCoborrowerIdStepNotAlreadyCompleted) {
      return verifyCoborrowerIdStep.set('directState', 'completed');
    }
  }

  @action
  inProgressOnfidoCheckDirectState(loanStepName) {
    const loanStep = this.store.peekAll('loan-step').findBy('name', loanStepName);
    if (!loanStep) { return false; }
    return loanStep.set('directState', 'in_progress');
  }

  @action
  completeOnfidoCheckDirectState(loanStepName) {
    const loanStep = this.store.peekAll('loan-step').findBy('name', loanStepName);
    if (!loanStep) { return false; }
    return loanStep.set('directState', 'completed');
  }

  @action
  alertOnfidoCheckDirectState(loanStepName) {
    const loanStep = this.store.peekAll('loan-step').findBy('name', loanStepName);
    if (!loanStep) { return false; }
    return loanStep.set('directState', 'alerted');
  }

  @action
  updateFlinksAgreement(borrower, deferred) {
    // eslint-disable-next-line ember/no-jquery
    return $.ajax({
      type: 'PUT',
      url: `/${this.intl.language}/api/v3/direct/borrowers/${borrower.id}`,
      data: {
        [borrower.key]: { flinks_check_agreement: '1' }
      }
    }).then((data, status, xhr) => {
      this.store.pushPayload('borrower', { borrower: data.borrower });
      return deferred.resolve();
    });
  }

  @action
  goToPhotoSpoke() {
    return this.transitionToRoute('portal.hub.loan-steps', 'supplemental_supported_id')
  }

  @action
  slideToHub() {
    this.loanStepSlide.to('portal.hub');
  }

  @action
  submitStep(document_type) {
    const url = `/${this.intl.language}/api/v3/direct/loan_steps/${this.get('model.id')}/submit`;
    const data = {
      login_key: this.get('loan.loginKey'),
      document_type,
    };

    // eslint-disable-next-line ember/no-jquery
    return $.ajax({
      type: 'PUT',
      url,
      data
    }).then((data, _status, _xhr) => {
      removeClass('.borrower-upload', 'hidden')
      removeClass('.coborrower-upload', 'hidden')
      this.set('uploadSubmitted', true);
      this.afterSubmitStep(data)
    });
  }

  @action
  async submitRequestFundsStep() {
    const url = `/${this.intl.language}/api/v3/direct/loan_steps/${this.get('model.id')}/submit`;
    const data = {
      login_key: this.get('loan.loginKey'),
      request_funds_form: { borrower_confirmed_work_complete: this.model.authorizedReleaseOfFunds }
    };
    const response = await secureFetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.content,
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();

    if (this.loan.multiStageFinancing) {
      const trancheDataResponse = await this.getLoanTrancheData();

      if (trancheDataResponse.ok) {
        const trancheData = await trancheDataResponse.json();
        responseData.last_tranche = trancheData.loan_tranches.lastObject;
      }

      const tableDataResponse = await this.getMsfTableData();
      if (tableDataResponse.ok) {
        const tableData = await tableDataResponse.json();
        responseData.requestedFundsTableData = tableData.requestedFundsTableData;
        responseData.transactionsTableData = tableData.transactionsTableData;
      }

      if (responseData.last_tranche.is_msf_final_tranche) {
        const loanResponse = await this.getLoan();
        if (loanResponse.ok) {
          const loanData = await loanResponse.json();
          this.store.push(this.store.normalize('loan', loanData.loan));
        }
      }
    }

    this.afterSubmitStep(responseData);
  }
}

export default PortalHubController;
