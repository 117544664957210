import Helper from '@ember/component/helper'
import { formatAsNumber } from 'tag/utils/number-formatters'
import { service } from '@ember/service'

export default class Percentage extends Helper {
  @service intl

  compute([value], { precision = null }) {
    const number = precision ? formatAsNumber(value, { precision }) : value

    return this.intl.t('percentage', { number })
  }
}
