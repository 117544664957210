import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class TermEndExplanationModalComponent extends Component {
  @action
  closeModal() {
    closeModal('term_end_explanation')
  }
}
