import classic from 'ember-classic-decorator';
import RESTSerializer from '@ember-data/serializer/rest';
import { decamelize } from '@ember/string';
import { singularize } from 'ember-inflector';

@classic
class ApplicationSerializer extends RESTSerializer {
  keyForAttribute(attr) {
    return decamelize(attr);
  }

  keyForRelationship(relationshipModelName, kind) {
    const key = decamelize(relationshipModelName);
    if (kind === 'belongsTo') {
      return key + '_id';
    } else if (kind === 'hasMany') {
      return singularize(key) + '_ids';
    } else {
      return key;
    }
  }
}

export default ApplicationSerializer;
