import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';

@classic
class PortalHubBankingConfirmationRoute extends Route {
  @service router

  model() {
    return this.controllerFor('loan').get('model');
  }

  setupController() {
    if (!this.controller.idNewlyVerified() && !this.controller.setUpPaymentsNewlyCompleted() && !this.controller.poiNewlyCompleted()) {
      return this.router.transitionTo('portal.hub');
    }
  }
}

export default PortalHubBankingConfirmationRoute;
