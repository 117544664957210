import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { defer } from 'rsvp';
import PortalHubBankingController from '../banking';

@classic
class PortalHubBankingSetUpPaymentsController extends PortalHubBankingController {
  @controller('portal.hub')
  hubController;

  @computed('loan.{borrowerId,hasCoborrower}', 'session.loggedInUser.id')
  get coborrowerLoggedIn() {
    return this.get('loan.hasCoborrower') && (parseInt(this.get('session.loggedInUser.id')) !== this.get('loan.borrowerId'));
  }

  @computed('coborrowerLoggedIn', 'loan.{borrower.id,coborrower.id}', 'model')
  get currentUserId() {
    if (this.coborrowerLoggedIn) {
      return {
        id: this.get('loan.coborrower.id'),
        key: "coborrower"
      };
    } else {
      return {
        id: this.get('loan.borrower.id'),
        key: "borrower"
      };
    }
  }

  @computed(
    'coborrowerLoggedIn',
    'loan.{borrowerIdVerified,coborrowerIdVerified}',
  )
  get currentUserHasBeenVerified() {
    if (this.coborrowerLoggedIn) {
      return this.get('loan.coborrowerIdVerified');
    } else {
      return this.get('loan.borrowerIdVerified');
    }
  }

  @computed(
    'coborrowerLoggedIn', 'loan.{borrowerIdVerified,coborrowerIdVerified,borrower.canVerifyIdViaBanking,coborrower.canVerifyIdViaBanking}'
  )
  get currentUserCanVerifyIdViaBanking() {
    if (this.coborrowerLoggedIn) {
      return this.get('loan.coborrower.canVerifyIdViaBanking');
    } else {
      return this.get('loan.borrower.canVerifyIdViaBanking');
    }
  }

  @computed('currentUserHasBeenVerified', 'currentUserCanVerifyIdViaBanking')
  get setUpPaymentsWillClearVerifyId() {
    if (!this.currentUserHasBeenVerified) {
      return this.currentUserCanVerifyIdViaBanking;
    } else {
      return false;
    }
  }

  @action
  launchSetupPayments() {
    const deferred = defer();
    deferred.promise.then(() => {
      this.transitionToRoute('portal.hub.banking.connect', { queryParams: { sourceFlow: 'set_up_payments' } });
      return this.hubController.set('loading', false);
    });
    this.hubController.set('loading', true);
    return this.hubController.send('updateFlinksAgreement', this.currentUserId, deferred);
  }
}

export default PortalHubBankingSetUpPaymentsController;
