import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import PortalProfileIndexController from '../profile';

@classic
export default class PreferencesController extends PortalProfileIndexController {
    @computed('loan.{isShoppingPass,northStarStreamlinedFlowEligible}')
    get canOptOutOfEconsent() {
        return !(this.get('loan.isShoppingPass') || this.get('loan.northStarStreamlinedFlowEligible'))
    }
}
