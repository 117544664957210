import classic from 'ember-classic-decorator';
import { schedule } from '@ember/runloop';
import Route from '@ember/routing/route';
import { onActivate, onDeactivate } from '../../../utils/step-transition'
import { inject as service } from '@ember/service';

@classic
class PortalHubBankingRoute extends Route {
  @service store

  activate() {
    super.activate(...arguments)
    onActivate()
  }

  deactivate() {
    super.deactivate(...arguments)
    onDeactivate()
  }

  beforeModel(transition) {
    const loan = this.controllerFor('loan').get('model');
  }

  model() {
    return this.store.peekAll('loan-step').findBy('name', 'set_up_payments');
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (this.controllerFor('partner').get('model.isBigBox')) {
      return schedule('afterRender', this, () => {
        if (window.hj) { return window.hj('trigger', 'checklist_step_poll'); }
      });
    }
  }
}


export default PortalHubBankingRoute;
