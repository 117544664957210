import Component from '@glimmer/component'
import { inject as service } from '@ember/service'
import { load as loadOnfido } from 'financeit-components/utils/load-onfido'

export default class OnfidoWebSdkComponent extends Component {
  @service intl
  @service router

  sdkLocale = this.intl.primaryLocale.startsWith('fr') ? 'fr_FR' : 'en_US'

  onfidoSdkToken = this.args.onfidoForm.onfido_sdk_token
  onfidoSdkSteps = this.args.onfidoForm.onfido_sdk_steps

  didInsert(_, [thisComponent]) {
    // FYI, this function is not a lifecycle event in Glimmer components but rather a function
    // that is called by the did-insert modifier in the hbs file, with the component context
    // passed in as `thisComponent`
    loadOnfido().then(() => {
      thisComponent.onfidoSDKInit()
    }).catch((error) => {
      thisComponent.router.transitionTo('error').then(() => {
        throw new Error(`Onfido SDK failed to load: ${error?.srcElement?.outerHTML}`)
      })
    })
  }

  onfidoSDKInit() {
    this.onfidoSDK = window.Onfido.init({
      token: this.onfidoSdkToken,
      containerId: 'onfido-mount',
      steps: this.onfidoSdkSteps,
      smsNumberCountryCode: 'CA',
      onComplete: (callbackData) => {
        this.args.createOnfidoCheck(callbackData)
      },
      language: {
        locale: this.sdkLocale,
        phrases: {
          document_selector: {
            identity: { title: this.intl.t('onfido-web-sdk.document_selector.identity.title') }
          }
        },
        mobilePhrases: {
          document_selector: {
            identity: { title: this.intl.t('onfido-web-sdk.document_selector.identity.title') }
          }
        }
      }
    })
  }

  willDestroy() {
    this.onfidoSDK?.tearDown()
    super.willDestroy(...arguments)
  }
}
