import Component from '@glimmer/component'
import { State } from 'tag/utils/ui-uploader'


export default class Progress extends Component {
  get iconClasses() {
    const classes = []
    if (this.args.state === State.Dragging) {
      classes.push('dragging-arrow')
    }

    return classes.join(' ')
  }

  get icon() {
    switch (this.args.state) {
      case State.Success:
        return 'check'
      case State.Error:
        return 'warning'
      default:
        return 'arrow-up'
    }
  }

  get iconColor() {
    switch (this.args.state) {
      case State.Error:
        return 'error-100'
      default:
        return 'interactive-100'
    }
  }

  get progress() {
    switch (this.args.state) {
      case State.Success:
        return 100
      case State.Error:
      case State.Dragging:
        return 0
      default:
        return this.args.progress
    }
  }
}
