import Component from '@glimmer/component'

export default class UiProgressBarStepComponent extends Component {
  get incompleteCircleColor() {
    if (this.args.currentStep) {
      return 'accent-100'
    } else {
      return 'ui-gray-80'
    }
  }

  get incompleteCircleSize() {
    if (this.args.currentStep) {
      return '4'
    }
    return '3'
  }

  get borderColor() {
    if (this.args.completed) {
      return 'accent-100'
    } else {
      return 'ui-gray-80'
    }
  }

  get borderWidth() {
    if (this.args.lastStep) {
      return '0'
    } else {
      return '2'
    }
  }
}
