import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import Changeset from 'ember-changeset';
import PortalController from '../portal';
import { applyErrors } from 'financeit-components/utils/model-helpers';

const _ = window._

@classic
class PortalRepromptController extends PortalController {
  @controller
  portal;

  @service
  intl;

  @service store
  @tracked borrowerChangeset

  @action
  setupRepromptChangeset() {
    if (!this.borrower) {
      return
    }

    this.borrowerChangeset = new Changeset(this.borrower);
    if (this.shouldResolveValue('firstNameOptions')) {
      this.clearInitialValue('firstNameOptions');
    }

    if (this.shouldResolveValue('lastNameOptions')) {
      this.clearInitialValue('lastNameOptions');
    }

    if (this.shouldResolveValue('birthdateOptions') && !this.shouldRepromptValue('birthdateOptions')) {
      this.clearInitialValue('birthYear');
      this.clearInitialValue('birthMonth');
      this.clearInitialValue('birthDate');
    }
  }

  shouldResolveValue(borrowerProperty) {
    return !!this.borrowerController[borrowerProperty].resolve
  }

  shouldRepromptValue(borrowerProperty) {
    return !!this.borrowerController[borrowerProperty].reprompt
  }

  clearInitialValue(borrowerProperty) {
    this.borrowerChangeset.set(borrowerProperty, '');
  }

  @computed('partner.borrowerBirthdateMin', 'borrower.province')
  get minBirthdate() {
    return new Date(this.get('partner.borrowerBirthdateMin'));
  }

  @computed('partner.{borrowerBirthdateMax,borrowerBirthdateMin}')
  get maxBirthdate() {
    return new Date(this.get('partner.borrowerBirthdateMax'));
  }

  @action
  onSubmit() {
    // eslint-disable-next-line ember/no-jquery
    $('.reprompt-feature .ui.dimmer').addClass('active');
    this.set('portal.loadingStatus', true);

    const borrower = this.borrowerChangeset;
    const form_key = `loan_actions_resolve_mismatch_warnings_${borrower.get('borrowerType').toLowerCase()}`;
    const data = {};
    data[form_key] = {};
    borrower.get('attributesToResolve').forEach(function(attribute) {
      const options = borrower.get(`selectOptions.${attribute}`)?.content;
      if (options) {
        let value = borrower.get(camelize(attribute));
        const new_options = {};
        _.keys(options).map(function(key) {
          const option = options[key];
          return new_options[key] = typeof option === "object" ? JSON.stringify(option) : option;
        });

        return data[form_key][attribute] = _.invert(new_options)[value];
      } else {
        if (attribute === "birthdate") {
          data[form_key]["birthdate(3i)"] = borrower.get("birthDate");
          data[form_key]["birthdate(2i)"] = borrower.get("birthMonth");
          return data[form_key]["birthdate(1i)"] = borrower.get("birthYear");
        } else {
          return data[form_key][attribute] = borrower.get(camelize(attribute));
        }
      }
    });

    // eslint-disable-next-line ember/no-jquery
    return $.ajax({
      type: 'PUT',
      url: `/${this.intl.language}/api/v3/direct/borrowers/${this.get('borrower.id')}/resolve_mismatch_warnings`,
      data
    }).then((data, status, xhr) => {
      this.store.push(this.store.normalize('borrower', data.borrowers[0]));
      this.set('borrower', this.store.peekRecord('borrower', data.borrowers[0].id));
      this.store.pushPayload('loan', { loan: data.loan });
      this.set('loan', this.store.peekRecord('loan', data.loan.id));

      return this.transitionToRoute('portal.offers.offer-approved', this.get('loan.loginKey'));
    }, jqXHR => {
      // eslint-disable-next-line ember/no-jquery
      $('.reprompt-feature .ui.dimmer').removeClass('active');
      this.set('portal.loadingStatus', false);

      const errors = this.store.adapterFor('borrower').ajaxError(jqXHR);
      this.borrower.send('becomeDirty');

      applyErrors(this.borrower, errors);
    });
  }
}

export default PortalRepromptController;
