import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-jquery,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { next } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import Controller from '@ember/controller';
import * as UiModalUtils from 'tag/utils/ui-modal'
import { applyErrors } from 'financeit-components/utils/model-helpers';

@classic
class AccountNewController extends Controller {
  showError = false;
  for = null;
  backendId = null;

  @service
  intl;

  @service store;
  @service router

  get backButtonHref() {
    return `/${this.intl.language}/direct/account/login`
  }

  directToSetupPage(data) {
    const params = {
      for: data.login_key,
      isCoborrower: data.is_coborrower,
      needsEsignConsent: data.needs_esign_consent,
      email: isPresent(data.borrower_email) ? data.borrower_email : null
    };

    this.router.transitionTo('account.setup', { queryParams: params });
  }

  @action
  goToLogin() {
    UiModalUtils.closeModal('login-modal');
    return this.router.transitionTo('account.login');
  }

  @action
  onSubmit() {
    const url = `/${this.intl.language}/api/v3/direct/borrowers/verify_id`;
    const borrower_data = this.model.serialize();
    const data = {
      'loan_id': borrower_data.loan_reference_number || this.backendId,
      'birthdate(3i)': borrower_data.birth_date,
      'birthdate(2i)': borrower_data.birth_month,
      'birthdate(1i)': borrower_data.birth_year
    };

    return $.ajax({
      type: 'POST',
      url,
      data
    }).then((data, status, xhr) => {
      if (isPresent(data.borrower_email)) {
        this.set('model.borrowerEmail', data.borrower_email); //borrower or coborrower
      }

      if (data.borrower_has_account) {
        this.set('for', data.login_key);

        return next(this, function() {
          return UiModalUtils.showModal('login-modal');
        });
      }

      this.directToSetupPage(data);
    }, jqXHR => {
      if (jqXHR.status == 503) {
        return window.location.href = '/503.html';
      }
      const errors = this.store.adapterFor('borrower').ajaxError(jqXHR);
      applyErrors(this.model, errors)

      if (isPresent(errors.noMatch)) {
        return this.set('showError', true);
      }
    });
  }
}

export default AccountNewController;
