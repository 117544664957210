import Component from '@glimmer/component'
import { isPresent } from '@ember/utils'

export default class UiFieldGroup extends Component {
  defaultBottomMargin = 4
  defaultSmallBottomMargin = 8

  get divider() {
    return this.args.divider ?? false
  }

  get mb() {
    if (isPresent(this.args.mb)) {
      return this.args.mb
    }

    if (this.divider) {
      return 0
    }

    return this.defaultBottomMargin
  }

  get smallMb() {
    if (isPresent(this.args.smallMb)) {
      return this.args.smallMb
    }

    if (this.divider) {
      return 0
    }

    return this.defaultSmallBottomMargin
  }
}
