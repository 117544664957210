import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils'

export default class LearnMoreContentComponent extends Component {
  get showExtendedCreditCopy() {
    return this.args.offer.extendedCreditOption || this.args.offer.extendedCredit

  }

  get showInterestRateFaq() {
    return this.args.isHomeImprovement && !this.args.quebecOffer && !this.args.postApproval
  }

  get showFeesGeneric() {
    if (this.args.postApproval) {
      return this.args.offer.hasAnyFeesAtAll && !this.args.offer.feesAbsorbed && !this.args.partner.adminFeesWaived
    } 

      return this.args.offer.showFeesGeneric
  }

  get showFeesSubprimeOnly() {
    if (this.postApproval) {
      return this.args.offer.isSubprime &&
        this.args.partner.get('adminFeesWaived') &&
        this.args.offer.hasAnyFeesAtAll &&
        isPresent(this.args.offer.feesAbsorbed) &&
        !this.args.offer.feesAbsorbed
    } else {
      return this.args.partner.get('subprimeEligible') && this.args.partner.get('adminFeesWaived') && isPresent(this.args.offer.feesAbsorbed) && !this.args.offer.feesAbsorbed
    }
  }

  @action
  collapseAccordionLearnMore() {
    // eslint-disable-next-line ember/no-jquery
    return $('#learn_more_faq').find('.collapse.in').collapse('hide');
  }
}
