import Component from "@glimmer/component"
import { closeModal } from "tag/utils/ui-modal"
import { action } from "@ember/object"

export default class ChangeCardPinModalComponent extends Component {
  id = "change_card_pin"

  @action
  onClose() {
    this.args.shoppingPass.reload()
    closeModal(this.id)
  }
}
