import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-jquery,
    no-undef
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';

@classic
class ErrorRoute extends Route {
  @service
  intl;

  beforeModel() {
    return $(document).attr('title', this.intl.t('title.error'));
  }
}

export default ErrorRoute;
