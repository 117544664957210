import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias, readOnly } from '@ember/object/computed';
/* eslint-disable
    ember/no-jquery,
    ember/use-brace-expansion,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils';
import FinanceitComponentsPortalController from 'financeit-components/controllers/portal';
import { addClass, removeClass } from 'financeit-components/utils/dom-node-helpers'
import { showModal } from 'tag/utils/ui-modal'

@classic
class PortalController extends FinanceitComponentsPortalController {
  @service
  router;

  @service
  intl;

  @service
  rollbar;

  @service store

  maxIdleTime = 30;
  maxIdleTimeout = 30;

  @controller('financeit')
  financeitController;

  @readOnly('financeitController')
  financeit;

  @controller('borrower')
  borrowerController;

  @alias('borrowerController.model')
  borrower;

  @controller('partner')
  partnerController;

  @readOnly('partnerController.model')
  partner;

  @controller('portal/funded')
  funded;

  @controller('loan')
  loanController;

  @alias('loanController.model')
  loan;

  @controller('portal/shopping-pass-dashboard')
  shoppingPassController;

  @alias('shoppingPassController.model')
  shoppingPass;

  @controller('session')
  session;

  @computed('loan.paymentFrequency')
  get loanPaymentFrequecy() {
    const freq = (() => {
      switch (this.get('loan.paymentFrequency')) {
        case 'm':
          return this.intl.t('loan_steps.m_payment');
        case 'f':
          return this.intl.t('loan_steps.f_payment');
        case 'fr':
          return this.intl.t('loan_steps.f_payment_rapid');
        case 'w':
          return this.intl.t('loan_steps.w_payment');
        case 'wr':
          return this.intl.t('loan_steps.w_payment_rapid');
        case 'sm':
          return this.intl.t('loan_steps.sm_payment');
      }
    })();
    return freq.toLowerCase();
  }

  loadingStatus = false;

  @computed('loan.currentArrears')
  get isInArrears() {
    return this.get('loan.currentArrears').is_in_arrears;
  }

  @computed('loan.termMatchesAmort', 'loan.hideAfterTermEndsModal')
  get hideWhatHappensAfterModal() {
    return this.get('loan.termMatchesAmort') || this.get('loan.hideAfterTermEndsModal');
  }

  @computed('loan.isShoppingPass')
  get shoppingPassLoan() {
    return this.get('loan.isShoppingPass');
  }

  @computed('loan.isShoppingPass', 'loan.state')
  get activeShoppingPass() {
    return this.get('loan.isShoppingPass') && (this.get('loan.state') === 'credit_issued');
  }

  @computed('borrower.{emailConfirmationCode,id}', 'intl.language')
  get esignOptInLink() {
    return `/${this.intl.language}/esign_consent/${this.get('borrower.id')}/${this.get('borrower.emailConfirmationCode')}`;
  }

  @computed('router.currentRouteName', 'loan.isReactivatable', 'shoppingPassLoan')
  get backgroundColour() {
    const grayBackgroundRoutes = [
      'portal.offers.offer',
      'portal.soft-decline.index',
      'portal.hard-decline',
      'portal.reprompt'
    ];

    if (!this.shoppingPassLoan && grayBackgroundRoutes.includes(this.router.currentRouteName) && !this.get('loan.isReactivatable')) {
      return 'gray-background';
    }

    return 'tw-bg-white';
  }

  @action
  goToPhotoSpoke() {
    return this.transitionToRoute('portal.hub.loan-steps', 'supplemental_supported_id');
  }

  @action
  printTable() {
    addClass('body', 'print-table')
    return window.print();
  }

  @action
  closePrintTable() {
    return removeClass('body', 'print-table')
  }

  @action
  confirmJobCompletion() {
    removeClass('.sign-document-block', 'hidden')
    removeClass('.step.sign-document.job-completion-prompt--completed', 'job-completion-prompt--completed')

    addClass('.step.sign-document.job-completion-prompt--completed', ['ready', 'highlight'])
    addClass('.decision-prompt', 'hidden')
  }

  @action
  confirmJobNotCompleted() {
    removeClass('.documents-not-ready', 'hidden')
    removeClass('.step.sign-document.job-completion-prompt--completed', 'job-completion-prompt--completed')

    addClass('.step.sign-document.job-completion-prompt--completed', ['job-completion-prompt', 'highlight'])
    addClass('.decision-prompt', 'hidden')
  }

  @action
  goToPartnerContact() {
    if (this.get('target.currentRouteName').indexOf("hub") > 0) {
      return this.transitionToRoute('portal.hub.loan-steps', 'supplemental_contact_partner')
    } else {
      this.rollbar.error(`The contact_partner_information modal has been removed, and has been triggered in error`)
    }
  }

  @action
  openCreatePasswordModal() {
    showModal('create-password-modal')
  }

  @action
  logout() {
    this.set('session.loggedIn', false);
    return this.send('logoutApiAction');
  }

  @action
  logoutOnCreatePassword() {
    this.set('session.loggedIn', false);
    return this.store.peekRecord('session', 0).logout().then(() => {
      this.store.unloadAll();

      return this.router.transitionTo('account.login');
    });
  }

  @action
  goToChecklist() {
    if (this.get('loan.isHardPulled')){
      this.router.transitionTo('portal.hub');
    }else {
      this.router.transitionTo('portal.stage-two-loan-application-form');
    }
  }

  @action
  goToHubOnCreatePassword() {
    this.router.transitionTo('portal.hub');
    return false;
  }

  @action
  logoutApiAction() {
    this.store.peekRecord('session', 0).logout().then((data, status, xhr) => {
      let loginRoute;
      if (this.shoppingPassLoan) {
        loginRoute = 'account.hdlogin';
      } else {
        loginRoute = 'account.login';
      }

      // FIP-27372: Clear Records related to the loan from the Store when user logs out
      this.store.unloadAll();

      return this.transitionToRoute(loginRoute);
    });

    return false;
  }
}

export default PortalController;
