import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
class PortalPaymentOptionsErrorRoute extends Route {
  @service
  intl;

  beforeModel() {
    document.title = this.intl.t('payment_options.title');
  }
}

export default PortalPaymentOptionsErrorRoute;
