import FormFieldComponent from './ui-form-field'
import { action } from '@ember/object'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'

@uiFormFieldValue
export default class UiToggleComponent extends FormFieldComponent {
  get toggleOnText() {
    return this.args.toggleOnText ?? ''
  }

  get toggleOffText() {
    return this.args.toggleOffText ?? ''
  }

  get bgColor() {
    if (this.checked) {
      return 'interactive-dark'
    }
    return 'ui-gray-100'
  }

  get checked() {
    return !!this.value
  }

  get textColor() {
    if (this.checked) {
      return 'white'
    }
    return 'ui-black-100'
  }

  get toggleText() {
    if (this.checked) {
      return this.toggleOnText.toUpperCase()
    } else {
      return this.toggleOffText.toUpperCase()
    }
  }

  @action
  onClick(event) {
    this.value = event.target.checked

    this.args.onClick?.(event)
  }
}
