function setTransitionOriginator(transition, model, routeName) {
  // this.router.generate(this.routeName, model) also produces the resource path
  // but causes Route errors trying to go forwards after going back
  const originator = '/' + routeName.replace('.', `/${model.login_key}/`)
  transition.originator = originator

  return true
}

export default setTransitionOriginator;
