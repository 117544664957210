// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { helper as buildHelper } from '@ember/component/helper';

const PercentizeHelper = buildHelper((value) => value.toString().percentize());

export default PercentizeHelper;
