import Component from '@glimmer/component'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { action } from '@ember/object'
import { service } from '@ember/service'

export default class AccountLoginComponent extends Component {
  @service intl
  /** @type {import('financeit-components/app/services/mobile').default} */
  @service mobile
  /** @type {import('direct/services/bioauth').default} */
  @service bioauth

  @action
  showModal(id) {
    UiModalUtils.showModal(id)
  }

  get mobileLoginSelectionHref() {
    return `/${this.intl.language}/mobile-login-selection`
  }

  get isEnvironmentPickerString() {
    if (this.mobile.isMobileApp) {
      return this.args.model.get('email') === 'financeit'
    }

    return false
  }

  get bioauthCtaText() {
    if (this.bioauth.isFaceIdEnrolled) {
      return this.intl.t('bioauth.login.login_button_face')
    } else if (this.bioauth.isTouchIdEnrolled) {
      return this.intl.t('bioauth.login.login_button_touch')
    }

    return ''
  }
}
