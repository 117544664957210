import Component from '@glimmer/component'
import { scheduleOnce, run } from '@ember/runloop'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class UiModalComponent extends Component {
  get size() {
    return this.args.size ?? 'md'
  }
  get backdrop() {
    return this.args.backdrop ?? true
  }
  get priority() {
    return this.args.priority ?? false
  }

  get zIndex() {
    return this.priority ? 30 : 20
  }

  constructor() {
    super(...arguments)

    scheduleOnce('afterRender', this, this.handleMouseDown)
  }

  handleMouseDown() {
    // this code prevents closing the modal when the user clicks in the modal and drags to the outside
    const modalElement = document.getElementById(this.args.id)
    modalElement.addEventListener('mousedown', mouseDownEvent => {
      const onMouseUp = mouseUpEvent => {
        if (mouseDownEvent.target !== mouseUpEvent.target) {
          run(() => {
            this.ignoreBackdropClick = true
          })
        }
        modalElement.removeEventListener('mouseup', onMouseUp)
      }

      modalElement.addEventListener('mouseup', onMouseUp)
    })
  }

  handleClose() {
    if (this.args.onClose) {
      return this.args.onClose()
    }

    closeModal(this.args.id)
  }

  @action
  onBackdropClick(event) {
    if (this.ignoreBackdropClick) {
      this.ignoreBackdropClick = false
      return
    }

    if (this.backdrop === 'static') {
      return
    }

    if (event.target.classList.contains('ui-modal__overlay')) {
      this.handleClose()
    }
  }

  @action
  onClose() {
    this.handleClose()
  }
}
