import classic from 'ember-classic-decorator';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Route from '@ember/routing/route';

@classic
export default class NoneFoundRoute extends Route {
  model() {
    return this.controllerFor('loan').get('model');
  }
}
