import Component from '@glimmer/component'

export default class UiDropdownComponent extends Component {
  get horizontalPosition() {
    return this.args.horizontalPosition ?? 'auto-right'
  }

  get verticalPosition() {
    return this.args.verticalPosition ?? 'below'
  }
}
