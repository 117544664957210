import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"tw-space-y-{{this.verticalSpacing}}\">\n  <div>\n    <UiText @tagName=\"p\" @text={{t \"insurance.life\"}} @textSize=\"md\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.life_description\"}} @textSize=\"sm\" />\n  </div>\n  <div>\n    <UiText @tagName=\"p\" @text={{t \"insurance.disability\"}} @textSize=\"md\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.disability_description\" }} @textSize=\"sm\" />\n  </div>\n  <div>\n    <UiText @tagName=\"p\" @text={{t \"insurance.unemployment\"}}  @textSize=\"md\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.unemployment_description\"}} @textSize=\"sm\" />\n  </div>\n  {{#unless @inLearnMoreModal}}\n    <UiButton @kind=\"basic\" @size=\"sm\" @onClick={{fn this.showModal @paymentProtectionModalId}} @text={{t \"apply.learn_more\"}} />\n  {{/unless}}\n</div>\n", {"contents":"<div class=\"tw-space-y-{{this.verticalSpacing}}\">\n  <div>\n    <UiText @tagName=\"p\" @text={{t \"insurance.life\"}} @textSize=\"md\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.life_description\"}} @textSize=\"sm\" />\n  </div>\n  <div>\n    <UiText @tagName=\"p\" @text={{t \"insurance.disability\"}} @textSize=\"md\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.disability_description\" }} @textSize=\"sm\" />\n  </div>\n  <div>\n    <UiText @tagName=\"p\" @text={{t \"insurance.unemployment\"}}  @textSize=\"md\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.unemployment_description\"}} @textSize=\"sm\" />\n  </div>\n  {{#unless @inLearnMoreModal}}\n    <UiButton @kind=\"basic\" @size=\"sm\" @onClick={{fn this.showModal @paymentProtectionModalId}} @text={{t \"apply.learn_more\"}} />\n  {{/unless}}\n</div>\n","moduleName":"direct/components/insurance/type-details.hbs","parseOptions":{"srcName":"direct/components/insurance/type-details.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'
import { showModal } from 'tag/utils/ui-modal'

export default class TypeDetailsComponent extends Component {
  get verticalSpacing() {
    return this.args.inLearnMoreModal ? '3' : '5'
  }

  @action
  showModal(modalId) {
    showModal(modalId)
  }
}
