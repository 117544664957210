import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'

export default class UiTableArrayComponent extends Component {
  constructor() {
    super(...arguments)

    // displays the icon for the currently sorted column (up, down, or up & down)
    this._setupInitialSortForColumns({
      columns: this.args.columns,
      sortProperty: this.args.sortProperty,
      sortDirection: this.args.sortDirection
    })
  }

  // default page to load is the first page, unless otherwise overriden
  @tracked currPage = this.args.initialPage ?? 1

  _setupInitialSortForColumns({ columns, sortProperty, sortDirection }) {
    if (sortProperty) {
      const sortedColumn = columns.find(column => {
        return column.propertyName === sortProperty || column.sortedBy === sortProperty
      })

      if (sortedColumn) {
        sortedColumn.sortDirection = sortDirection ?? 'asc'
        sortedColumn.sortPrecedence = 1
      }
    }
  }
}
