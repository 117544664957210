import Service, { inject as service } from '@ember/service'

export default class LoanStepSlideService extends Service {
  @service router

  to(route) {
    document.querySelector('html').classList.remove('stepOpen')
    const stepElement = document.getElementsByClassName('step')[0]
    if (stepElement) stepElement.style.transform = 'translateX(100vw)'
    setTimeout((() => {
      this.router.transitionTo(route).then(() => {
        const stepWrapperElement = document.getElementsByClassName('stepWrapper')[0]
        if (stepWrapperElement) stepWrapperElement.style.transform = 'translateX(0vw)'
      })
    }), 400)
  }
}
