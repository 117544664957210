import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/use-brace-expansion,
    ember/no-incorrect-calls-with-inline-anonymous-functions,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
import { isPresent } from '@ember/utils';
import PortalOffersController from '../offers';
import { formatAsCurrency } from 'tag/utils/number-formatters'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { showModal } from 'tag/utils/ui-modal'
import { scheduleOnce } from '@ember/runloop';
import { htmlSafe } from '@ember/template';

@classic
class PortalOffersOfferApprovedController extends PortalOffersController {
  @service
  intl;

  @service
  router;

  @service store

  queryParams = ['isDeclinedCobo'];
  isDeclinedCobo = false;

  showCoboDeclinedModal() {
    if (!this.isDeclinedCobo) {
      return;
    }

    return scheduleOnce('afterRender', this, function() {
      return showModal('declined_cobo')
    });
  }

  @action
  showModal(id) {
    UiModalUtils.showModal(id)
  }

  setTitle() {
    document.title = this.intl.t('offer_approved.youre_approved', {
      status: this.approvedStatus
    })
  }

  @computed('isQuickfund', 'loan.{approvalConditions,isShoppingPass}', 'loanController.fundingConditionVariables')
  get poiCondition() {
    const condition = [];
    const proofOfIncomeKey = this.get('loan.isShoppingPass') ? 'proof_of_income_shopping_pass' : 'proof_of_income';
    const proofOfIncomeCondition = this.intl.t(`approval_conditions.${proofOfIncomeKey}`, this.get('loanController.fundingConditionVariables'));

    if (this.get('loan.approvalConditions').indexOf('proof_of_income') > -1) {
      condition.push(htmlSafe(proofOfIncomeCondition));
    }

    return condition;
  }

  @computed(
    'isQuickfund', 'loan.approvalConditions', 'loanController.{filteredFundingConditions,fundingConditionVariables}', 'poiCondition'
  )
  get fundingConditions() {
    const conditions = this.get('loanController.filteredFundingConditions').slice();
    return this.poiCondition.concat(conditions);
  }

  @computed('fundingConditions.length')
  get anyFundingConditions() {
    return this.fundingConditions.length > 0;
  }

  @computed('loan.displayExpiryDate')
  get expiryDateText() {
    if (isPresent(this.get('loan.displayExpiryDate'))) { return `${this.intl.t('offer_expiry')} ${moment(this.get('loan.displayExpiryDate')).format('MMM D, YYYY')}`; } else { return ''; }
  }

  @computed('approvedStatus', 'isCounterOffer', 'loan.isSubprime')
  get approvedHeading() {
    if (this.isCounterOffer) {
      return this.intl.t('offer_approved.counter_offer_header')
    } else {
      return this.intl.t('offer_approved.approved_header', {
        approvedMessage: this.approvedStatus
      });
    }
  }

  @computed('loan.approvedMessage')
  get approvedStatus() {
    return this.loan.approvedMessage.toLowerCase()
  }

  @computed('loan.{northStarStreamlinedFlowEligible,isSubprime,isCounterOffer,maxAmountToShow,creditLimit}', 'approvedStatus')
  get approvedAmountMessage() {
    const params = {
      maxAmount: formatAsCurrency(this.loan.maxAmountToShow, { precision: 2}),
      creditLimit: formatAsCurrency(this.loan.creditLimit, { precision: 2 }),
      status: this.approvedStatus
    }

    if (this.loan.isCounterOffer) {
      return this.intl.t('offer_approved.approved_message_counter_offer', {
        ...params,
        program: this.loan.isSubprime ? this.intl.t('offer_approved.programs.subprime') : this.intl.t('offer_approved.programs.extended_credit')
      })
    }
    if (this.loan.northStarStreamlinedFlowEligible) {
      if (parseFloat(this.loan.maxAmountToShow) <= parseFloat(this.loan.creditLimit)) {
        return this.intl.t('offer_approved.amount_equal_to_credit_limit_message', params)
      } else {
        return this.intl.t('offer_approved.streamline_credit_limit_message_html', params)
      }
    } else {
      return this.intl.t('offer_approved.approved_message', params)
    }
  }

  @computed(
    'approvedAmountMessage',
    'loan.{northStarStreamlinedFlowEligible,creditLimit}'
  )
  get approvedMessages() {
    const messages = [this.approvedAmountMessage]

    if (this.loan.northStarStreamlinedFlowEligible) {
      messages.push(this.intl.t('offer_approved.responsible_message'))
    }

    return htmlSafe(messages.join(' '))
  }

  @computed(
    'loan.{currentRate,isApprovedConditionally,isSubprime,maxAmount}', 'loanController.hasPurchaseAmount'
  )
  get prequalifiedApprovedMessage() {
    if (this.get('loanController.hasPurchaseAmount')) {
      return this.intl.t('offer_approved.prequalified_counter_offer_with_amount', {
        program: this.loan.isSubprime ? this.intl.t('offer_approved.second_look_advantage') : this.intl.t('offer_approved.extended_credit'),
        amount: formatAsCurrency(this.loan.maxAmount, { precision: 2 }),
        conditions: this.loan.isApprovedConditionally ? this.intl.t('offer_approved.with_conditions') : null
      })
    } else {
      return this.intl.t('offer_approved.prequalified_counter_offer', {
        program: this.loan.isSubprime ? this.intl.t('offer_approved.second_look_advantage') : this.intl.t('offer_approved.extended_credit'),
        rate: this.loan.currentRate
      })
    }
  }

  @computed('isCounterOffer', 'loan.addCoborrowerAvailable')
  get showAddCoborrower() {
    return this.isCounterOffer && this.loan.addCoborrowerAvailable
  }

  @computed('loan.{extendedCredit,isSubprime}')
  get isCounterOffer() {
    return this.loan.extendedCredit || this.loan.isSubprime;
  }
}

export default PortalOffersOfferApprovedController;
