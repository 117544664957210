import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Hub::LoanSelectionModal\n  @loans={{this.loans}}\n  @setCurrentlySelectedLoan={{this.setCurrentlySelectedLoan}}\n  @showSpinner={{this.showSpinner}}\n  @errorMessage={{this.errorMessage}}/>\n<div id=\"loan-selection\" class=\"lg:tw-w-2/3 sm:tw-w-full tw-mb-3\">\n  <OptionCard::Panel\n    @loanName={{this.currentlySelectedLoan.name}}\n    @loanSelectionHeading={{this.panelLoanSelectionHeading}}\n    @onClick={{this.showLoanSelectionModal}}\n  />\n</div>", {"contents":"<Hub::LoanSelectionModal\n  @loans={{this.loans}}\n  @setCurrentlySelectedLoan={{this.setCurrentlySelectedLoan}}\n  @showSpinner={{this.showSpinner}}\n  @errorMessage={{this.errorMessage}}/>\n<div id=\"loan-selection\" class=\"lg:tw-w-2/3 sm:tw-w-full tw-mb-3\">\n  <OptionCard::Panel\n    @loanName={{this.currentlySelectedLoan.name}}\n    @loanSelectionHeading={{this.panelLoanSelectionHeading}}\n    @onClick={{this.showLoanSelectionModal}}\n  />\n</div>","moduleName":"direct/components/hub/loan-selection.hbs","parseOptions":{"srcName":"direct/components/hub/loan-selection.hbs"}});
import Component from '@glimmer/component'
import { inject as service } from '@ember/service';
import * as UiModalUtils from 'tag/utils/ui-modal'
import { action } from '@ember/object';
import { tracked } from "@glimmer/tracking";

export default class LoanSelectionComponent extends Component {
  @service intl;
  @service store;
  @service router;

  loanSelectionModalId = 'loan-selection-modal'
  @tracked showSpinner = false;
  @tracked errorMessage = null;

  @tracked loans = null;
  @tracked currentlySelectedLoan = null;
  @tracked currentlySelectedPartner = null;

  constructor () {
    super(...arguments);
    this.currentlySelectedLoan = this.store.peekAll('loan').objectAt(0);
    this.currentlySelectedPartner = this.store.peekAll('partner').objectAt(0);
  }

  get selectedPartnerBusinessName() {
    return this.currentlySelectedPartner.businessName ?? this.currentlySelectedPartner.business_name
  }

  get panelLoanSelectionHeading() {
    if (this.currentlySelectedLoan.isCreditTopUp) {
      return this.intl.t('hub.personal_loan');
    } else {
      return this.selectedPartnerBusinessName;
    }
  }

  modalLoanSelectionHeading(loan) {
    if (loan.is_credit_top_up) {
      return this.intl.t('hub.personal_loan');
    } else {
      return loan.partner_name;
    }
  }

  @action
  async showLoanSelectionModal() {
    UiModalUtils.showModal(this.loanSelectionModalId)
    this.session = null
    const loginKey = this.store.peekAll('loan').objectAt(0).loginKey;

    this.showSpinner = true;

    try {
      const data = await this.store.peekRecord('session', 0).loans({login_key: loginKey});
      this.loans = data;
      this.loans.loans.forEach(loan => { loan['loan_selection_heading'] = this.modalLoanSelectionHeading(loan)})
    } catch(e) {
      this.errorMessage = e.errors[0];
    }

    this.showSpinner = false;
  }

  @action
  closeModal(id) {
    UiModalUtils.closeModal(id);
  }

  @action
  async setCurrentlySelectedLoan(item) {
    window.location = this.router.urlFor('portal.hub', item);
  }
}
