import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class BasicNavigationComponent extends Component {
  @service intl
  @service router

  get switchLanguageUrl() {
    const locale = this.intl.language === 'en' ? 'fr' : 'en'
    return `/${locale}/direct${this.router.currentURL}`
  }

  get borderClasses() {
    if (this.args.shoppingPassLoan) {
      return 'tw-border-b-2 tw-border-interactive-100'
    }

    return 'tw-border-b tw-border-ui-gray-60'
  }
}
