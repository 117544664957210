import Controller from '@ember/controller'
import { action } from '@ember/object'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'

export default class CreditTopUpApplyController extends Controller {
  @service intl;
  @service router;
  @tracked showSpinner = false

  queryParams = ['offerId', 'source']
  source = null;
  offerId = null;

  @action
  showCtuLearnMoreModal() {
    UiModalUtils.showModal('ctu-learn-more-modal')
  }

  @action
  showQuoteCardLearnMoreModal() {
    UiModalUtils.showModal('learn-more-modal')
  }

  @action
  async createLoan() {
    this.showSpinner = true;

    try {
      await this.attemptBureauRepull();
    } catch {
      return this.router.transitionTo('credit-top-up.application-decline')
    }

    try {
      const response = await this.model.offer.import({ source: this.source })
      this.showSpinner = false
      if (response.backend_id) {
        return this.router.transitionTo('account.new', {
          queryParams: { backendId: response.backend_id },
        }).catch((error) => {
          if (error.name === 'TransitionAborted') {
            return;
          }
        });
      } else {
        return this.router.transitionTo('portal.hub', response.login_key).catch((error) => {
          if (error.name === 'TransitionAborted') {
            return;
          }
        });
      }
    } catch (error) {
      if (error.errors[0].meta.errors.credit_score_error) {
        return this.router.transitionTo('credit-top-up.application-decline', { queryParams: { creditScoreError: true }})
      } else {
        return this.router.transitionTo('credit-top-up.application-decline')
      }
    }
  }

  async attemptBureauRepull() {
    const response = await this.model.offer.attemptBureauRepull()

    if (response.repull) {
      await this.holdForBureauRepull()
    }
  }

  async holdForBureauRepull() {
    try {
      const response = await this.model.offer.holdForBureauRepull()
      if (response.status === 'keep_polling') {
        await new Promise(res => {
          setTimeout(res, 1000)
        });
        await this.holdForBureauRepull()
      }
    } catch {
      throw new Error('Bureau Error')
    }
  }
}
