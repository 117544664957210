import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
class ShoppingPassDashboardIndexRoute extends Route {
  @service
  intl;

  async beforeModel(transition) {
    // eslint-disable-next-line ember/no-jquery
    return $(document).attr('title', this.intl.t('dashboard.title'));
  }
}

export default ShoppingPassDashboardIndexRoute;
