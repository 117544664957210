import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
/* eslint-disable
    ember/no-jquery
*/
import { isBlank, isPresent, isNone } from '@ember/utils';
import Route from '@ember/routing/route';

@classic
class PaymentPlanRoute extends Route {
  @service
  intl;

  @service store
  @service router

  beforeModel(transition) {
    if (navigator.cookieEnabled === false) {
      return this.router.transitionTo('cookies-disabled');
    }

    const { key } = this.paramsFor('payment-plan');
    if (isBlank(key)) {
      return this.router.transitionTo('error');
    } else {
      const invite_id = transition.to.queryParams['iid'];
      const { lead_id } = transition.to.queryParams;
      const requestedAmount = transition.to.queryParams.p;
      const amountIsFixed = transition.to.queryParams.fixed_p;
      const vehicleType = transition.to.queryParams['vehicle_type'];
      if (isPresent(requestedAmount)) { this.controllerFor('subvention').set('requestedAmount', requestedAmount); }
      if (isPresent(amountIsFixed)) { this.controllerFor('subvention').set('amountIsFixed', amountIsFixed); }
      if (isPresent(vehicleType)) { this.controllerFor('subvention').set('vehicleType', vehicleType); }

      return $.getJSON(
        `/${this.intl.language}/api/v3/direct/partner_promos/${key}?iid=${invite_id}&lead_id=${lead_id}&amount=${requestedAmount}`
      ).then(data => {
        let model;
        this.controllerFor('subvention').set('stagedFunding', data.staged_funding);
        this.controllerFor('subvention').set('borrowerFeePercent', data.borrower_fee_percent);
        this.controllerFor('subvention').set('borrowerMaxFee', data.borrower_max_fee);
        // Initialize subvention singleton
        if (data.subvention_program_item.id === 0) {
          this.controllerFor('subvention').set('standard', data.subvention_program_item);
        } else {
          this.controllerFor('subvention').set('promo', data.subvention_program_item);
        }

        // Push the subvention to the ember data store
        const subventionProgramItemId = parseInt(data.subvention_program_item.id, 10)
        this.store.pushPayload('subvention', { subvention: { id: subventionProgramItemId }})

        // Initialize vertical singleton
        this.store.pushPayload('vertical', { vertical: data.vertical });
        this.controllerFor('subvention').set('vertical', data.vertical);

        // If partner model has yet to be loaded into store
        if (isNone(this.controllerFor('partner').get('model'))) {
          this.store.pushPayload('partner', { partner: data.partner });
          model = this.store.find('partner', data.partner.id);
          this.controllerFor('partner').set('model', model);
        }

        if (data.loan) {
          this.store.pushPayload('loan', { loan: data.loan });
          model = this.store.find('loan', data.loan.id);
          this.controllerFor('loan').set('model', model);
        }

        // Is a shareable link (no existing quote)
        if (isBlank(invite_id)) {
          const partner = this.store.peekRecord('partner', data.partner.id)
          const subvention = this.store.peekRecord('subvention', subventionProgramItemId)

          this.store.createRecord('quote', {
            feesAbsorbed: partner.get('absorbBorrowerFees') === 'all',
            feesWaived: partner.get('adminFeesWaived'),
            partner: partner,
            paymentFrequency: transition.to.queryParams.pf ? transition.to.queryParams.pf : partner.get('defaultPaymentFrequency'),
            quebec: data.worksite_quebec,
            selectedPartnerId: partner.id,
            subvention: subvention,
            useMaxAmortization: !isPresent(transition.to.queryParams.a),
            amortization: transition.to.queryParams.a ? parseInt(transition.to.queryParams.a) : null,
            vertical: this.store.peekRecord('vertical', data.vertical.id),
            amount: transition.to.queryParams.p ? parseInt(transition.to.queryParams.p, 10) : null,
          })
        }

        // Setup view model for creation form
        const defaultPaymentType = this.controllerFor('subvention').get('vertical.default_payment_type');
        this.controllerFor('borrower').set('model', this.store.createRecord('borrower', {
          paymentType: defaultPaymentType,
          borrowerType: "Borrower",
          noMiddleName: false,
          approvalNoAmount: data.borrower.approvalNoAmount,
          collectCreditLimit: data.borrower.collectCreditLimit,
          verticalLimit: data.borrower.verticalLimit,
        }));

        return this.controllerFor('session').set('key', key);
      }, () => {
        return this.router.transitionTo('error');
      }).then(() => {
        if ((transition.to.name !== "payment-plan.invite-expired") && isPresent(invite_id)) {
          return this.transitionIfInviteExpired(invite_id);
        }
      });
    }
  }

  afterModel() {
    const { pendo, pendoData } = window;
    if (pendo && pendoData) {
      const loanVertical = this.controllerFor('subvention').get('vertical.name');
      const newPendoData = $.extend(true, {}, pendoData);
      newPendoData.visitor.loanVertical = loanVertical;
      newPendoData.visitor.productLine = this.controllerFor('partner').get('model.loanManagingGroupName');
      return pendo.updateOptions(newPendoData);
    }
  }

  transitionIfInviteExpired(inviteId) {
    return $.getJSON(
      `/${this.intl.language}/api/v3/direct/invites/${inviteId}/validate`
    ).then(data => {
      if (!data.valid) {
        return this.router.transitionTo('payment-plan.invite-expired');
      }
    }, () => {
      return this.router.transitionTo('error');
    });
  }
}

export default PaymentPlanRoute;
