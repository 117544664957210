import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import PortalController from '../portal';

@classic
class PortalMyDocumentsController extends PortalController {
  @service
  intl;

  @computed('loan.loanSteps.content')
  get documentsArchiveItems() {
    const archiveItems = [];

    this.get('loan.loanSteps.content').forEach(item => {
      if (item.get('signedDocsUrl')) {
        return archiveItems.push({
          isConsent: false,
          title: this.intl.t(`hub.view_completed_document_name.${item.get('name')}`),
          date: moment(item.get('updatedAt')).format('D MMM YYYY'),
          description: item.get('signedDocsUrl')
        });
      }
    });
    return archiveItems;
  }

  @computed('loan.model.documents', 'model.documents')
  get modelDocuments() {
    const documents = [];

    this.get('model.documents').forEach(item => {
      documents.push({
        isConsent: false,
        title: this.intl.t(`hub.documents.${item.document_name}`),
        date: moment(item.document_date).format('D MMM YYYY'),
        description: item.document_url
      });
    });
    return documents;
  }

  // Refer to FIP-31463. This will not be required after
  // 6 months from 10th Feb 2020 (Econsent deployment)
  @computed('loan.{hdLoanBeforeEconsentDeployed,applicationCreatedAt}')
  get econsentDisclosureForCreditIssuedState() {
    if (this.get('loan.hdLoanBeforeEconsentDeployed')) {
      return ({
        isConsent: false,
        title: this.intl.t("hub.econsent_disclosure_title"),
        date: moment(this.get('loan.applicationCreatedAt')).format('D MMM YYYY'),
        description: this.intl.t("hub.econsent_disclosure_description")
      });
    } else {
      return null;
    }
  }

  @computed('session.loggedInUser.esignConsentHistory')
  get borrowerConsentHistory() {
    const consentHistory = [];

    this.get('session.loggedInUser.esignConsentHistory').forEach(item => {
      let disclosure, status;
      let effective_date = moment(item.created_at);

      if ((effective_date.day() === 5) || (effective_date.day() === 6)) {
        effective_date = effective_date.day(8);
      } else {
        effective_date = effective_date.add('d', 1);
      }

      if (item.consented === true) {
        status = this.intl.t("hub.documents.give_econsent_disclosure");
        disclosure = this.intl.t("hub.documents.electronic_disclosure_consent_given", { effect_date: effective_date.format('MMM D, YYYY') });
      } else {
        status = this.intl.t("hub.documents.revoke_econsent_disclosure");
        disclosure = this.intl.t("hub.documents.electronic_disclosure_consent_revoked", { effect_date: effective_date.format('MMM D, YYYY') });
      }

      return consentHistory.push({
        isConsent: true,
        title: status,
        date: moment(item.updated_at).format('D MMM YYYY'),
        description: disclosure
      });
    });
    return consentHistory.reverse();
  }

  @computed(
    'econsentDisclosureForCreditIssuedState',
    'documentsArchiveItems',
    'borrowerConsentHistory',
    'modelDocuments'
  )
  get allDocuments() {
    const documents = [];
    if (!isEmpty(this.borrowerConsentHistory)) Array.prototype.push.apply(documents,this.borrowerConsentHistory);
    if (!isEmpty(this.documentsArchiveItems)) Array.prototype.push.apply(documents,this.documentsArchiveItems);
    if (!isEmpty(this.econsentDisclosureForCreditIssuedState)) Array.prototype.push.apply(documents,this.econsentDisclosureForCreditIssuedState);
    if (!isEmpty(this.modelDocuments)) Array.prototype.push.apply(documents,this.modelDocuments);
    documents.sort(function(a,b){
      var dateA = new Date(a.date), dateB = new Date(b.date);
      return dateB - dateA;
    });

    return documents;
  }
}

export default PortalMyDocumentsController;
