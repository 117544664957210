import Controller from '@ember/controller';
import { service } from '@ember/service'
import { htmlSafe } from '@ember/template'

export default class CreditTopUpApplicationDeclineController extends Controller {
  @service intl;

  queryParams = ['creditScoreError']
  creditScoreError = false;

  get errorBody() {
    if (this.creditScoreError) {
      return this.intl.t('credit_top_up.application_decline.credit_score_error')
    } else {
      return htmlSafe(this.intl.t('credit_top_up.apply.more_info_html'))
    }
  }
}
