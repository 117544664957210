import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.bioauth.isBioauthSupported}}\n  <UiToggle\n    @name=\"bioauth\"\n    @value={{this.isEnabled}}\n    @onValueChange={{this.toggleBioauth}}\n    @toggleOnText={{t \"bioauth.settings.toggle_on\"}}\n    @toggleOffText={{t \"bioauth.settings.toggle_off\"}}\n  >\n    <UiText\n      @text={{this.labelText}}\n      @textSize=\"sm\"\n    />\n  </UiToggle>\n{{/if}}\n", {"contents":"{{#if this.bioauth.isBioauthSupported}}\n  <UiToggle\n    @name=\"bioauth\"\n    @value={{this.isEnabled}}\n    @onValueChange={{this.toggleBioauth}}\n    @toggleOnText={{t \"bioauth.settings.toggle_on\"}}\n    @toggleOffText={{t \"bioauth.settings.toggle_off\"}}\n  >\n    <UiText\n      @text={{this.labelText}}\n      @textSize=\"sm\"\n    />\n  </UiToggle>\n{{/if}}\n","moduleName":"direct/components/bioauth/toggle.hbs","parseOptions":{"srcName":"direct/components/bioauth/toggle.hbs"}});
import Component from '@glimmer/component'
import { service } from '@ember/service'
import { action } from '@ember/object'

export default class BioauthToggleComponent extends Component {
  /** @type {import('direct/services/bioauth').default} */
  @service bioauth
  @service intl

  get isEnabled() {
    return this.bioauth.isBioauthEnrolled
  }

  get labelText() {
    if (this.bioauth.isFaceIdEligible || this.bioauth.isFaceIdEnrolled) {
      return this.intl.t('bioauth.settings.label_face')
    } else if (this.bioauth.isTouchIdEligible || this.bioauth.isTouchIdEnrolled) {
      return this.intl.t('bioauth.settings.label_touch')
    }

    return ''
  }

  @action
  async toggleBioauth(isEnabled) {
    return this.bioauth.toggleBioauth(isEnabled)
  }

  @action
  resetBioauth() {
    document.dispatchEvent(new CustomEvent('resetBioauth'))
  }
}
