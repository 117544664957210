import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
class PortalPaymentReviewConfirmationRoute extends Route {
  @service
  intl;

  model() {
    return this.modelFor('portal.payment.review.index')
  }

  beforeModel() {
    document.title = this.intl.t('make_payment.thank_you');
  }
}

export default PortalPaymentReviewConfirmationRoute;
