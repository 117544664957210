import Component from '@glimmer/component'
import { isPresent } from '@ember/utils'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { next } from '@ember/runloop'

export default class UiTableServerPaginatedComponent extends Component {
  constructor() {
    super(...arguments)

    // displays the icon for the currently sorted column (up, down, or up & down)
    next(() =>
      this._setupInitialSortForColumns({
        columns: this.args.columns,
        query: this.args.data.query
      })
    )
  }

  // the query parameters to use for server side filtering / querying
  filterQueryParameters = {
    sort: 'sort',
    sortDirection: 'sortDirection',
    page: 'page[number]',
    pageSize: 'page[size]'
  }

  // the meta property on the server response to fetch record count
  metaItemsCountProperty = 'recordCount'

  // time interval between page change / sort and requesting new info from server
  debounceDataLoadTime = 0

  // default page to load is the first page, unless otherwise overriden
  @tracked currPage = this.args.data.query.page?.number ?? 1

  get pageSize() {
    return this.args.data.query.page?.size
  }

  // massage the query sent to the backend to set sort properly
  setQueryFilter(query, column) {
    if (column.sortDesc) query.sort = `-${query.sort}`

    if (column.filteredBy) {
      if (!query.filter) {
        query.filter = {}
      }

      if (isPresent(column.filterString)) {
        query.filter[column.filteredBy] = column.filterString
      } else {
        delete query.filter[column.filteredBy]
      }
    }
  }

  _setupInitialSortForColumns({ columns, query }) {
    if (query.sort) {
      let sortParts = query.sort.match(/(-?)(.*)/)
      let sortDirection = sortParts[1] === '-' ? 'desc' : 'asc'
      let sortProperty = sortParts[2]

      const sortedColumn = columns.find(column => {
        return column.propertyName === sortProperty || column.sortedBy === sortProperty
      })

      if (sortedColumn) {
        sortedColumn.sortDirection = sortDirection
        sortedColumn.sortPrecedence = 1
      }
    }
  }

  /**
   * v4 of ember-models-table broke the previous filtering mechanism, so we need to fix it here. This is a
   * horrible hack that should be eventually rethought.
   */
  fixQueryFilters(query) {
    this.args.filters?.forEach(filter => {
      if (query[filter.filteredBy] !== undefined) {
        if (!query.filter) {
          query.filter = {}
        }
        query.filter[filter.filteredBy] = query[filter.filteredBy]
        delete query[filter.filteredBy]
      }
    })

    if (query['page[number]'] !== undefined) {
      if (!query.page) {
        query.page = {}
      }

      query.page.number = query['page[number]']
    }

    if (query['page[size]'] !== undefined) {
      if (!query.page) {
        query.page = {}
      }

      query.page.size = query['page[size]']
    }

    if (query.sort && query.sortDirection) {
      query.sort = `${query.sortDirection === 'asc' ? '' : '-'}${query.sort}`
    }

    return query
  }

  @action
  async doQuery(query) {
    query = this.fixQueryFilters(query)

    return this.args.doQuery(query)
  }
}
