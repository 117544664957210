import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import PortalController from '../../portal';

@classic
export default class ErrorController extends PortalController {
  @service
  intl;

  @computed('bankingErrorDescription', 'bankingErrorTitle')
  get errorMessage() {
    if (isPresent(this.bankingErrorDescription)) {
      return {
        title: this.bankingErrorTitle,
        description: this.bankingErrorDescription,
      };
    } else {
      return {
        title: this.intl.t('hub.banking_error.generic_title'),
        description: this.intl.t('hub.banking_error.generic_description')
      };
    }
  }

  @action
  returnHome() {
    return this.transitionToRoute('portal.funded', this.get('loan.loginKey'));
  }
}
