import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"tw-overflow-auto\">\n  <UiTableArray\n    @data={{this.rows}}\n    @columns={{this.columns}}\n    @paginationType=\"none\"\n    @selectRowOnClick={{false}}\n    @expandedRowOptions={{hash\n      component=\"ui-table-array/expandable-rows/status-and-balance\"\n      className=\"tw-table-cell sm:tw-hidden\"\n    }}\n  />\n</div>\n", {"contents":"<div class=\"tw-overflow-auto\">\n  <UiTableArray\n    @data={{this.rows}}\n    @columns={{this.columns}}\n    @paginationType=\"none\"\n    @selectRowOnClick={{false}}\n    @expandedRowOptions={{hash\n      component=\"ui-table-array/expandable-rows/status-and-balance\"\n      className=\"tw-table-cell sm:tw-hidden\"\n    }}\n  />\n</div>\n","moduleName":"direct/components/portal/shopping-pass-dashboard/transaction-table.hbs","parseOptions":{"srcName":"direct/components/portal/shopping-pass-dashboard/transaction-table.hbs"}});
import Component from '@glimmer/component'
import {inject as service} from '@ember/service'

export default class ShoppingPassTransactionTableComponent extends Component {
  @service intl

  get rows() {
    return this.args.data.slice(0, this.args.maxRows)
  }

  columns = [
    {
      title: this.intl.t('hub.transactions.date'),
      propertyName: 'date',
      disableSorting: true,
      component: 'ui-table-array/cells/date',
      columnHeaderClassNames: 'tw-text-xs',
      className: 'tw-w-1/3 sm:tw-w-1/6',
      componentProperties: {
        textSize: 'xs'
      }
    },
    {
      title: this.intl.t('hub.transactions.status'),
      propertyName: 'status',
      disableSorting: true,
      component: 'ui-table-array/cells/badge',
      columnHeaderClassNames: 'tw-text-xs tw-ml-4',
      className: 'tw-hidden sm:tw-table-cell tw-w-1/6 tw-whitespace-no-wrap'
    },
    {
      title: this.intl.t('hub.transactions.description'),
      propertyName: 'description',
      disableSorting: true,
      columnHeaderClassNames: 'tw-text-xs',
      className: 'tw-w-7/12 sm:tw-w-1/3',
      componentProperties: {
        textSize: 'xs',
      }
    },
    {
      title: this.intl.t('hub.transactions.amount'),
      propertyName: 'amount',
      disableSorting: true,
      component: 'ui-table-array/cells/money',
      columnHeaderClassNames: 'tw-text-xs tw-justify-end',
      className: 'tw-w-1/12 sm:tw-w-1/6 tw-whitespace-no-wrap tw-text-right',
      componentProperties: {
        textSize: 'sm'
      }
    },
    {
      title: this.intl.t('hub.transactions.balance'),
      propertyName: 'balance',
      disableSorting: true,
      component: 'ui-table-array/cells/money',
      columnHeaderClassNames: 'tw-text-xs tw-justify-end',
      className: 'tw-hidden sm:tw-table-cell tw-w-1/6 tw-whitespace-no-wrap tw-text-right',
      componentProperties: {
        textSize: 'sm'
      }
    }
  ]
}
