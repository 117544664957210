import Service from '@ember/service';

export default class SnowplowService extends Service {
  constructor() {
    super(...arguments);

    this.formTrackingConfig = {
      allowlist: ['snowplow-tracked'],
      fields: {
        filter: function (elt) {
          return elt.classList.contains('snowplow-tracked');
        }
      }
    };
  }

  snowplow() {
    if (window.snowplow) {
      return window.snowplow(...arguments);
    }
  }

  enableFormTracking(options = this.formTrackingConfig) {
    return this.snowplow('enableFormTracking', { options });
  }
}
