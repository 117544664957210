import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import PortalProfileIndexController from '../../profile';
import { inject as service } from '@ember/service';

@classic
export default class ConfirmationController extends PortalProfileIndexController {
  @controller('session')
  session;

  @service store

  @action
  async onSubmit() {
    const { borrower, changeset } = this.model;
    try {
      await borrower.updateEmail({
        new_email: changeset.get('email'),
        password: changeset.get('password')
      });

      this.transitionToRoute('account.email-confirmation');
    } catch (e) {
      this.pushErrorMessages(changeset, e.errors);
      if(e.errors.firstObject.status === "401") {
        let loginRoute;
        if (this.shoppingPassLoan) {
          loginRoute = 'account.hdlogin';
        } else {
          loginRoute = 'account.login';
        }
        this.set('session.loggedIn', false);
        this.store.unloadAll();
        return this.transitionToRoute(loginRoute);
      }
    }
  }
}
