import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isBlank } from '@ember/utils';

@classic
export default class AuthenticateRoute extends Route {
  @service
  intl;

  @service store
  @service router

  beforeModel(transition) {
    document.title = this.intl.t('account.verify_identity_title');
    if (isBlank(transition.to.queryParams.token)) {
      this.router.transitionTo('error');
    }
  }

  async model({ token, resetPassword }) {
    try {
      const data = await this.store.peekRecord('session', 0).twoFactorPhoneNumbers({
        token: token,
        reset_password: resetPassword
      });

      return this.store.createRecord('borrower', {
        validityMins: data.validity_mins,
        authCodePhoneNumbers: data.borrower_phone_numbers
      });
    } catch {
      this.router.transitionTo('account.expired-link', { queryParams: { redirectTo: 'new' } });
    }
  }
}
