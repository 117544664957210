import Component from '@glimmer/component'

export default class UiPageRecordsIndicator extends Component {
  get pageCount() {
    let pageSize = Number.parseInt(this.args.pageSize)
    if (Number.isNaN(pageSize) || pageSize === 0) return 1

    return Math.ceil(this.args.recordCount / this.args.pageSize)
  }

  get firstRecord() {
    return (this.args.currPage - 1) * this.args.pageSize + 1
  }

  get lastRecord() {
    if (this.args.currPage < this.pageCount) {
      return this.args.currPage * this.args.pageSize
    }

    return this.args.recordCount
  }
}
