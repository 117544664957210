import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils';
import PortalHubBankingController from '../banking';

@classic
class PortalHubBankingErrorController extends PortalHubBankingController {
  bankingErrorTitle = null;
  bankingErrorDescription = null;
  bankingErrorSuggestion = null;
  bankingErrorType = null;
  queryParams = ['sourceFlow', 'poiFailure', 'sixMonthsDepositsFailure', { idvStepId: 'idvStepId' }];
  poiFailure = false;
  sixMonthsDepositsFailure = false;
  sourceFlow = 'verify_id';
  idvStepId = null;

  @service
  intl;

  @computed('borrower.atMaxFlinksPoiAttempts', 'sourceFlow')
  get tryAgainLimitReached() {
    return this.sourceFlow === 'poi' && this.get('borrower.atMaxFlinksPoiAttempts')
  }

  @computed('tryAgainLimitReached', 'bankingErrorType')
  get tryAgainButtonHidden() {
    return this.tryAgainLimitReached || this.bankingErrorType === 'flinks_connection'
  }

  @computed('bankingErrorDescription', 'bankingErrorSuggestion', 'bankingErrorTitle', 'loan.borrower.atMaxFlinksPoiAttempts', 'poiFailure', 'sixMonthsDepositsFailure', 'sourceFlow')
  get errorMessage() {
    if (this.sourceFlow === 'poi' && this.get('loan.borrower.atMaxFlinksPoiAttempts')) {
      return {
        title: this.intl.t('hub.banking_error.verify_income_title'),
        description: this.intl.t('hub.banking_error.verify_income_description'),
        suggestion: this.intl.t('hub.banking_error.verify_income_limit_reached_suggestion')
      }
    }
    else if (this.poiFailure) {
      // poi was actually attempted and failed, ie. not a flinks connection issue
      if (this.sixMonthsDepositsFailure) {
        return {
          title: this.intl.t('hub.banking_error.verify_income_title'),
          description: this.intl.t('hub.banking_error.six_months_failure_description'),
          suggestion: this.intl.t('hub.banking_error.six_months_failure_suggestion'),
        }
      }
      return {
        title: this.intl.t('hub.banking_error.verify_income_title'),
        description: this.intl.t('hub.banking_error.verify_income_description'),
        suggestion: this.intl.t('hub.banking_error.verify_income_suggestion')
      }
    }
    else if (isPresent(this.bankingErrorDescription)) {
      return {
        title: this.bankingErrorTitle,
        description: this.bankingErrorDescription,
        suggestion: this.bankingErrorSuggestion,
      };
    } else {
      return {
        title: this.intl.t('hub.banking_error.generic_title'),
        description: this.intl.t('hub.banking_error.generic_description')
      };
    }
  }
}

export default PortalHubBankingErrorController;
