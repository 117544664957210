import classic from 'ember-classic-decorator';
import { inject as controller } from '@ember/controller';
import { readOnly } from '@ember/object/computed';
import FinanceitComponentsFinanceitController from 'financeit-components/controllers/financeit';

@classic
class FinanceitController extends FinanceitComponentsFinanceitController {
  @controller('partner')
  partnerController;

  @readOnly('partnerController.model')
  partner;
}

export default FinanceitController;
