import { inject as controller } from '@ember/controller'
import PortalPaymentController from '../payment'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject as service } from '@ember/service'
import { showModal } from 'tag/utils/ui-modal'

export default class PortalPaymentPaymentDetailsController extends PortalPaymentController {
  @controller('services/paymentSchedule') paymentSchedule
  @controller('services/paymentCalculator') calculator
  @controller() subvention

  @service intl

  setTitle() {
    document.title = this.intl.t('payment_details.payment_details')
  }

  @tracked viewPaymentDetails = 'history'
  queryParams = [{ viewPaymentDetails: 'dataToShow' }]

  get formForSegmentedControl() {
    return {
      viewPaymentDetails: this.viewPaymentDetails
    }
  }

  @action
  onViewPaymentDetailsControlChange(event) {
    this.viewPaymentDetails = event.target.value
  }

  @action
  printPage() {
    return window.print();
  }
  
  @action
  openTermEndModal() {
    showModal('term_end_explanation')
  }
}
