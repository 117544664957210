import classic from 'ember-classic-decorator';
/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Service, { inject as service } from '@ember/service';

@classic
export default class SocketService extends Service {
  @service('router')
  router;

  init() {
    super.init(...arguments);
    return this.createClient();
  }

  createClient() {
    return window.socketClient = new Faye.Client('/faye');
  }
}
