import Component from '@glimmer/component'
import { action } from '@ember/object'
export default class UiDropdownItemLinkComponent extends Component {
  @action
  closeDropdown() {
    this.args.closeDropdown()
  }

  get query() {
    return this.args.query ?? {}
  }
}
