import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'direct/config/environment'

@classic
class ApplicationRoute extends Route {
  @service
  intl;

  @service store
  /** @type {import('financeit-components/app/services/mobile').default} */
  @service mobile
  /** @type {import('direct/app/services/bioauth').default} */
  @service bioauth

  async beforeModel() {
    await this.bioauth.setBioauthEligibility(true)
    // This ensures the mobile app populates window.mobileFeatureFlags with the correct values
    await this.mobile.callBridgeFunction('initialize_feature_flags')

    this.store.createRecord('session', { id: 0 });

    const { locale } = window.regionServices;

    I18n.locale = locale;
    I18n.fallbacks = true;

    const intlLocale =
      (() => {
        switch (locale) {
          case 'fr':
            return 'fr-ca';
          default:
            return 'en-ca';
        }
      })();

    await this.loadTranslations(intlLocale);

    if (intlLocale === 'fr-ca') {
      moment.lang('fr');
    } else {
      moment.lang('en');
    }

    return super.beforeModel();
  }

  async loadTranslations(locale) {
    /*
      NOTE: The URLs below are fully hardcoded due to fingerprinting using
      broccoli-asset-rev, and should not changed to being interpolated.

      See https://ember-intl.github.io/ember-intl/versions/master/docs/guide/asynchronously-loading-translations#fingerprinting
    */
    const localeJsonUrl = this.translationFileUrl(locale);

    const translations = await fetch(localeJsonUrl);
    const translationsAsJson = await translations.json();
    this.intl.addTranslations(locale, translationsAsJson);
    this.intl.setLocale([locale]);
    this.loadSecondaryTranslations(locale)
  }

  /**
   * Loads translation files for the "secondary" language.
   *
   * If the primary language is "en", the secondary will be "fr", and vice versa. This
   * ensures we can still use the secondary language's translations in places where "locale"
   * is explicitly specified when fetching a translation.
   *
   * @param {string} currentLocale
   */
  async loadSecondaryTranslations(currentLocale) {
    const secondaryLocale = currentLocale === 'fr-ca' ? 'en-ca' : 'fr-ca';
    const localeJsonUrl = this.translationFileUrl(secondaryLocale);

    const translations = await fetch(localeJsonUrl);
    const translationsAsJson = await translations.json();
    this.intl.addTranslations(secondaryLocale, translationsAsJson)
  }

  translationFileUrl(locale) {
    const host = ENV.prepend ? '' : 'http://localhost:4200'
    /*
      NOTE: The URLs below are fully hardcoded due to fingerprinting using
      broccoli-asset-rev, and should not changed to being interpolated.

      See https://ember-intl.github.io/ember-intl/versions/master/docs/guide/asynchronously-loading-translations#fingerprinting
    */
    let path
    switch(locale) {
      case 'fr-ca':
        path = ENV.prepend ? '/assets/ember-cli/apps/direct/translations/fr-ca.json' : '/translations/fr-ca.json'
        break
      default:
        path = ENV.prepend ? '/assets/ember-cli/apps/direct/translations/en-ca.json' : '/translations/en-ca.json'
        break
    }

    return host + path
  }
}

export default ApplicationRoute;
