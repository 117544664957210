import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { checkAuthenticated } from "../../../utils/redirect-session";
import Changeset from 'ember-changeset';

@classic
export default class ChangeEmailRoute extends Route {
  @service
  intl;

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel(transition) {
    checkAuthenticated(transition, this);
    document.title = this.intl.t('profile.login_and_security.change_your_email')
  }

  model() {
    const borrower = this.controllerFor('session').get('loggedInUser');
    const changeset = new Changeset(borrower);
    changeset.set('email', null);
    return { borrower, changeset };
  }
}
