import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll';
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session';
import setTransitionOriginator from '../../utils/set-transition-originator'

@classic
class PortalRepromptRoute extends Route {
  @service
  intl;

  @service store

  init() {
    super.init(...arguments);
    this.on('routeWillChange', this.willTransition.bind(this))
  }

  activate() {
    super.activate(...arguments)
    resetScroll()
  }

  beforeModel(transition) {
    // eslint-disable-next-line ember/no-jquery
    $(document).attr('title', this.intl.t('title.reprompt'));
    checkAuthenticated(transition, this);

    const loan = this.controllerFor('loan').get('model');

    // this is not an ideal solution as it doesnt handle the case where both borrower and coborrower have attributes to resolve
    const borrower_id = loan.get('borrowerId')
    const coborrower_id = loan.get('coborrower.id');
    if (this.store.peekRecord('borrower', loan.get('borrowerId')).attributesToResolve.length > 0) {
      return this.controllerFor('borrower').set('model', this.store.peekRecord('borrower', borrower_id ))
    } else if (coborrower_id && this.store.peekRecord('borrower', coborrower_id).attributesToResolve.length > 0){
      return this.controllerFor('borrower').set('model', this.store.peekRecord('borrower', coborrower_id));
    }
    transitionToDefaultRoute(this);
  }

  @action
  willTransition(transition) {
    const model = this.controller.get('model')
    return setTransitionOriginator(transition, model, this.routeName);
  }
}

export default PortalRepromptRoute;
