import Model, { attr, hasMany } from "@ember-data/model";
import { isPresent } from "@ember/utils";

export default class Partner extends Model {
  @attr("boolean") adminFeesWaived;
  @attr availablePaymentFrequencies;
  @hasMany("loan") loans;
  @attr("string") workPhoneNumber;
  @attr("string") website;
  @attr("string") language;
  @attr("boolean") postDeclineContact;
  @attr("string") postDeclineContactMethod;
  @attr("string") postApprovedContactMethod;
  @attr directFinancingOptions;
  @attr("string") binaryDirectFinancingOption;
  @attr("string") businessName;
  @attr("string") address;
  @attr("string") city;
  @attr("string") province;
  @attr("string") postalCode;
  @attr("string") phone;
  @attr("string") email;
  @attr businessHours;
  @attr("boolean") missingLogo;
  @attr("string") logoUrl;
  @attr("boolean") feesAbsorbed;
  @attr("number") flatFeesPercent;
  @attr("boolean") allowQuebecLoans;
  @attr("string") borrowerBirthdateMin;
  @attr("string") borrowerBirthdateMax;
  @attr("number") borrowerSubventionPercentage;
  @attr("number") loanManagingGroupId;
  @attr("string") loanManagingGroupName;
  @attr("number") minRate;
  @attr("number") maxAmort;
  @attr("boolean") isBigBox;
  @attr("") homeImprovementBoundariesPrime;
  @attr("string") defaultPaymentFrequency;
  @attr("") homeImprovementBoundariesSubprime;
  @attr("") homeImprovementBoundariesQuebecSubprime;
  @attr("boolean") subprimeEligible;
  @attr("string") absorbBorrowerFees;
  @attr applicableAmortizations;
  @attr("boolean") isTwoStepLoanFormAvailable;
  @attr("boolean") streamlinedFlowEnabled;
  @attr("boolean") isEligibleForGreaterThan_100k;

  get fullAddress() {
    let partial = `${this.address}${", " + this.city}`;
    if (isPresent(this.province) || isPresent(this.postalCode)) {
      partial += ", ";
    }
    return partial + `${this.province + " "}${this.postalCode}`;
  }

  get flatFeesExample() {
    return (this.flatFeesPercent / 100) * 1000;
  }
}
