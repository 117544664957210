import Component from '@glimmer/component';

export default class GetStartedComponent extends Component {
  get showBadgePromotion() {
    return this.args.isTop && this.args.subvention.isBadgePromotion && !this.args.hasQuoteWithAmount
  }

  get showImageForTop() {
    return this.args.isTop && !this.args.showBadgePromotion;
  }
}
