import { registerDeprecationHandler } from '@ember/debug';

export function initialize() {
  registerDeprecationHandler((message, options, next) => {
    // This deprecation warning causes too much noise. Let's silence it for now
    // until we can refactor the partials into components
    if (options.id === 'ember-views.partial') {
      return;
    }

    next(message, options);
  });
}

export default { initialize };
