import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'

export default class FileListComponent extends Component {
  @service UiFileQueue

  @action
  onRemoveFile(file) {
    const queue = this.UiFileQueue.find(this.args.name)

    if (queue) {
      queue.removeFile(file)
    }
  }
}
