import Route from '@ember/routing/route'
import { transitionToDefaultRoute, checkAuthenticated } from '../../utils/redirect-session'
import { inject as service } from '@ember/service'
export default class extends Route {
  @service store

  beforeModel(transition) {
    checkAuthenticated(transition, this)
    if (this.controllerFor('loan').get('model.isHardPulled')) {
      return transitionToDefaultRoute(this)
    }
  }

  resetController(controller) {
    controller.showSpinner = false
    controller.holdingForBureau = false
    controller.redirectToAddCoborrower = null
  }

  model() {
    const loan = this.controllerFor('loan').get('model')
    const subvention = this.controllerFor('subvention')
    const { partner, borrower } = loan

    return {
      loan,
      partner,
      subvention,
      borrower: this.store.createRecord('borrower', {
        firstName: borrower.get('firstName'),
        lastName: borrower.get('lastName'),
        maritalStatus: borrower.get('maritalStatus'),
        optionalHousingAndEmployerInfo: borrower.get('optionalHousingAndEmployerInfo')
      })
    }
  }
}
