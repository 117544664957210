import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import PortalProfileIndexController from '../../profile';

@classic
export default class IndexController extends PortalProfileIndexController {
  @action
  async onSubmit() {
    const { borrower, changeset } = this.model;
    try {
      await borrower.updateEmail({
        new_email: changeset.get('email'),
        email_validation_step: true
      });

      this.transitionToRoute('portal.profile.change-email.confirmation');
    } catch (e) {
      const errors = {}
      e.errors.forEach((error) => {
        errors[error.meta.key] = error.meta.errors
      })
      this.pushErrorMessages(changeset, [errors]);
    }
  }
}
