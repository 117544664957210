import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import PortalHubController from "../../hub";

@classic
class SecureYourAccountErrorController extends PortalHubController {
  queryParams = ['borrowerType'];
  borrowerType = "Borrower";

  @service
  intl;

  @computed("model.loan.isSecureYourAccountStepMandatory")
  get accountErrorMessage() {
    if (this.get("model.loan.isSecureYourAccountStepMandatory")) {
      return this.intl.t("secure-your-account.error.try_again_later");
    } else {
      return this.intl.t("secure-your-account.error.try_other_options");
    }
  }
}

export default SecureYourAccountErrorController;
