import classic from 'ember-classic-decorator';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import { isPresent } from '@ember/utils'
import Controller from '@ember/controller'
import { addClass, removeClass } from 'financeit-components/utils/dom-node-helpers'
import { tracked } from "@glimmer/tracking";

@classic
class PartnerController extends Controller {
  @service
  intl;

  queryParams = ['lmgn'];

  setModel(model) {
    set(this, 'model', model);
    this.setTheme();
  }

  setTheme() {
    addClass('html', this.lmgn)

    if (this.get('model.isBigBox')) {
      // Manually sets the Home Depot theme hook
      addClass('html', 'home_depot')
      document.querySelector('html').setAttribute('data-theme', 'home_depot')

      document.title = this.intl.t(`partner.home_depot.title`)
      const link = document.querySelector('link[rel*="icon"]')
      link.href = `/${this.intl.t(`partner.home_depot.favicon`)}`
    } else {
      removeClass('html', 'home_depot' )
      document.querySelector('html').removeAttribute('data-theme')
      document.title = this.intl.t(`partner.financeit.title`)
      const className = this.convertToClassName(this.get('model.loanManagingGroupName'))
      addClass('html', className)
      const link = document.querySelector('link[rel*="icon"]')
      link.href = `/${this.intl.t(`partner.financeit.favicon`)}`
    }
  }

  convertToClassName(name) {
    //replace spaces with underscore and letters lowercase
    if (!name) { return; }
    return name.replace(/\s+/g, '_').toLowerCase();
  }

  @computed('model.phone')
  get hasPhone() {
    return isPresent(this.get('model.phone'));
  }

  @computed('model.businessHours')
  get hasBusinessHours() {
    return this.get('model.businessHours').length > 0;
  }

  @equal('model.postApprovedContactMethod', 'telephone')
  preferredTelephoneApproved;

  @equal('model.postApprovedContactMethod', 'email')
  preferredEmailApproved;

  @equal('model.postApprovedContactMethod', 'visit')
  preferredVisitApproved;

  @equal('model.postApprovedContactMethod', 'no_contact')
  preferredNoContactApproved;

  @equal('model.postDeclineContactMethod', 'telephone')
  preferredTelephoneDeclined;

  @equal('model.postDeclineContactMethod', 'email')
  preferredEmailDeclined;

  @equal('model.postDeclineContactMethod', 'visit')
  preferredVisitDeclined;

  @computed('model.phone')
  get phoneLink() {
    return `tel://${this.get('model.phone')}`;
  }

  @computed('model.fullAddress')
  get mapLink() {
    return this.get('model.fullAddress').mapify();
  }
}

export default PartnerController;
