import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
export default class AlternativePoiRoute extends Route {
  @service('loading-indicator')
  loading_animation;

  init() {
    super.init(...arguments);
    this.on('routeWillChange', this.willTransition.bind(this))
  }

  @action
  willTransition(transition) {
    if (transition.to.queryParams.reload === "true") {
      return this.controllerFor('loan').get('model').reload().then(() => {
        return this.loading_animation.hide();
      });
    }
  }
}
